import React from 'react';
import { FormInput, DataGridProps } from '@campus/components';

import AdminContainer from 'containers/AdminContainer';
import EvaluationTypeRepository from 'repositories/EvaluationTypeDescriptionRepository';

const EvaluationTypeForm: React.FC = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome"
        required
        grid={{ xs: 6 }}
      />
      <FormInput
        name="pluralName"
        label="Nome no plural"
        placeholder="Insira o nome no plural"
        required
        grid={{ xs: 6 }}
      />
      <FormInput
        name="color"
        label="Cor"
        type={'color'}
        defaultValue="#FFFFFF"
        required
      />
    </>
  );
};

const EvaluationTypeFilter: React.FC = () => {
  return <FormInput name="name" label="Nome" placeholder="Insira o nome" />;
};

const EvaluationTypePage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [{ Header: 'Nome', accessor: 'name' }],
    []
  );

  return (
    <AdminContainer
      title="Cadastro dos tipos de avaliação"
      columns={columns}
      repository={new EvaluationTypeRepository()}
      components={{
        Form: EvaluationTypeForm,
        Filter: EvaluationTypeFilter
      }}
    />
  );
};

export default EvaluationTypePage;
