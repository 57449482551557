import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import {
  DisciplineMatrix,
  DisciplineMatrixInput
} from 'models/DisciplineMatrix';

export const getDisciplineMatrix = async (
  input: DisciplineMatrixInput
): Promise<Response<DisciplineMatrix[]>> => {
  try {
    const api = Api.INSTANCE.getApi();

    const { discipline, grade, period } = input;

    const response = await api.get(
      `/discipline/${discipline?.value}/disciplinematrix`,
      { params: { gradeId: grade?.value, periodId: period?.value } }
    );

    const data = response.data?.map(DisciplineMatrix.fromJson) ?? [];

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
};

export const createDisciplineMatrix = async (
  input: DisciplineMatrixInput
): Promise<Response<DisciplineMatrix>> => {
  try {
    const api = Api.INSTANCE.getApi();

    const { discipline, grade, period, evaluationMatrix, showPerformance } =
      input;
    const response = await api.post(`/discipline/disciplinematrix`, {
      GradeId: grade?.value,
      PeriodId: period?.value,
      DisciplineId: discipline?.value,
      EvaluationMatrixId: evaluationMatrix?.value,
      ShowPerformance: showPerformance
    });

    const data = DisciplineMatrix.fromJson(response.data);

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
};
