import { useEffect, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

type UseSearchFormProps = {
  searchData: any;
  defaultKey: string;
  requiredFields: string[];
};

const useSearchForm = (props: UseSearchFormProps) => {
  const { searchData, defaultKey, requiredFields } = props;

  const [key, setKey] = useState(defaultKey);

  const { setValue, control } = useFormContext();
  const { isSubmitting, errors } = useFormState({ control });

  useEffect(() => {
    if (searchData) {
      setValue('name', searchData?.name);
      setValue('email', searchData?.email);
      setValue('imageUrl', searchData?.imageUrl);
    }
  }, [setValue, searchData]);

  useEffect(() => {
    if (isSubmitting) {
      const errKeys = Object.keys(errors);
      const hasObject = errKeys.find((e) => requiredFields.includes(e));

      if (!!hasObject) setKey(defaultKey);
    }
  }, [isSubmitting, errors, requiredFields, defaultKey]);

  return { key, setKey };
};

export default useSearchForm;
