import { Api } from '@campus/auth';
import { Response, PaginationModel } from '@campus/commons';
import {
  Discipline,
  DisciplineFilter,
  DisciplineInput
} from 'models/Discipline';
import { AdminRepository } from './interfaces/AdminRepository';

class DisciplineRepository extends AdminRepository<
  DisciplineFilter,
  DisciplineInput,
  Discipline
> {
  path: string = '/discipline';

  async getAll(
    filter: DisciplineFilter
  ): Promise<Response<PaginationModel<Discipline>>> {
    try {
      const { name, teacher, classroom, page, pageSize } = filter;
      const skipCount = page * pageSize;
      const response = await Api.INSTANCE.getApi().get(this.path, {
        params: {
          Name: name,
          TeacherId: teacher?.value,
          ClassId: classroom?.value,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(
        Discipline,
        response.data,
        skipCount
      );

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<Discipline>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);

      const data = Discipline.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: DisciplineInput): Promise<Response<boolean>> {
    try {
      const { name } = input;

      const response = await super.api.post(this.path, {
        Name: name
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(input: DisciplineInput, id: string): Promise<Response<boolean>> {
    try {
      const { name } = input;

      const response = await super.api.put(`${this.path}/${id}`, {
        Name: name
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}
export default DisciplineRepository;
