import { useState } from 'react';

export function usePagination() {
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(false);

  const goToFirstPage = () => {
    setPage(1);
  };

  const goToPrevPage = () => {
    setPage((prev) => prev - 1);
  };

  const goToNextPage = () => {
    setPage((prev) => prev + 1);
  };

  const goToLastPage = () => {
    setPage(totalPages);
  };

  return {
    page,
    totalPages,
    loading,
    pageSize,
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPrevPage,
    setLoading,
    setPage,
    setTotalPages
  };
}
