import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

export interface UnitFilter extends PaginatedFilter {
  name?: string;
}

export type UnitInput = {
  id: string;
  name: string;
  imageUrl?: string;
  bannerImageUrl?: string;
  bannerImageMobileUrl?: string;
  bannerRedirectUrl?: string;
};

export class Unit implements FormModel<UnitInput> {
  id?: string;
  name?: string;
  imageUrl?: string;
  bannerImageUrl?: string;
  bannerImageMobileUrl?: string;
  bannerRedirectUrl?: string;

  static fromJson(data: any): Unit {
    const unit = new Unit();

    unit.id = data?.Id;
    unit.name = data?.Name;
    unit.imageUrl = data?.ImageUrl;
    unit.bannerImageUrl = data?.BannerImageUrl;
    unit.bannerImageMobileUrl = data?.BannerImageMobileUrl;
    unit.bannerRedirectUrl = data?.BannerRedirectUrl;

    return unit;
  }

  toInput = (): UnitInput => ({
    id: this.id,
    name: this.name,
    imageUrl: this.imageUrl,
    bannerImageUrl: this.bannerImageUrl,
    bannerImageMobileUrl: this.bannerImageMobileUrl,
    bannerRedirectUrl: this.bannerRedirectUrl
  });
}
