import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Row, Col, Checkbox } from '@campus/components';

import { Role } from 'models/ProfileRole';

type PermissionsInputProps = {
  roles: Role[];
};

const PermissionsInput: React.FC<PermissionsInputProps> = ({ roles }) => {
  const { control, setValue } = useFormContext();
  const selectedRoles = useWatch({ control, name: 'roles' });

  const handleChange = (perm: string) => {
    const included = selectedRoles?.includes(perm);

    if (included) {
      const perms = selectedRoles.filter((s) => s !== perm);
      setValue('roles', perms);
    } else {
      setValue('roles', [...selectedRoles, perm]);
    }
  };

  return (
    <Row className="gy-2 gx-2">
      {roles.map((r) => (
        <Col key={r.name} xs={3}>
          <Checkbox
            name={r.name}
            label={r.description}
            checked={selectedRoles?.includes(r.name)}
            onChange={() => handleChange(r.name)}
          />
        </Col>
      ))}
    </Row>
  );
};

export default PermissionsInput;
