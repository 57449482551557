import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { toast } from '@campus/components';

import { AdminContext } from 'components/AdminTable';
import { AdminRepository } from 'repositories/interfaces/AdminRepository';

export const useAdminForm = <I = unknown>(
  repository: AdminRepository<any, I, any>
) => {
  const { onSubmit } = useOutletContext<AdminContext>();

  const navigate = useNavigate();

  const [defaultValues, setDefaultValues] = useState<I>(null);
  const [loadingData, setLoadingData] = useState(true);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const params = useParams();
  const id = params.id;

  const loadFormData = useCallback(async () => {
    if (id) {
      setLoadingData(true);

      const response = await repository.get(id);
      if (response.data) {
        setDefaultValues(response.data.toInput());
      }
    }
    setLoadingData(false);
  }, [id, repository]);

  useEffect(() => {
    loadFormData();
  }, [loadFormData]);

  const handleSubmit = async (data: any) => {
    setLoadingSubmit(true);

    const response = id
      ? await repository.update(data, id)
      : await repository.create(data);

    setLoadingSubmit(false);

    if (!response.error) {
      toast.success('👍 Dados salvos com sucesso');
      onSubmit();
      navigate(-1);
    } else {
      console.error(response.error);
      toast.error(
        response.error?.message ??
          'Erro ao salvar os dados. Entre em contato com o suporte.'
      );
    }
  };

  return {
    defaultValues,
    loadingData,
    loadingSubmit,
    onSubmit: handleSubmit
  };
};
