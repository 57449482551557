import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { DataGridProps } from '@campus/components';

import { AdminRepository } from 'repositories/interfaces';

import AdminTable from 'components/AdminTable';
import FormPage from './components/FormPage';

import { AdminComponents } from './types';

export type AdminContainerProps = {
  title: string;
  columns: DataGridProps['columns'];
  repository: AdminRepository;
  components: AdminComponents;
  disableNew?: boolean;
  disableEdit?: boolean;
};

const AdminContainer: React.FC<AdminContainerProps> = ({
  title,
  columns,
  components,
  repository,
  disableNew,
  disableEdit
}) => {
  return (
    <Routes>
      <Route
        element={
          <AdminTable
            title={title}
            columns={columns}
            components={components}
            onDelete={(d) => repository.delete(d)}
            onFetch={(d) => repository.getAll(d)}
            disableNew={disableNew}
          />
        }
      >
        <Route index element={<></>} />
        {!disableEdit && (
          <Route
            path=":id"
            element={
              <FormPage
                isEdit
                repository={repository}
                components={components}
              />
            }
          />
        )}
        {!disableNew && (
          <Route
            path="new"
            element={
              <FormPage repository={repository} components={components} />
            }
          />
        )}
      </Route>
    </Routes>
  );
};

export default AdminContainer;
