import { Api } from '@campus/auth';
import { SelectOption } from '@campus/components';
import { PaginationModel, Response, Student } from '@campus/commons';
import { DisciplineMatrixEmpty } from 'errors/DisiciplineMatrixError';

type StudentsFilter = {
  discipline: SelectOption;
  classroom: SelectOption;
};

export type ClassDisciplineInput = {
  sourceDiscipline: SelectOption;
  sourceClassroom: SelectOption;
  discipline: SelectOption;
  classroom: SelectOption;
  startDate: string;
  endDate: string;
};

class ClassroomDisciplineRepository {
  async getAll({
    discipline,
    classroom
  }: StudentsFilter): Promise<Response<PaginationModel<Student>>> {
    try {
      const classId = classroom?.value;

      if (!(discipline?.value || classId)) {
        throw new DisciplineMatrixEmpty();
      }

      const response = await Api.INSTANCE.getApi().get(
        `/class/${classId}/students`,
        {
          params: { disciplineMatrixId: discipline?.value }
        }
      );

      const data = PaginationModel.fromJson(
        Student,
        { Items: response.data },
        0
      );

      return Response.fromData(data, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }

  async create(data: ClassDisciplineInput): Promise<Response<boolean>> {
    try {
      const {
        sourceClassroom,
        sourceDiscipline,
        classroom,
        discipline,
        endDate,
        startDate
      } = data;

      const classId = classroom?.value;
      const disciplineId = discipline?.value;

      const response = await Api.INSTANCE.getApi().post(
        `/class/${classId}/disciplinematrix/${disciplineId}`,
        {
          SourceClassId: sourceClassroom?.value,
          SourceDisciplineMatrixId: sourceDiscipline?.value,
          StartDate: startDate,
          EndDate: endDate
        }
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }

  async delete({ id, classId, disciplineId }): Promise<Response<boolean>> {
    try {
      const response = await Api.INSTANCE.getApi().delete(
        `/class/${classId}/disciplinematrix/${disciplineId}`,
        {
          params: { studentId: id }
        }
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }

  async deleteBatch({ classId, disciplineId }): Promise<Response<boolean>> {
    try {
      const response = await Api.INSTANCE.getApi().delete(
        `/class/${classId}/disciplinematrix/${disciplineId}`
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }
}

export default ClassroomDisciplineRepository;
