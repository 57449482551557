import { PaginationModel, Response } from '@campus/commons';
import {
  ClassificationType,
  ClassificationTypeFilter,
  ClassificationTypeInput
} from 'models/ClassificationType';
import { AdminRepository } from './interfaces/AdminRepository';

class ClassificationTypeRepository extends AdminRepository<
  ClassificationTypeFilter,
  ClassificationTypeInput,
  ClassificationType
> {
  path: string = '/classificationtype';

  async get(id: string): Promise<Response<ClassificationType>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);

      const data = ClassificationType.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async getAll(
    filter: ClassificationTypeFilter
  ): Promise<Response<PaginationModel<ClassificationType>>> {
    try {
      const { name, page, pageSize } = filter;
      const skipCount = page * pageSize;

      const response = await super.api.get(this.path, {
        params: {
          Name: name,
          SkipCount: page * pageSize,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(
        ClassificationType,
        response.data,
        skipCount
      );

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: ClassificationTypeInput): Promise<Response<boolean>> {
    try {
      const response = await super.api.post(
        this.path,
        ClassificationType.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(
    input: ClassificationTypeInput,
    id: string
  ): Promise<Response<boolean>> {
    try {
      const response = await super.api.put(
        `${this.path}/${id}`,
        ClassificationType.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}

export default ClassificationTypeRepository;
