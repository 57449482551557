import React from 'react';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.background};
`;

export const Container = styled.div`
  padding: 20px;
  margin: 0 auto;
  max-width: 1020px;
  width: 100%;
`;

const Content: React.FC = ({ children }) => (
  <Root>
    <Container>{children}</Container>
  </Root>
);

export default Content;
