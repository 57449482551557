import { Response, PaginationModel } from '@campus/commons';
import { Profile, ProfileFilter, ProfileInput } from 'models/Profile';
import { AdminRepository } from './interfaces/AdminRepository';

class ProfileRepository extends AdminRepository<
  ProfileFilter,
  ProfileInput,
  Profile
> {
  path: string = '/profile';

  async getAll(
    filter: ProfileFilter
  ): Promise<Response<PaginationModel<Profile>>> {
    try {
      const { name, page, pageSize } = filter;
      const skipCount = page * pageSize;
      const response = await super.api.get(this.path, {
        params: {
          Name: name,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(Profile, response.data, skipCount);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<Profile>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);

      const data = Profile.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: ProfileInput): Promise<Response<boolean>> {
    try {
      const { name, roles } = input;

      const response = await super.api.post(this.path, {
        Name: name,
        Roles: roles
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(input: ProfileInput, id: string): Promise<Response<boolean>> {
    try {
      const { name, roles } = input;

      const response = await super.api.put(`${this.path}/${id}`, {
        Name: name,
        Roles: roles
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}

export default ProfileRepository;
