import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

export interface PeriodFilter extends PaginatedFilter {
  name?: string;
}

export type PeriodInput = {
  name: string;
};
export class Period implements FormModel<PeriodInput> {
  id?: string;
  name?: string;

  static fromJson(data: any): Period {
    const period = new Period();

    period.id = data?.Id;
    period.name = data?.Name;

    return period;
  }

  toInput = (): PeriodInput => ({
    name: this.name
  });
}
