import React from 'react';

import { DataGridProps } from '@campus/components';

import AdminContainer, { FormSearchProps } from 'containers/AdminContainer';
import AdminSearchFormPage from 'containers/AdminSearchFormPage';
import StudentRepository from 'repositories/StudentRepository';

import StudentFormFilter from './components/StudentFormFilter';
import StudentForm from './components/StudentForm';

const StudentFormPage: React.FC<FormSearchProps> = ({ repository }) => {
  return <AdminSearchFormPage repository={repository} Form={StudentForm} />;
};

const StudentPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [
      { Header: 'Nome', accessor: 'user.name' },
      { Header: 'Email', accessor: 'user.email' },
      { Header: 'Data de Nascimento', accessor: 'birthdayFormated' }
    ],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de estudantes"
      repository={new StudentRepository()}
      columns={columns}
      components={{
        Filter: StudentFormFilter,
        FormPage: StudentFormPage
      }}
    />
  );
};

export default StudentPage;
