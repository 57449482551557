import React from 'react';

import {
  FormInput,
  FormSelectorAsync,
  DataGridProps
} from '@campus/components';

import AdminContainer from 'containers/AdminContainer';
import GradeRepository from 'repositories/GradeRepository';
import { getSegments } from 'services/services';

const GradeForm: React.FC = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome do Grau"
        required
      />

      <FormSelectorAsync
        name="segment"
        label="Segmento"
        placeholder="Selecione um segmento"
        required
        getOptions={(inputValue: string) => getSegments(inputValue)}
      />
    </>
  );
};

const GradeFilter: React.FC = () => {
  return (
    <>
      <FormInput name="name" label="Nome" placeholder="Insira o nome do Grau" />

      <FormSelectorAsync
        name="segment"
        label="Segmento"
        placeholder="Selecione um segmento"
        required
        getOptions={(inputValue: string) => getSegments(inputValue)}
      />
    </>
  );
};

const GradePage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Segmento', accessor: 'segment.name' }
    ],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de séries"
      repository={new GradeRepository()}
      columns={columns}
      components={{
        Form: GradeForm,
        Filter: GradeFilter
      }}
    />
  );
};

export default GradePage;
