import { Api } from '@campus/auth';
import { Response, PaginationModel } from '@campus/commons';
import { DisciplineMatrixEmpty } from 'errors/DisiciplineMatrixError';
import {
  DisciplineMatrix,
  DisciplineMatrixFilter,
  DisciplineMatrixInput
} from 'models/DisciplineMatrix';
import { AdminRepository } from './interfaces/AdminRepository';

class DisciplineMatrixRepository extends AdminRepository<
  DisciplineMatrixFilter,
  DisciplineMatrixInput,
  DisciplineMatrix
> {
  path: string = '/discipline';

  async getAll(
    filter: DisciplineMatrixFilter
  ): Promise<Response<PaginationModel<DisciplineMatrix>>> {
    try {
      const { classroom, discipline, grade, period } = filter;

      if (
        !discipline?.value &&
        grade?.value &&
        period?.value &&
        classroom?.value
      ) {
        throw new DisciplineMatrixEmpty();
      }

      const response = await Api.INSTANCE.getApi().get(
        `${this.path}/disciplineMatrix`,
        {
          params: {
            DisciplineId: discipline?.value,
            GradeId: grade?.value,
            PeriodId: period?.value,
            ClassId: classroom?.value
          }
        }
      );

      const data = PaginationModel.fromJson(DisciplineMatrix, {
        Items: response.data
      });

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<DisciplineMatrix>> {
    try {
      const response = await super.api.get(
        `${this.path}/disciplineMatrix/${id}/evaluation`
      );

      const data = DisciplineMatrix.fromJson({ Evaluations: response.data });

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: DisciplineMatrixInput): Promise<Response<boolean>> {
    try {
      const data = DisciplineMatrix.fromInputToDTO(input);

      const response = await super.api.post(
        `${this.path}/disciplineMatrix`,
        data
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(
    input: DisciplineMatrixInput,
    id: string
  ): Promise<Response<boolean>> {
    try {
      const data = DisciplineMatrix.fromInputToDTO(input);

      const response = await super.api.put(
        `${this.path}/disciplineMatrix/${id}/evaluation`,
        data
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async updateBatch(input: any): Promise<Response<boolean>> {
    try {
      const response = await super.api.put(
        `${this.path}/disciplineMatrix/evaluation`,
        input
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}
export default DisciplineMatrixRepository;
