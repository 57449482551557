import SegmentRepository from 'repositories/SegmentRepository';

export const getSegments = async (name: string) => {
  const segmentRepository = new SegmentRepository();

  const segments = await segmentRepository.getAll({
    name: name,
    page: 0,
    pageSize: 10
  });

  return segments.data.items.map((i) => ({
    value: i.id,
    label: i.name
  }));
};
