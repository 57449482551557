import styled from 'styled-components';

export const DisciplineContainer = styled.div`
  h3 {
    color: ${({ theme }) => theme.colors.gray3};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
`;
