import React, { useState } from 'react';
import { Button, Modal } from '@campus/components';
import { Trash } from '@campus/components/icons';

type DeleteButtonProps = {
  onDelete: () => void;
};

const DeleteButton: React.FC<DeleteButtonProps> = ({ onDelete }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Trash size={16} onClick={() => setShow(true)} />
      <Modal
        show={show}
        aria-labelledby="classification-modal"
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="classification-modal">Deseja excluir?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Clicando em “Confirmar” você deletará permanentemente.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShow(false)}>
            Cancelar
          </Button>

          <Button onClick={onDelete}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteButton;
