import { AffectIndex } from '@campus/commons';

export const parseAffectIndex = (value: AffectIndex) => {
  switch (value) {
    case AffectIndex.YES:
      return { value, label: 'Sim' };
    case AffectIndex.NO:
      return { value, label: 'Não' };
    case AffectIndex.OPTIONAL:
      return { value, label: 'Opcional' };
  }
};

export const affectIndexOptions = [
  parseAffectIndex(AffectIndex.YES),
  parseAffectIndex(AffectIndex.NO),
  parseAffectIndex(AffectIndex.OPTIONAL)
];
