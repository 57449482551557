import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { SelectOption } from '@campus/components';

import { Classroom } from './Classroom';
import { DisciplineMatrix } from './DisciplineMatrix';

import FormModel from './interfaces/FormModel';

export type ClassDisciplinesStudentInput = {
  disciplineMatrix: SelectOption;
  classroom: SelectOption;
  startDate: string;
  endDate: string;
  score: number;
  weight: number;
};

export class ClassDisciplinesStudent
  implements FormModel<ClassDisciplinesStudentInput>
{
  classroom: Classroom;

  disciplineMatrix: DisciplineMatrix;

  startDate: string;

  endDate: string;

  score: number;

  weight: number;

  static fromJson(data: any): ClassDisciplinesStudent {
    const classDisciplinesStudent = new ClassDisciplinesStudent();

    classDisciplinesStudent.classroom = Classroom.fromJson(data?.Class);
    classDisciplinesStudent.disciplineMatrix = DisciplineMatrix.fromJson(
      data?.DisciplineMatrix
    );

    if (data?.StartDate) {
      const startDate = parseISO(data?.StartDate);
      classDisciplinesStudent.startDate = format(startDate, 'yyyy-MM-dd');
    }

    if (data?.EndDate) {
      const endDate = parseISO(data?.EndDate);
      classDisciplinesStudent.endDate = format(endDate, 'yyyy-MM-dd');
    }

    classDisciplinesStudent.score = data?.TransferScore;

    classDisciplinesStudent.weight = data?.TransferWeight;

    return classDisciplinesStudent;
  }

  static fromInputToDTO(input: ClassDisciplinesStudentInput) {
    return {
      ClassId: input?.classroom?.value,
      DisciplineMatrixId: input?.disciplineMatrix?.value,
      StartDate: input?.startDate,
      EndDate: input?.endDate,
      TransferScore: input?.score,
      TransferWeight: input?.weight
    };
  }

  toInput = (): ClassDisciplinesStudentInput => ({
    endDate: this.endDate,
    startDate: this.startDate,
    score: this.score,
    weight: this.weight,
    disciplineMatrix: {
      value: this.disciplineMatrix?.id,
      label: this.disciplineMatrix?.discipline?.name
    },
    classroom: {
      value: this.classroom?.id,
      label: this.classroom?.name
    }
  });
}
