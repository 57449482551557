import React from 'react';

import { FormInput, DataGridProps } from '@campus/components';

import AdminContainer from 'containers/AdminContainer';
import EducationLevelRepository from 'repositories/EducationLevelRepository';

const EducationLevelForm: React.FC = () => {
  return (
    <FormInput
      name="name"
      label="Nome"
      placeholder="Insira o nome"
      required
      grid={{ xs: 12 }}
    />
  );
};

const EducationLevelFilter: React.FC = () => {
  return <FormInput name="name" label="Nome" placeholder="Insira o nome" />;
};

const EducationLevelPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [{ Header: 'Nome', accessor: 'name' }],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de níveis de ensino"
      repository={new EducationLevelRepository()}
      columns={columns}
      components={{
        Form: EducationLevelForm,
        Filter: EducationLevelFilter
      }}
    />
  );
};

export default EducationLevelPage;
