import { Unit } from './Unit';
import { User } from './User';

export class ProfileUser {
  user?: User;
  unit?: Unit;

  static fromJson(data: any): ProfileUser {
    const user = new ProfileUser();

    user.user = User.fromJson(data?.User);
    user.unit = Unit.fromJson(data?.Unit);

    return user;
  }
}
