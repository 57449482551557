import React, { useState } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import { Col, toast, UploadButton } from '@campus/components';
import { Upload, UploadVariant } from '@campus/commons';

import { ImageContainer, Placeholder, UploadContainer } from './styles';
import ImageView from './components/ImageView';

type FormInputImageProps = {
  name: string;
  variant: UploadVariant;
  required?: boolean;
  placeholder?: string;
  imageWidth?: number;
  imageHeight?: number;
};

const FormInputImage: React.FC<FormInputImageProps> = ({
  name,
  variant,
  required,
  placeholder,
  imageWidth,
  imageHeight
}) => {
  const { control } = useFormContext();

  const { field } = useController({ name, control, rules: { required } });
  const { onChange, value } = field;

  const [loading, setLoading] = useState<boolean>(false);

  const onInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);
      const upload = new Upload(variant);
      await upload.attrFile(e.target.files[0]);
      await upload.exec();

      onChange(upload.getViewUrl());
    } catch (e) {
      toast.error((e as Error).message);
    }
    setLoading(false);
  };

  return (
    <Col xs={12}>
      <Placeholder>{placeholder}</Placeholder>
      <ImageContainer $isLargeImage={imageWidth > 500}>
        <ImageView
          image={value}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
        />
        <UploadContainer $isLargeImage={imageWidth > 500}>
          <p>
            Os formatos de imagens permitidos são JPG, GIF e PNG. Os arquivos
            devem ter no máximo 5Mb.{' '}
            {(imageWidth &&
              imageHeight &&
              'É sugerido usar uma imagem com as dimensões de ' +
                imageWidth +
                'x' +
                imageHeight +
                ' pixels.') ||
              ''}
          </p>
          <UploadButton
            accept="image/*"
            size="sm"
            onInputChange={onInputChange}
            loading={loading}
          >
            Escolher imagem do computador
          </UploadButton>
        </UploadContainer>
      </ImageContainer>
    </Col>
  );
};

export default FormInputImage;
