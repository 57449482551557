import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Collapser, Col, Button } from '@campus/components';

import { DisciplineMatrixInput } from 'models/DisciplineMatrix';

import ClassDisciplineItem, { inputName } from './ClassDisciplineItem';
import DisciplineMatrixSelect from './DisciplineMatrixSelect';

import { DisciplineContainer } from '../styles';
import { classRepository } from '../Classroom';

const ClassDisciplineInput: React.FC = () => {
  const { control, getValues } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: inputName
  });

  const handleUpdate = (
    disciplineMatrix: DisciplineMatrixInput,
    index: number
  ) => {
    const values = getValues();
    const current = values.classDisciplines[index];

    if (current) {
      const classDiscipline = { ...current, disciplineMatrix };
      update(index, classDiscipline);

      if (values.id) {
        const currentId = current.disciplineMatrix.id;
        const newId = disciplineMatrix.id;
        classRepository.updateParticalDiscipline(values.id, currentId, newId);
      }
    }
  };

  return (
    <Col xs={12}>
      <DisciplineContainer>
        <h3>Componentes Curriculares</h3>
        <Collapser>
          {fields.map((f, i) => (
            <ClassDisciplineItem
              key={f.id}
              index={i}
              remove={() => remove(i)}
              onSelect={(input) => handleUpdate(input, i)}
            />
          ))}
        </Collapser>
        <DisciplineMatrixSelect
          onSelect={(e) => append({ teachers: [], disciplineMatrix: e })}
          renderButton={(onClick) => (
            <Button variant="link" onClick={onClick}>
              Adicionar componente curricular
            </Button>
          )}
        />
      </DisciplineContainer>
    </Col>
  );
};

export default ClassDisciplineInput;
