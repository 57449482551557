import React from 'react';

import {
  FormInput,
  FormSelectorAsync,
  DataGridProps
} from '@campus/components';

import AdminContainer from 'containers/AdminContainer';
import DigitalObjectPermissionsRepository from 'repositories/DigitalObjectPermissionsRepository';
import { getClassroom, getDisciplines, getGrades } from 'services/services';

import DigitalObjectActions from './components/DigitalObjectActions';

export const digitalObjectRepository = new DigitalObjectPermissionsRepository();

const DigitalObjectFilter: React.FC = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome do Objeto Digital"
      />

      <FormSelectorAsync
        name="discipline"
        label="Componente Curricular"
        placeholder="Selecione um componente curricular"
        grid={{ xs: 4 }}
        getOptions={(inputValue) => getDisciplines(inputValue)}
      />
      <FormSelectorAsync
        name="classroom"
        label="Turma"
        placeholder="Selecione uma turma"
        grid={{ xs: 4 }}
        getOptions={(inputValue) => getClassroom(inputValue)}
      />

      <FormSelectorAsync
        name="grade"
        label="Série"
        placeholder="Selecione uma série"
        getOptions={(inputValue) => getGrades(inputValue)}
      />
    </>
  );
};

const DigitalObjectPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [
      { Header: 'Nome da Obra', accessor: 'libraryObject.name', width: 175 },
      { Header: 'Disciplina', accessor: 'discipline.name', width: 100 },
      { Header: 'Série', accessor: 'grade.name', width: 75 }
    ],
    []
  );

  return (
    <AdminContainer
      title="Gestão de acesso aos Objetos Digitais"
      repository={digitalObjectRepository}
      columns={columns}
      components={{
        Filter: DigitalObjectFilter,
        FormActions: DigitalObjectActions
      }}
      disableNew
      disableEdit
    />
  );
};

export default DigitalObjectPage;
