import React from 'react';
import { AdminActions } from './types';

type IAdminActionsContext = {
  onCreate: () => void;
  onEdit: (path: string, items: Array<string>) => void;
  items: Array<any>;
  selected: Array<string>;
  actions: Array<AdminActions>;
}

export const AdminActionsContext = React.createContext<IAdminActionsContext>(null);

export default AdminActionsContext;
