import FormModel from './interfaces/FormModel';

export type RatingInput = {
  id?: string;
  minimumValue: number;
  rating: string;
};

export class Rating implements FormModel<RatingInput> {
  id?: string;
  minimumValue?: number;
  rating?: string;

  static fromJson(data: any): Rating {
    const rating = new Rating();

    rating.id = data?.Id;
    rating.minimumValue = data?.MinimumValue;
    rating.rating = data?.Rating;

    return rating;
  }

  toInput = (): RatingInput => ({
    id: this.id,
    minimumValue: this.minimumValue,
    rating: this.rating
  });
}
