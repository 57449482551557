import { Response, PaginationModel } from '@campus/commons';

import { Classroom, ClassroomFilter, ClassroomInput } from 'models/Classroom';
import { AdminRepository } from './interfaces/AdminRepository';

class ClassroomRepository extends AdminRepository<
  ClassroomFilter,
  ClassroomInput,
  Classroom
> {
  path: string = '/class';

  async get(id: string): Promise<Response<Classroom>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);

      const data = Classroom.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async getAll(
    filter: ClassroomFilter
  ): Promise<Response<PaginationModel<Classroom>>> {
    try {
      const { name, teacher, student, grade, discipline, page, pageSize } =
        filter;
      const skipCount = page * pageSize;
      const response = await super.api.get(this.path, {
        params: {
          Name: name,
          TeacherId: teacher?.value,
          StudentId: student?.value,
          GradeId: grade?.value,
          DisciplineId: discipline?.value,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(
        Classroom,
        response.data,
        skipCount
      );

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: ClassroomInput): Promise<Response<boolean>> {
    try {
      const response = await super.api.post(
        this.path,
        Classroom.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(input: ClassroomInput, id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.put(
        `${this.path}/${id}`,
        Classroom.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async updateParticalDiscipline(
    id: string,
    current: string,
    newId: string
  ): Promise<Response<boolean>> {
    try {
      const response = await super.api.put(
        `${this.path}/${id}/disciplineMatrix/${current}/${newId}`
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}
export default ClassroomRepository;
