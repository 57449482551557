import { EvaluationType, Stage } from '@campus/commons';
import FormModel from './interfaces/FormModel';

export type EvaluationInput = {
  id?: string;
  stage: Stage;
  evaluationType: EvaluationType;
  performanceWeight: number;
  engagementWeight: number;
};

export type EvaluationDTO = {
  Id: string;
  StageId: string;
  EvaluationTypeId: string;
  PerformanceWeight: number;
  EngagementWeight: number;
};

export class Evaluation implements FormModel<EvaluationInput> {
  id?: string;
  evaluationType?: EvaluationType;
  stage?: Stage;
  performanceWeight?: number;
  engagementWeight?: number;

  static fromJson(data: any): Evaluation {
    const evaluation = new Evaluation();

    evaluation.id = data?.Id;
    evaluation.evaluationType = EvaluationType.fromJson(data?.EvaluationType);
    evaluation.stage = Stage.fromJson(data?.Stage);
    evaluation.performanceWeight = data?.PerformanceWeight;
    evaluation.engagementWeight = data?.EngagementWeight;

    return evaluation;
  }

  static fromInputToDTO = (input: EvaluationInput): EvaluationDTO => {
    return {
      Id: input?.id,
      EvaluationTypeId: input?.evaluationType?.id,
      StageId: input?.stage?.id,
      PerformanceWeight: input?.performanceWeight ?? 0,
      EngagementWeight: input?.engagementWeight ?? 0
    };
  };

  toInput = (): EvaluationInput => ({
    id: this.id,
    stage: this.stage,
    evaluationType: this.evaluationType,
    engagementWeight: this.engagementWeight,
    performanceWeight: this.performanceWeight
  });
}
