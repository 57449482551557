import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DataGrid, DataGridProps, Checkbox } from '@campus/components';

import useAdminRepository from 'hooks/useAdminRepository';
import TableError from 'components/TableError';
import { AdminRepository } from 'repositories/interfaces';

import { Card, Header, Title } from './styles';

import AdminFilter from '../AdminFilter';
import { AdminActionsComponents } from '../../types';
import AdminActionsContext from '../../AdminActionsContext';

type AdminTableProps = {
  title: string;
  columns: DataGridProps['columns'];
  repository: AdminRepository;
  components: AdminActionsComponents;
};

export type AdminContext = {
  onSubmit: () => void;
};

const AdminTable: React.FC<AdminTableProps> = ({
  title,
  columns,
  repository,
  components
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);

  const { error, items, loading, pageCount, onFilter, onFetch } =
    useAdminRepository({
      onFetch: (d) => repository.getAll(d),
      onDelete: (d) => repository.delete(d)
    });

  const onCreate = () => navigate({ pathname: 'new', search });
  const handleSubmited = () => onFetch(0, 10);
  const onEdit = (path: string, items: Array<string>) =>
    navigate({ pathname: path, search: `${search}&ids=${items.join()}` });

  const Filter = components.Filter;
  const ErrorPage = components.ErrorPage ?? TableError;

  const handleCheck = (hasItem, value) => {
    if (hasItem) {
      return setSelectedItems(selectedItems.filter((f) => f !== value));
    }
    return setSelectedItems([...selectedItems, value]);
  };

  return (
    <AdminActionsContext.Provider
      value={{
        onCreate,
        onEdit,
        items: items,
        selected: selectedItems,
        actions: components.actions
      }}
    >
      <Header>
        <Title>{title}</Title>
      </Header>

      <AdminFilter
        loading={loading}
        onFilter={onFilter}
        onClear={() => setSelectedItems([])}
      >
        <Filter />
      </AdminFilter>

      <Card>
        {!!error ? (
          <ErrorPage error={error} />
        ) : (
          <DataGrid
            pageCount={pageCount}
            onFetchData={onFetch}
            data={items}
            loading={loading}
            columns={[
              {
                Header: '',
                accessor: 'id',
                width: 40,
                maxWidth: 40,
                Cell: ({ value }) => {
                  const hasItem =
                    selectedItems.filter((f) => f === value).length > 0;

                  return (
                    <Checkbox
                      name={value}
                      onChange={() => handleCheck(hasItem, value)}
                      checked={hasItem}
                    />
                  );
                }
              },
              ...columns
            ]}
          />
        )}
      </Card>

      <Outlet context={{ onSubmit: handleSubmited } as AdminContext} />
    </AdminActionsContext.Provider>
  );
};

export default AdminTable;
