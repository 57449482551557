import styled, { keyframes } from 'styled-components';

const slide = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
`;

export const StyledDrawer = styled.aside`
  overflow: hidden auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 700px;
  height: 100%;
  box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
  padding: 1.5rem;
  animation: ${slide} 0.1s linear;
  background-color: #fff;
  max-width: 100vw;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
