import { Api } from '@campus/auth';
import { Response } from '@campus/commons';

import { ProfileRole } from 'models/ProfileRole';
import UserProfileRepository from 'repositories/UserProfileRepository';

export const getRoles = async (): Promise<Response<ProfileRole[]>> => {
  try {
    const api = Api.INSTANCE.getApi();

    const response = await api.get('/profile/roles');

    const data = response.data?.map(ProfileRole.fromJson) ?? [];

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
};

export const getProfileUsers = async (text: string) => {
  const userProfileRepository = new UserProfileRepository();

  const response = await userProfileRepository.getAll(text);

  if (response.data) {
    return response.data.map((i) => ({
      value: i.id,
      label: i.name
    }));
  }

  return [];
};
