import { SelectOption } from '@campus/components';
import {
  ClassDisciplines,
  ClassDisciplinesInput,
  ClassDisciplinesDTO
} from './ClassDisciplines';
import { Grade } from './Grade';

import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';
import { Period } from './Period';

export interface ClassroomFilter extends PaginatedFilter {
  name?: string;
  student?: SelectOption;
  teacher?: SelectOption;
  grade?: SelectOption;
  discipline?: SelectOption;
}

export type ClassroomInput = {
  id: string;
  name: string;
  grade?: SelectOption;
  period?: SelectOption;
  classDisciplines: ClassDisciplinesInput[];
};

export type ClassroomTypeDTO = {
  Name: string;
  GradeId: string;
  PeriodId: string;
  ClassDisciplines: ClassDisciplinesDTO[];
};

export class Classroom implements FormModel<ClassroomInput> {
  id?: string;
  name?: string;

  grade?: Grade;
  period?: Period;

  classDisciplines?: ClassDisciplines[];

  static fromJson(data: any): Classroom {
    const classroom = new Classroom();

    classroom.id = data?.Id;
    classroom.name = data?.Name;
    classroom.grade = Grade.fromJson(data?.Grade);
    classroom.period = Period.fromJson(data?.Period);

    classroom.classDisciplines =
      data?.ClassDisciplines?.map(ClassDisciplines.fromJson) ?? [];
    return classroom;
  }

  static fromInputToDTO(input: ClassroomInput): ClassroomTypeDTO {
    return {
      Name: input?.name,
      GradeId: input.grade?.value?.toString(),
      PeriodId: input.period?.value?.toString(),
      ClassDisciplines: input?.classDisciplines.map(
        ClassDisciplines.fromInputToDTO
      )
    };
  }

  toInput = (): ClassroomInput => ({
    id: this.id,
    name: this.name,
    grade: { label: this.grade.name, value: this.grade.id },
    period: { label: this.period.name, value: this.period.id },
    classDisciplines: this.classDisciplines.map((c) => c.toInput())
  });
}
