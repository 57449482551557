import { useCallback, useEffect, useState } from 'react';

import { SelectOption, toast } from '@campus/components';

import UserProfileRepository from 'repositories/UserProfileRepository';
import { ProfileUser } from 'models/ProfileUser';

const userProfileRepository = new UserProfileRepository();

export const useProfileUser = (id: string) => {
  const [loading, seLoading] = useState(true);
  const [users, setUsers] = useState<ProfileUser[]>([]);

  const loadUsers = useCallback(async () => {
    seLoading(true);
    const response = await userProfileRepository.get(id);

    if (response.data) {
      setUsers(response.data.items);
    }
    seLoading(false);
  }, [id]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const handleAddUser = async (
    unit: SelectOption<string>,
    user: SelectOption<string>
  ) => {
    const userId = user.value;
    const profileUser = { id, userId, unitId: unit.value };

    const res = await userProfileRepository.create(profileUser);
    if (res.data) {
      setUsers((u) => [...u, res.data]);
    } else if (res.error) {
      toast.error(res.error.message);
    }
  };

  const handleRemoveUser = async (profile: ProfileUser) => {
    const userId = profile?.user?.id;
    const unitId = profile?.unit?.id;
    const profileUser = { id, userId, unitId };

    const res = await userProfileRepository.delete(profileUser);
    if (res.data) {
      setUsers((users) => users.filter((u) => u.user.id === userId));
    } else if (res.error) {
      toast.error(res.error.message);
    }
  };

  return {
    users,
    loading,
    addUser: handleAddUser,
    removeUser: handleRemoveUser
  };
};
