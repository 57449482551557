import React, { useCallback, useEffect, useState } from 'react';

import { Check2Square } from '@campus/components/icons';
import {
  Button,
  CampusIcon,
  Checkbox,
  Input,
  Pagination,
  toast
} from '@campus/components';

import { ActionProps } from 'containers/AdminContainer';

import {
  ActionsContainer,
  Avatar,
  AvatarAndName,
  DivActions,
  DivPage,
  DivSearch,
  Footer,
  TableStyled
} from './styles';

import Drawer from 'components/Drawer';
import {
  LibraryObjectStudentPermission,
  StudentsPermissions
} from 'models/LibraryObjectStudentPermission';
import { digitalObjectRepository } from '../DigitalObject';

type StudentsPermissionsDto = StudentsPermissions & {
  isModified?: boolean;
};

const DigitalObjectActions: React.FC<
  ActionProps<LibraryObjectStudentPermission>
> = ({ data: libraryObjectStudentPermission }) => {
  const [showModal, setShowModal] = useState(false);
  const [studentsPermissions, setStudentsPermissions] = useState<
    StudentsPermissionsDto[]
  >([]);
  const [filteredStudentsPermissions, setFilteredStudentsPermissions] =
    useState<StudentsPermissionsDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [studentsPagination, setStudentsPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const pageSize = 10;

  const handleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  let subtitle = `Referente ao objeto '${libraryObjectStudentPermission.libraryObject.name}'`;
  if (libraryObjectStudentPermission.discipline?.name) {
    subtitle += ` da disciplina '${libraryObjectStudentPermission.discipline.name}'`;
  }

  if (libraryObjectStudentPermission.grade?.name) {
    subtitle += ` (${libraryObjectStudentPermission.grade.name})`;
  }

  const loadData = useCallback(async () => {
    const getUniquesStudentsPermission = (
      studentsPermissions: StudentsPermissionsDto[]
    ) => {
      const map = new Map<string, StudentsPermissionsDto>();

      for (const studentPermission of studentsPermissions) {
        map.set(studentPermission.student.id, studentPermission);
      }

      return Array.from(map.values());
    };

    try {
      setLoading(true);
      const result =
        await digitalObjectRepository.getAllStudentsByLibraryObject({
          libraryObjectId: libraryObjectStudentPermission.libraryObject.id,
          disciplineId: libraryObjectStudentPermission.discipline?.id,
          gradeId: libraryObjectStudentPermission.grade?.id
        });
      const studentsPermissions = result?.data?.length
        ? getUniquesStudentsPermission(result?.data ?? [])
        : [];
      setStudentsPermissions(studentsPermissions);
      setTotalPages(Math.ceil(studentsPermissions.length / pageSize));
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao carregar permissões dos estudantes');
    } finally {
      setLoading(false);
    }
  }, [
    libraryObjectStudentPermission.discipline?.id,
    libraryObjectStudentPermission.grade?.id,
    libraryObjectStudentPermission.libraryObject.id
  ]);

  useEffect(() => {
    if (showModal) {
      loadData();
    }
  }, [loadData, showModal]);

  useEffect(() => {
    setFilteredStudentsPermissions(studentsPermissions);
  }, [studentsPermissions]);

  useEffect(() => {
    if (studentsPermissions && studentsPermissions.length > 0) {
      const students = studentsPermissions.slice(
        (page - 1) * pageSize,
        page * pageSize
      );
      setStudentsPagination(students);
    }
  }, [studentsPermissions, page]);

  useEffect(() => {
    if (filteredStudentsPermissions && filteredStudentsPermissions.length > 0) {
      const students = filteredStudentsPermissions.slice(
        (page - 1) * pageSize,
        page * pageSize
      );
      setStudentsPagination(students);
    }
  }, [filteredStudentsPermissions, page]);

  const goToFirstPage = () => {
    setPage(1);
  };

  const goToPrevPage = () => {
    setPage((prev) => prev - 1);
  };

  const goToNextPage = () => {
    setPage((prev) => prev + 1);
  };

  const goToLastPage = () => {
    setPage(Math.ceil(studentsPermissions.length / pageSize));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value === '') {
      setTotalPages(Math.ceil(studentsPermissions.length / pageSize));
      setStudentsPagination(studentsPermissions.slice(0, pageSize));
    } else {
      let filteredStudents = studentsPermissions.filter((student) =>
        student.student.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
      if (filteredStudents.length > pageSize) {
        setTotalPages(Math.ceil(filteredStudents.length / pageSize));
        filteredStudents = filteredStudents.slice(0, pageSize);
      } else {
        setTotalPages(1);
      }
      setFilteredStudentsPermissions(filteredStudents);
      setStudentsPagination(filteredStudents.slice(0, pageSize));
    }
    setPage(1);
  };

  const handleChangePermission = (studentId: string) => {
    setFilteredStudentsPermissions((prev) => {
      const newStudentsPermissions = [...prev];

      newStudentsPermissions.forEach((permission) => {
        if (permission.isModified === undefined) {
          permission.isModified = false;
        }

        if (permission.student.id === studentId) {
          permission.hasPermission = !permission.hasPermission;
          permission.isModified = true;
        }
      });

      return newStudentsPermissions;
    });
  };

  const handleSave = useCallback(async () => {
    const permissionsMapped = studentsPermissions
      .filter((permission) => permission.isModified)
      .map((permission) => ({
        studentId: permission.student.id,
        hasPermission: permission.hasPermission
      }));

    const hasPermission = permissionsMapped.filter(
      (permission) => permission.hasPermission
    );
    const hasNotPermission = permissionsMapped.filter(
      (permission) => !permission.hasPermission
    );

    if (hasPermission.length > 0) {
      await digitalObjectRepository.updateMany(
        {
          studentsIds: hasPermission.map((permission) => permission.studentId),
          permission: true
        },
        libraryObjectStudentPermission.libraryObject.id
      );
    }

    if (hasNotPermission.length > 0) {
      await digitalObjectRepository.updateMany(
        {
          studentsIds: hasNotPermission.map(
            (permission) => permission.studentId
          ),
          permission: false
        },
        libraryObjectStudentPermission.libraryObject.id
      );
    }

    handleModal();
    toast.success('Permissões atualizadas com sucesso!');
  }, [
    handleModal,
    libraryObjectStudentPermission.libraryObject.id,
    studentsPermissions
  ]);

  return (
    <>
      <ActionsContainer>
        <Check2Square size={16} onClick={handleModal} />
      </ActionsContainer>
      {showModal && (
        <Drawer
          title="Permissões dos Estudantes"
          subTitle={subtitle}
          onClose={handleModal}
        >
          {!loading && (
            <>
              <DivSearch>
                <Input
                  placeholder="Pesquisar Estudante"
                  onChange={handleSearch}
                  value={search}
                  name="search"
                  StartIcon={<CampusIcon name="search" />}
                />
              </DivSearch>
              <TableStyled>
                <thead>
                  <tr>
                    <th>Aluno</th>
                    <th>Acesso</th>
                  </tr>
                </thead>
                <tbody>
                  {studentsPagination.map(
                    ({ student, hasPermission, libraryObject }) => (
                      <tr key={student.id}>
                        <td>
                          <div>
                            <AvatarAndName>
                              <Avatar img={student.imageUrl} />
                              <span>{student.name}</span>
                            </AvatarAndName>
                          </div>
                        </td>
                        <td>
                          <Checkbox
                            checked={hasPermission}
                            name={`permission-${student.id}`}
                            onChange={() => {
                              handleChangePermission(student.id);
                            }}
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </TableStyled>
              <Footer>
                <DivPage>
                  <span>
                    Página{' '}
                    <strong>
                      {page} de {totalPages}
                    </strong>
                  </span>
                </DivPage>
                <Pagination>
                  <Pagination.First
                    onClick={goToFirstPage}
                    disabled={page === 1}
                  />
                  <Pagination.Prev
                    onClick={goToPrevPage}
                    disabled={page === 1}
                  />
                  <Pagination.Next
                    onClick={goToNextPage}
                    disabled={page === totalPages}
                  />
                  <Pagination.Last
                    onClick={goToLastPage}
                    disabled={page === totalPages}
                  />
                </Pagination>
              </Footer>
              <DivActions>
                <Button onClick={handleModal} variant="secondary">
                  Cancelar
                </Button>
                <Button onClick={handleSave} variant="primary">
                  Salvar
                </Button>
              </DivActions>
            </>
          )}
        </Drawer>
      )}
    </>
  );
};

export default DigitalObjectActions;
