import EducationLevelRepository from 'repositories/EducationLevelRepository';

export const getEducationLevels = async (name: string) => {
  const educationLevelRepository = new EducationLevelRepository();

  const educationLevels = await educationLevelRepository.getAll({
    name: name,
    page: 0,
    pageSize: 10
  });

  return educationLevels.data.items.map((i) => ({
    value: i.id,
    label: i.name
  }));
};
