import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import { Classification, ClassificationInput } from 'models/Classification';

class ClassificationRepository {
  path: string = '/classificationtype';

  get api() {
    return Api.INSTANCE.getApi();
  }

  async getAll(
    typeId: string,
    parent?: string
  ): Promise<Response<Classification[]>> {
    try {
      const response = await this.api.get(
        `${this.path}/${typeId}/classification/tree`,
        { params: { parentId: parent } }
      );

      const data =
        response.data?.map((d) => Classification.fromJson(d, parent)) ?? [];

      return Response.fromData(data, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }

  async create(
    typeId: string,
    input: ClassificationInput
  ): Promise<Response<boolean>> {
    try {
      const response = await this.api.post(
        `${this.path}/${typeId}/classification`,
        Classification.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }

  async update(
    typeId: string,
    input: ClassificationInput
  ): Promise<Response<boolean>> {
    try {
      const response = await this.api.put(
        `${this.path}/${typeId}/classification/${input.id}`,
        Classification.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }

  async delete(typeId: string, id: string): Promise<Response<boolean>> {
    try {
      const response = await this.api.delete(
        `${this.path}/${typeId}/classification/${id}`
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }
}

export default ClassificationRepository;
