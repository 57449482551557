export class User {
  id?: string;

  email?: string;

  name?: string;

  imageUrl?: string;

  imageHash?: string;

  integrationId?: string;

  static fromJson(data: any): User {
    const user = new User();

    user.id = data?.Id;

    user.email = data?.Email;

    user.name = data?.Name;

    user.imageUrl = data?.ImageUrl;

    user.imageHash = data?.imageHash;

    user.integrationId = data?.integrationId;

    return user;
  }
}
