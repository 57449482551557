import React, { useContext, useEffect, useMemo } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';

import {
  Row,
  CollapserItem,
  CollapserContext,
  FormInput,
  FormCheckbox,
  FormSelector,
  FormSelectorAsync
} from '@campus/components';
import { affectIndexOptions } from 'utils/AffectIndexOptions';
import { getEvaluationTypes } from 'services/services';
import { CloseAccordion } from '../styles';

type EvaluationInputItemProps = {
  index: number;
  remove: () => void;
};

export const inputName = 'evaluationTypes';

const EvaluationInputItem: React.FC<EvaluationInputItemProps> = ({
  index,
  remove
}) => {
  const fieldName = `${inputName}.${index}`;

  const { control } = useFormContext();

  const type = useWatch({ control, name: `${fieldName}.type` });
  const { isSubmitting, errors } = useFormState();

  const { forceOpened } = useContext(CollapserContext);

  useEffect(() => {
    if (isSubmitting) {
      const evaluationTypes = errors?.evaluationTypes;

      if (evaluationTypes instanceof Array) {
        const i = evaluationTypes.findIndex((e) => e);
        forceOpened(i);
      }
    }
  }, [isSubmitting, errors, forceOpened]);

  const title = useMemo(() => {
    const name = type?.label;
    if (!name || name?.length === 0) {
      return `Avaliação ${index + 1}`;
    }

    return name;
  }, [type, index]);

  const handleGetEvaluationTypes = async (inputValue) => {
    const evaluationTypesResponse = await getEvaluationTypes(inputValue);
    const evaluationTypes = evaluationTypesResponse.data.map((e) =>
      e.toSelectOption()
    );
    return evaluationTypes;
  };

  return (
    <CollapserItem
      eventKey={index}
      header={
        <>
          <CloseAccordion onClick={remove} />
          {title}
        </>
      }
    >
      <Row className="gy-2 gx-2">
        <FormSelectorAsync
          name={`${fieldName}.type`}
          label="Tipo"
          getOptions={(inputValue: string) =>
            handleGetEvaluationTypes(inputValue)
          }
          required
          isClearable={false}
        />

        <FormSelector
          name={`${fieldName}.affectIndex`}
          label="Afeta o índice"
          options={affectIndexOptions}
        />

        <FormInput
          name={`${fieldName}.performanceDescription`}
          label="Descrição da performance"
          placeholder="Insira a descrição"
          required
        />

        <FormInput
          name={`${fieldName}.engagementDescription`}
          label="Descrição do engajamento"
          placeholder="Insira a descrição"
          required
        />

        <FormCheckbox
          name={`${fieldName}.performanceEvaluable`}
          label="Exibir performance"
        />

        <FormCheckbox
          name={`${fieldName}.engagementEvaluable`}
          label="Exibir engajamento"
        />
      </Row>
    </CollapserItem>
  );
};

export default EvaluationInputItem;
