import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.gray2};
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 25px;
  margin: 0;
`;

export const Card = styled.div`
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1rem 1rem;
`;
