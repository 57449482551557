import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { FormSelectorAsync } from '@campus/components';

import { getClassroom } from 'services/services';

import DisciplineInput from 'components/DisciplineInput';

const ClassroomDisciplineInput: React.FC = () => {
  const { control } = useFormContext();
  const classroom = useWatch({ control, name: 'classroom' });

  return (
    <>
      <FormSelectorAsync
        name="classroom"
        label="Turma"
        placeholder="Selecione uma turma"
        required
        getOptions={(value) => getClassroom(value)}
      />

      <DisciplineInput name="discipline" classroomId={classroom?.value} />
    </>
  );
};

export default ClassroomDisciplineInput;
