import ClassroomRepository from 'repositories/ClassroomRepository';

export const getClassroom = async (name: string, pageSize: number = 10) => {
  const classroomRepository = new ClassroomRepository();

  const classrooms = await classroomRepository.getAll({
    page: 0,
    name,
    pageSize
  });

  return classrooms.data.items.map((i) => ({
    value: i.id,
    label: i.name
  }));
};
