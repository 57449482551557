import { Response, PaginationModel, User } from '@campus/commons';
import { Manager, ManagerFilter, ManagerInput } from 'models/Manager';
import { AdminRepository } from './interfaces/AdminRepository';

class ManagerRepository extends AdminRepository<
  ManagerFilter,
  ManagerInput,
  Manager
> {
  path: string = '/manager';
  isSearch: boolean = true;

  async getAll(
    filter: ManagerFilter
  ): Promise<Response<PaginationModel<Manager>>> {
    try {
      const { name, page, pageSize } = filter;
      const skipCount = page * pageSize;
      const response = await super.api.get(this.path, {
        params: {
          Name: name,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });
      const data = PaginationModel.fromJson(Manager, response.data, skipCount);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<Manager>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);

      const data = Manager.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: ManagerInput): Promise<Response<boolean>> {
    try {
      const { name, birthday, email, imageUrl, classRoom } = input;

      const response = await super.api.post(this.path, {
        Birthday: birthday,
        ImageUrl: imageUrl,
        User: {
          Name: name,
          Email: email,
          ImageUrl: imageUrl
        },
        Classes: classRoom?.map((item) => item.value)
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(input: ManagerInput, id: string): Promise<Response<boolean>> {
    try {
      const { userId, name, birthday, email, imageUrl, classRoom } = input;

      const response = await super.api.put(`${this.path}/${id}`, {
        Birthday: birthday,
        ImageUrl: imageUrl,
        User: {
          Id: userId,
          Name: name,
          Email: email,
          ImageUrl: imageUrl
        },
        Classes: classRoom?.map((item) => item.value)
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async searchByKey(user: User): Promise<Response<User>> {
    try {
      const response = await super.api.get(`${this.path}/user`, {
        params: { email: user.email }
      });

      const data = User.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}

export default ManagerRepository;
