import GradeRepository from 'repositories/GradeRepository';

export const getGrades = async (name: string) => {
  const gradeRepository = new GradeRepository();

  const grade = await gradeRepository.getAll({
    name: name,
    page: 0,
    pageSize: 100
  });

  return grade.data.items.map((i) => ({
    value: i.id,
    label: i.name
  }));
};
