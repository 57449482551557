import React from 'react';
import { Checkbox, SelectOption } from '@campus/components';

type ManagerClassRoomItemProps = {
  index: number;
  onAdd: (key) => void;
  onRemove: (key) => void;
  item: SelectOption;
  checked: boolean;
};

const ManagerClassRoomItem: React.FC<ManagerClassRoomItemProps> = ({
  index,
  item,
  checked,
  onAdd,
  onRemove
}) => {
  const handleChecked = () => {
    if (checked) {
      return onRemove(index);
    }

    return onAdd(item);
  };

  return (
    <Checkbox
      name={item.label}
      label={item.label}
      checked={checked}
      onChange={handleChecked}
    />
  );
};

export default ManagerClassRoomItem;
