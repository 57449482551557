import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, Modal } from '@campus/components';

import { useAdminForm } from 'components/AdminForm';

import ClassificationTypeRepository from 'repositories/ClassificationTypeRepository';
import { ClassificationTypeInput } from 'models/ClassificationType';

import ClassificationTypeForm from './components/ClassificationTypeForm';
import ClassificationBody from './components/ClassificationBody';

import { ModalBody, Footer, Separator } from './styles';

type ClassificationModalProps = {
  repository: ClassificationTypeRepository;
};

const ClassificationModal: React.FC<ClassificationModalProps> = ({
  repository
}) => {
  const navigate = useNavigate();

  const { onSubmit, defaultValues, loadingData, loadingSubmit } =
    useAdminForm<ClassificationTypeInput>(repository);

  const methods = useForm({ defaultValues: defaultValues });
  const { reset, handleSubmit } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onFormSubmit = handleSubmit((data) => onSubmit(data));

  const onCloseModal = () => navigate(-1);

  return (
    <FormProvider {...methods}>
      <Modal
        show
        size="xl"
        $fullHeight
        aria-labelledby="classification-modal"
        onHide={onCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="classification-modal">
            Criar Tipo de classificação
          </Modal.Title>
        </Modal.Header>
        <ModalBody>
          <ClassificationTypeForm loadingData={loadingData} />
          <Separator />
          <ClassificationBody
            typeId={defaultValues?.id}
            loadingData={loadingData}
          />
        </ModalBody>
        <Footer>
          <div>
            <Button variant="outline-secondary" onClick={onCloseModal}>
              Cancelar
            </Button>
          </div>

          <Button loading={loadingSubmit} onClick={onFormSubmit}>
            Confirmar
          </Button>
        </Footer>
      </Modal>
    </FormProvider>
  );
};

export default ClassificationModal;
