import React, { useContext, createContext } from 'react';

import { ContextType } from '@campus/auth';
import { FullSpinner } from '@campus/components';
import { StudyPeriod, usePeriod } from '@campus/commons';

type AdminMainContext = {
  period: StudyPeriod;
  periods: StudyPeriod[];
  setPeriod: (period: StudyPeriod) => void;
};

const Context = createContext<AdminMainContext | null>(null);

export const useAdminMainContext = () => useContext(Context);

const AdminMainContextProvider: React.FC = ({ children }) => {
  const {
    period,
    periods,
    loading: loadingPeriods,
    changePeriod: setPeriod
  } = usePeriod(ContextType.ADMIN);

  if (loadingPeriods) {
    return <FullSpinner title="Carregando períodos de estudo..." />;
  }

  return (
    <Context.Provider
      value={{
        periods,
        period,
        setPeriod
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AdminMainContextProvider;
