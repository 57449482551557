import React, { useContext, useRef, useState } from 'react';
import { toast } from '@campus/components';

import { ClassificationInput } from 'models/Classification';
import ClassificationRepository from 'repositories/ClassificationRepository';

type OnRefresh = () => void;

type ClassificationContextProps = {
  selected: ClassificationInput;
  onCreate: (input: ClassificationInput) => void;
  onDelete: (id: String, refresh: OnRefresh) => Promise<void>;
  onSelect: (input: ClassificationInput, refresh: OnRefresh) => void;
};

const ClassificationContext = React.createContext(
  {} as ClassificationContextProps
);

export const useClassification = () => useContext(ClassificationContext);

type ClassificationProviderProps = {
  typeId: string;
};
const classificationRepository = new ClassificationRepository();

const ClassificationProvider: React.FC<ClassificationProviderProps> = ({
  typeId,

  children
}) => {
  const [selected, setSelected] = useState<ClassificationInput>(null);

  const refreshRef = useRef(() => {});

  const handleEdit = (input: ClassificationInput, refresh: OnRefresh) => {
    setSelected(input);
    refreshRef.current = refresh;
  };

  const handleCreate = async (input: ClassificationInput) => {
    const response = input.id
      ? await classificationRepository.update(typeId, input)
      : await classificationRepository.create(typeId, input);

    if (response.data) {
      refreshRef.current();
      setSelected(null);
    } else if (response.error) {
      toast.error(response.error.message);
    }
  };

  const handleDelete = async (id: string, refresh: OnRefresh) => {
    const response = await classificationRepository.delete(typeId, id);

    if (response.data) {
      refresh();
    } else if (response.error) {
      toast.error(response.error.message);
    }
  };

  return (
    <ClassificationContext.Provider
      value={{
        selected,
        onDelete: handleDelete,
        onCreate: handleCreate,
        onSelect: handleEdit
      }}
    >
      {children}
    </ClassificationContext.Provider>
  );
};

export default ClassificationProvider;
