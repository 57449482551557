import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import EvaluationMatrixRepository from 'repositories/EvaluationMatrixRepository';
import { EvaluationTypeDescription } from 'models/EvaluationTypeDescription';

export const getEvaluationMatrices = async (name: string) => {
  const evaluationMatrixRepository = new EvaluationMatrixRepository();

  const matrices = await evaluationMatrixRepository.getAll({
    name: name,
    page: 0,
    pageSize: 10
  });

  return matrices.data.items.map((i) => ({
    value: i.id,
    label: i.name
  }));
};

export const getEvaluationTypes = async (
  name: string
): Promise<Response<EvaluationTypeDescription[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().get('/evaluationtype', {
      params: { Name: name }
    });

    return Response.fromData(
      response.data.Items.map((item) =>
        EvaluationTypeDescription.fromJson(item)
      ),
      response.status
    );
  } catch (error) {
    return Response.error(error);
  }
};
