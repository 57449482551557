import React from 'react';

import { DataGridProps } from '@campus/components';

import ClassroomDisciplineRepository from 'repositories/ClassroomDisciplineRepository';

import { ActionProps } from 'containers/AdminContainer';
import AdminTable, { DeleteButton } from 'components/AdminTable';
import { useAdminContext } from 'providers/AdminProvider';

import { Student } from '@campus/commons';
import AppBarActions from './components/AppBarActions';
import ClassroomDisciplineInput from './components/ClassroomDisciplineInput';

const _repository = new ClassroomDisciplineRepository();

const FormActions: React.FC<ActionProps<Student>> = ({ data }) => {
  const { onDelete, filterData } = useAdminContext();

  const handleDelete = async () => {
    const classId = filterData?.classroom?.value;
    const disciplineId = filterData?.discipline?.value;

    await onDelete({ id: data.id, classId, disciplineId });
  };

  return <DeleteButton onDelete={handleDelete} />;
};

const ClassroomDisciplinePage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [{ Header: 'Nome', accessor: 'name' }],
    []
  );

  return (
    <AdminTable
      title="Cadastro de turma/componente curricular"
      columns={columns}
      components={{
        Filter: ClassroomDisciplineInput,
        FormActions,
        AppBarActions
      }}
      onFetch={_repository.getAll}
      onDelete={_repository.delete}
    />
  );
};

export default ClassroomDisciplinePage;
