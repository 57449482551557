import { SelectOption } from '@campus/components';
import FormModel from './interfaces/FormModel';
import { PaginatedFilter } from './interfaces';

import { Discipline } from './Discipline';
import { Grade } from './Grade';
import { Period } from './Period';
import { Classroom } from './Classroom';
import { EvaluationMatrix } from './EvaluationMatrix';
import { Evaluation, EvaluationInput, EvaluationDTO } from './Evaluation';

export interface DisciplineMatrixFilter extends PaginatedFilter {
  grade: SelectOption;
  period: SelectOption;
  discipline: SelectOption;
  classroom: SelectOption;
}

export type DisciplineMatrixInput = {
  id?: string;
  ids?: Array<string>;
  grade: SelectOption;
  period: SelectOption;
  discipline: SelectOption;
  evaluationMatrix: SelectOption;
  showPerformance: boolean;
  evaluations?: Array<EvaluationInput>;
};

export type DisciplineMatrixDTO = {
  Id?: string;
  Ids?: Array<string>;
  DisciplineId: string;
  EvaluationMatrixId: string;
  GradeId: string;
  PeriodId: string;
  ShowPerformance: boolean;
  Evaluations?: Array<EvaluationDTO>;
};

export class DisciplineMatrix implements FormModel<DisciplineMatrixInput> {
  id: string;

  ids?: Array<string>;

  discipline?: Discipline;

  grade?: Grade;

  period?: Period;

  classDisciplines?: Classroom[];

  evaluationMatrix?: EvaluationMatrix;

  showPerformance?: boolean;

  evaluations?: Array<Evaluation>;

  static fromJson(data: any): DisciplineMatrix {
    const disciplineMatrix = new DisciplineMatrix();

    disciplineMatrix.id = data?.Id;
    disciplineMatrix.discipline = Discipline.fromJson(data?.Discipline);
    disciplineMatrix.grade = Grade.fromJson(data?.Grade);
    disciplineMatrix.period = Period.fromJson(data?.Period);
    disciplineMatrix.evaluationMatrix = EvaluationMatrix.fromJson(
      data?.EvaluationMatrix
    );
    disciplineMatrix.showPerformance = data?.ShowPerformance;

    disciplineMatrix.classDisciplines =
      data?.ClassDisciplines?.map(Classroom.fromJson) ?? [];

    disciplineMatrix.evaluations =
      data?.Evaluations?.map(Evaluation.fromJson) ?? [];

    return disciplineMatrix;
  }

  get label() {
    return [this.discipline.name, this.grade?.name, this.period?.name]
      .filter(Boolean)
      .join(' - ');
  }

  static fromInputToDTO = (
    input: DisciplineMatrixInput
  ): DisciplineMatrixDTO => {
    return {
      Ids: input?.ids,
      ShowPerformance: input.showPerformance,
      DisciplineId: input.discipline?.value?.toString(),
      EvaluationMatrixId: input.evaluationMatrix?.value?.toString(),
      GradeId: input.grade?.value?.toString(),
      PeriodId: input.period?.value?.toString(),
      Evaluations: input.evaluations?.map(Evaluation.fromInputToDTO)
    };
  };

  toInput = (): DisciplineMatrixInput => ({
    id: this.id,
    ids: this.ids,
    showPerformance: this.showPerformance,
    grade: { value: this.grade.id, label: this.grade.name },
    period: { value: this.period.id, label: this.period.name },
    discipline: { value: this.discipline.id, label: this.discipline.name },
    evaluationMatrix: {
      value: this.discipline.id,
      label: this.discipline.name
    },
    evaluations: this.evaluations?.map((e) => e.toInput())
  });
}
