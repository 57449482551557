import { Api } from '@campus/auth';
import { PaginationModel, Response } from '@campus/commons';
import FormModel from 'models/interfaces/FormModel';
import PaginatedFilter from 'models/interfaces/PaginatedFilter';

export abstract class AdminRepository<
  Filter = PaginatedFilter,
  Input = unknown,
  Model = FormModel<Input>
> {
  abstract path: string;
  isSearch: boolean = false;

  get api() {
    return Api.INSTANCE.getApi();
  }

  handleError<T>(e: unknown) {
    return Response.error<T>(e);
  }

  abstract getAll(filter: Filter): Promise<Response<PaginationModel<Model>>>;

  async get(id: string): Promise<Response<Model>> {
    throw Error('Not implemented');
  }

  async create(input: Input): Promise<Response<boolean>> {
    throw Error('Not implemented');
  }

  async update(input: Input, id: string): Promise<Response<boolean>> {
    throw Error('Not implemented');
  }

  async delete(id: string): Promise<Response<boolean>> {
    throw Error('Not implemented');
  }

  async searchByKey(data: any): Promise<Response<any>> {
    return null;
  }
}
