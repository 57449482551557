import React from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { Delete } from '@styled-icons/material/Delete';
import { Col, Button, FormInput } from '@campus/components';
import { StyledCol } from '../styles';

const inputName = 'ratings';
const defaultValue = { minimumValue: '', rating: '' };

type RatingInputProps = {};

const RatingInput: React.FC<RatingInputProps> = () => {
  const { control } = useFormContext();

  const inputFieldName = inputName;
  const { fields, append, remove } = useFieldArray({
    control,
    name: inputFieldName
  });

  return (
    <>
      <Col xs={12}>
        <Button variant="link" onClick={() => append(defaultValue)}>
          Adicionar avaliação
        </Button>
      </Col>

      {fields.map((f, i) => {
        const fieldName = `${inputFieldName}.${i}`;

        return (
          <React.Fragment key={f.id}>
            <FormInput
              name={`${fieldName}.rating`}
              label="Nome"
              placeholder="Insira a nome"
              required
              grid={{ xs: 6 }}
            />
            <FormInput
              name={`${fieldName}.minimumValue`}
              label="Valor minimo"
              placeholder="Insira o valor minimo"
              type="number"
              required
              grid={{ xs: 5 }}
            />
            <StyledCol xs={1}>
              <Button variant="light" onClick={() => remove(i)}>
                <Delete color="gray" size={24} />
              </Button>
            </StyledCol>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default RatingInput;
