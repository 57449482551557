import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Collapser, Col, Button } from '@campus/components';
import EvaluationInputItem, { inputName } from './EvaluationInputItem';
import RatingInput from './RatingInput';

const defaultValue = {
  description: '',
  ratings: []
};

const EvaluationInput: React.FC = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: inputName
  });

  return (
    <>
      <Collapser>
        {fields.map((f, i) => (
          <EvaluationInputItem key={f.id} index={i} remove={() => remove(i)} />
        ))}
      </Collapser>
      <Col xs={12}>
        <Button variant="link" onClick={() => append(defaultValue)}>
          Adicionar tipos de avaliação
        </Button>
      </Col>
      <RatingInput />
    </>
  );
};

export default EvaluationInput;
