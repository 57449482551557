import React from 'react';

import { HookForm, Spinner } from '@campus/components';
import { AdminRepository } from 'repositories/interfaces/AdminRepository';

import { LoadingContainer } from './styled';
import { useAdminForm } from './hooks/useAdminForm';

export type FormProps = {
  data?: any;
};

type AdminFormProps = {
  repository: AdminRepository;
  Form: React.ComponentType<FormProps>;
  [x: string]: any;
};

const AdminForm: React.FC<AdminFormProps> = ({
  repository,
  Form,
  ...props
}) => {
  const { onSubmit, defaultValues, loadingData, loadingSubmit } =
    useAdminForm(repository);

  if (loadingData) {
    return (
      <LoadingContainer>
        <Spinner animation="border" variant="primary" />
        <span>Carregando...</span>
      </LoadingContainer>
    );
  }

  return (
    <HookForm
      loading={loadingSubmit}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      submitLabel="Enviar"
    >
      <Form {...props} data={defaultValues} />
    </HookForm>
  );
};

export default AdminForm;
