import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

export interface ClassificationTypeFilter extends PaginatedFilter {
  name?: string;
}

export type ClassificationTypeInput = {
  id?: string;
  name: string;
  description?: string;
};

export type ClassificationTypeDTO = {
  Id: string;
  Name: string;
  Description: string;
};

export class ClassificationType implements FormModel<ClassificationTypeInput> {
  id?: string;
  name?: string;
  description?: string;

  static fromJson(data: any): ClassificationType {
    const classificationType = new ClassificationType();

    classificationType.id = data?.Id;
    classificationType.name = data?.Name;
    classificationType.description = data?.Description;

    return classificationType;
  }

  static fromInputToDTO = (
    input: ClassificationTypeInput
  ): ClassificationTypeDTO => ({
    Id: input.id,
    Description: input.description,
    Name: input.name
  });

  toInput = (): ClassificationTypeInput => ({
    id: this.id,
    name: this.name,
    description: this.description
  });
}
