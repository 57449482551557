import { PaginationModel, Response, User } from '@campus/commons';
import { Student, StudentFilter, StudentInput } from 'models/Student';
import { AdminRepository } from 'repositories/interfaces/AdminRepository';

class StudentRepository extends AdminRepository<
  StudentFilter,
  StudentInput,
  Student
> {
  path: string = '/student';
  isSearch: boolean = true;

  async get(id: string): Promise<Response<Student>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);

      const data = Student.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async getAll(
    filter: StudentFilter
  ): Promise<Response<PaginationModel<Student>>> {
    try {
      const { name, classroom, isBirthdayMonth, teacher, page, pageSize } =
        filter;
      const skipCount = page * pageSize;
      const response = await super.api.get(this.path, {
        params: {
          Name: name,
          ClassId: classroom?.value,
          TeacherId: teacher?.value,
          IsBirthdayMonth: isBirthdayMonth,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(Student, response.data, skipCount);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: StudentInput): Promise<Response<boolean>> {
    try {
      const response = await super.api.post(
        this.path,
        Student.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(input: StudentInput, id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.put(
        `${this.path}/${id}`,
        Student.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async searchByKey(user: User): Promise<Response<User>> {
    try {
      const response = await super.api.get(`${this.path}/user`, {
        params: { email: user.email }
      });

      const data = User.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}

export default StudentRepository;
