import { Api } from '@campus/auth';
import { Response } from '@campus/commons';

import { DisciplineMatrixStage } from 'models/DisciplineStage'

class DisciplineMatrixStageRepository {
  path: string = '/discipline';

  async getByDisciplineMatrixID(id: string): Promise<Response<DisciplineMatrixStage>> {
    try {
      const response = await Api.INSTANCE.getApi().get(
        `${this.path}/disciplineMatrix/${id}/stage`
      )

      const data = DisciplineMatrixStage.fromJson(response.data)
      return Response.fromData(data, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }

  async updateBatch(input: any): Promise<Response<boolean>> {
    try {
      const response = await Api.INSTANCE.getApi().put(
        `${this.path}/disciplineMatrix/stage`,
        input
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }
}

export default DisciplineMatrixStageRepository;