import React from 'react';

import { useAuthority } from '@campus/auth';
import { SideMenu } from '@campus/components';

import {
  StudyPeriod,
  useMenuHeaderSelect,
  MenuHeader as BaseMenuHeader
} from '@campus/commons';

import { useAdminMainContext } from '../../../providers/AdminMainContextProvider';

const MenuHeader: React.FC = () => {
  const { unityName } = useAuthority();

  const { handleSelectOptionChanged } = useMenuHeaderSelect();

  const { period, periods, setPeriod } = useAdminMainContext();

  function onPeriodChange(newPeriod: StudyPeriod) {
    setPeriod(newPeriod);
    handleSelectOptionChanged();
  }

  return (
    <SideMenu.Header
      title={unityName}
      subtitle={`Período: ${period?.name ?? 'Não definido'}`}
    >
      <BaseMenuHeader.StudyPeriodSelect
        allPeriods={periods}
        selectedPeriod={period}
        onChange={onPeriodChange}
      />
    </SideMenu.Header>
  );
};

export default MenuHeader;
