import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormInput } from '@campus/components';

import { SearchFormProps } from 'containers/AdminSearchFormPage';

const TeacherForm: React.FC<SearchFormProps> = ({ searchData }) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (searchData) {
      setValue('name', searchData?.name);
      setValue('email', searchData?.email);
      setValue('imageUrl', searchData?.imageUrl);
    }
  }, [setValue, searchData]);

  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome"
        required
      />

      <FormInput
        name="email"
        label="Email"
        placeholder="Insira o email"
        required
      />

      <FormInput
        name="birthday"
        type="date"
        label="Data de Nascimento"
        placeholder="Insira a data de nascimento"
      />

      <FormInput
        name="imageUrl"
        label="URL Imagem"
        placeholder="Insira a URL da imagem"
      />
    </>
  );
};

export default TeacherForm;
