import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CampusIcon,
  Checkbox,
  Input,
  Pagination,
  toast
} from '@campus/components';

import { PermissionFeatureManager } from 'models/PermissionFeatureManager';
import {
  getPermissionFeatureManager,
  setPermissionFeature,
  updatePermissionFeature
} from 'repositories/LiteraryWorkPermissionRepository';

import PermissionType from 'utils/PermissionType';

import { usePagination } from '../hooks';

import {
  AvatarAndName,
  DivPage,
  TableStyled,
  Avatar,
  PaginationContainer,
  ModalStyled,
  DivActions,
  DivSearch
} from './styles';

type Props = {
  show: boolean;
  setShowModal: (value: boolean) => void;
};

const ModalPermissionManager: React.FC<Props> = ({ show, setShowModal }) => {
  const [search, setSearch] = useState('');
  const [managerData, setManagerData] = useState<PermissionFeatureManager[]>(
    []
  );

  const {
    page,
    totalPages,
    loading,
    pageSize,
    goToFirstPage,
    goToNextPage,
    goToLastPage,
    goToPrevPage,
    setPage,
    setLoading,
    setTotalPages
  } = usePagination();

  const loadDataManager = useCallback(
    async (name?: string) => {
      setLoading(true);
      const response = await getPermissionFeatureManager(page, pageSize, name);
      setManagerData(response?.data?.items ?? []);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
      setLoading(false);
    },
    [page, pageSize, setLoading, setTotalPages]
  );

  useEffect(() => {
    if (show) {
      loadDataManager();
    }
  }, [loadDataManager, show]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      loadDataManager();
    } else {
      loadDataManager(search);
    }
    setPage(1);
  };

  const handleChangeManagerPermission = useCallback(
    async (user: PermissionFeatureManager) => {
      if (user.permission?.id) {
        const resultUpdate = await updatePermissionFeature(user.permission);

        const updatedItem = resultUpdate.data;

        setManagerData((currentValue) => {
          return currentValue.map((item) => {
            return item.manager.user.id === updatedItem.userId
              ? { ...item, permission: updatedItem }
              : item;
          });
        });
      } else {
        await setPermissionFeature(
          PermissionType.VESTIBULAR_MANAGEMENT,
          !user.permission.enabled,
          user.manager.user.id,
          null
        );

        const result = await setPermissionFeature(
          PermissionType.VESTIBULAR_ACCESS,
          !user.permission.enabled,
          user.manager.user.id,
          null
        );

        const createdItem = result.data;

        setManagerData((currentValue) => {
          return currentValue.map((item) => {
            return item.manager.user.id === createdItem.userId
              ? { ...item, permission: createdItem }
              : item;
          });
        });
      }

      toast.success('Permissão alterada com sucesso');
    },
    []
  );
  return (
    <>
      <ModalStyled show={show} onHide={() => setShowModal(false)} size="lg">
        <ModalStyled.Header closeButton>
          <ModalStyled.Title>Gerenciar permissoes de Gestor</ModalStyled.Title>
        </ModalStyled.Header>
        <ModalStyled.Body>
          {!loading && (
            <>
              <DivSearch>
                <Input
                  placeholder="Pesquisar Gestor"
                  onBlur={handleSearch}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  name="search"
                  StartIcon={<CampusIcon name="search" />}
                />
              </DivSearch>
              <TableStyled>
                <thead>
                  <tr>
                    <th>Gestor</th>
                    <th>Acesso</th>
                  </tr>
                </thead>
                <tbody>
                  {managerData.map((value) => (
                    <tr key={value.manager.user.id}>
                      <td>
                        <div>
                          <AvatarAndName>
                            <Avatar img={value.manager.user.imageUrl} />
                            <span>{value.manager.user.name}</span>
                          </AvatarAndName>
                        </div>
                      </td>
                      <td>
                        <Checkbox
                          checked={value.permission.enabled}
                          name={`permission-${value.manager.user.id}`}
                          onChange={() => {
                            handleChangeManagerPermission(value);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableStyled>
              <PaginationContainer>
                <DivPage>
                  <span>
                    Página{' '}
                    <strong>
                      {page} de {totalPages}
                    </strong>
                  </span>
                </DivPage>
                <Pagination>
                  <Pagination.First
                    onClick={goToFirstPage}
                    disabled={page === 1}
                  />
                  <Pagination.Prev
                    onClick={goToPrevPage}
                    disabled={page === 1}
                  />
                  <Pagination.Next
                    onClick={goToNextPage}
                    disabled={page === totalPages}
                  />
                  <Pagination.Last
                    onClick={goToLastPage}
                    disabled={page === totalPages}
                  />
                </Pagination>
              </PaginationContainer>
            </>
          )}
        </ModalStyled.Body>
        <ModalStyled.Footer>
          <DivActions>
            <Button onClick={() => setShowModal(false)} variant="secondary">
              Fechar
            </Button>
          </DivActions>
        </ModalStyled.Footer>
      </ModalStyled>
    </>
  );
};

export default ModalPermissionManager;
