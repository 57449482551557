import { Response, PaginationModel } from '@campus/commons';
import { Segment, SegmentFilter, SegmentInput } from 'models/Segment';
import { AdminRepository } from './interfaces/AdminRepository';

class SegmentRepository extends AdminRepository<
  SegmentFilter,
  SegmentInput,
  Segment
> {
  path: string = '/segment';

  async getAll(
    filter: SegmentFilter
  ): Promise<Response<PaginationModel<Segment>>> {
    try {
      const { name, page, pageSize } = filter;
      const skipCount = page * pageSize;
      const response = await super.api.get('/segment', {
        params: {
          Name: name,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(Segment, response.data, skipCount);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<Segment>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);

      const data = Segment.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: SegmentInput): Promise<Response<boolean>> {
    try {
      const response = await super.api.post(
        this.path,
        Segment.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(input: SegmentInput, id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.put(
        `${this.path}/${id}`,
        Segment.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}

export default SegmentRepository;
