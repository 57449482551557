import { Response, PaginationModel } from '@campus/commons';

import {
  StudyPeriod,
  StudyPeriodInput,
  StudyPeriodFilter
} from 'models/StudyPeriod';
import { AdminRepository } from './interfaces/AdminRepository';
import { toast } from '@campus/components';

class StudyPeriodRepository extends AdminRepository<
  StudyPeriod,
  StudyPeriodInput,
  StudyPeriodFilter
> {
  path: string = '/studyPeriod';

  async getAll(
    filter: StudyPeriodFilter
  ): Promise<Response<PaginationModel<StudyPeriod>>> {
    try {
      const { name, page, pageSize } = filter;
      const skipCount = page * pageSize;
      const response = await super.api.get(this.path, {
        params: {
          Name: name,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(
        StudyPeriod,
        response.data,
        skipCount
      );

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<StudyPeriod>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);
      const data = StudyPeriod.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: StudyPeriod): Promise<Response<boolean>> {
    try {
      if (input.startDate >= input.endDate) {
        toast.error(
          'Data de início não pode ser igual ou maior a data do fim'
        );
        return;
      }

      if (input.hideScoreStartDate >= input.hideScoreEndDate) {
        toast.error(
          'Data de início (esconder notas) não pode ser igual ou maior a Data do fim (esconder notas)'
        );
        return;
      }

      const response = await super.api.post(
        this.path,
        StudyPeriod.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(input: StudyPeriod, id: string): Promise<Response<boolean>> {
    try {
      if (input.startDate >= input.endDate) {
        toast.error(
          'Data de inicio não pode ser maior ou igual que a data do fim'
        );
        return;
      }

      if (input.hideScoreStartDate >= input.hideScoreEndDate) {
        toast.error(
          'Data de inicio (esconder notas) não pode ser maior ou igual que a Data do fim (esconder notas)'
        );
        return;
      }

      const response = await super.api.put(
        `${this.path}/${id}`,
        StudyPeriod.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}

export default StudyPeriodRepository;
