import TeacherRepository from 'repositories/TeacherRepository';

export const getTeachers = async (name: string) => {
  const teacherRepository = new TeacherRepository();

  const response = await teacherRepository.getAll({
    name,
    page: 0,
    pageSize: 10
  });

  const data = response.data;

  if (data) {
    return data?.items?.map((i) => ({ value: i.id, label: i.user.name })) ?? [];
  }

  return [];
};
