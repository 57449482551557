import { Api } from '@campus/auth';
import { Response, PaginationModel } from '@campus/commons';

import PermissionType from 'utils/PermissionType';

import { PermissionFeatureGrade } from '../models/PermissionFeatureGrade';

import { PermissionFeatureManager } from '../models/PermissionFeatureManager';
import { Permission } from '../models/Permission';

const path: string = '/feature';
const permissionFeature: string = 'permissionfeature';

export async function getPermissionFeatureGrade(
  page: number,
  pageSize: number,
  gradeName?: string
): Promise<Response<PaginationModel<PermissionFeatureGrade>>> {
  try {
    const skipCount = (page - 1) * pageSize;

    const response = await Api.INSTANCE.getApi().get(
      `${path}/${PermissionType.VESTIBULAR_ACCESS}/${permissionFeature}/grade`,
      {
        params: {
          code: PermissionType.VESTIBULAR_ACCESS,
          SkipCount: skipCount,
          MaxResultCount: pageSize,
          GradeName: gradeName ?? null
        }
      }
    );

    const data = PaginationModel.fromJson(
      PermissionFeatureGrade,
      response.data,
      skipCount
    );

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
}

export async function getPermissionFeatureManager(
  page: number,
  pageSize: number,
  name?: string
): Promise<Response<PaginationModel<PermissionFeatureManager>>> {
  try {
    const skipCount = (page - 1) * pageSize;

    const response = await Api.INSTANCE.getApi().get(
      `${path}/${PermissionType.VESTIBULAR_MANAGEMENT}/${permissionFeature}/manager`,
      {
        params: {
          code: PermissionType.VESTIBULAR_MANAGEMENT,
          SkipCount: skipCount,
          MaxResultCount: pageSize,
          Name: name ?? null
        }
      }
    );

    const data = PaginationModel.fromJson(
      PermissionFeatureManager,
      response.data,
      skipCount
    );

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
}

export async function setPermissionFeature(
  code: string,
  enable: boolean,
  userId?: string,
  gradeId?: string
): Promise<Response<Permission>> {
  try {
    const response = await Api.INSTANCE.getApi().post(
      `${path}/${code}/${permissionFeature}`,
      {
        UserId: userId ?? null,
        GradeId: gradeId ?? null,
        Enabled: enable
      }
    );

    const data = Permission.fromJson(response.data);

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
}

export async function updatePermissionFeature(
  permissionData: Permission
): Promise<Response<Permission>> {
  try {
    const response = await Api.INSTANCE.getApi().patch(
      `${permissionFeature}/${permissionData.id}`,
      {
        Enabled: !permissionData.enabled,
        UserId: permissionData.userId ?? null,
        GradeId: permissionData.gradeId ?? null
      }
    );

    const data = Permission.fromJson(response.data);

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
}
