import PeriodRepository from 'repositories/PeriodRepository';

export const getPeriods = async (name: string) => {
  const periodRepository = new PeriodRepository();

  const periods = await periodRepository.getAll({
    name: name,
    page: 0,
    pageSize: 100
  });

  return periods.data.items.map((i) => ({
    value: i.id,
    label: i.name
  }));
};
