import { Api } from '@campus/auth';

type Student = {
  Items: Array<{ Id: string; Name: string }>;
};

export const getStudents = async (name: string) => {
  try {
    const response = await Api.INSTANCE.getApi().get<Student>('/student', {
      params: { Name: name }
    });

    return response.data.Items.map((i) => ({
      value: i.Id,
      label: i.Name
    }));
  } catch (error) {
    return [];
  }
};
