import { PaginationModel, Response } from '@campus/commons';
import { toast } from '@campus/components';
import { useRef, useCallback, useState, useEffect } from 'react';

const _initialPage = 0;
const _initialPageSize = 10;
const _initialPageCount = -1;

export type IUseAdminRepository = {
  onFetch: (filter: any) => Promise<Response<PaginationModel<any>>>;
  onDelete?: (data: any) => Promise<Response<boolean>>;
};

const useAdminRepository = ({ onDelete, onFetch }: IUseAdminRepository) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(_initialPage);
  const [pageSize, setPageSize] = useState(_initialPageSize);
  const [pageCount, setPageCount] = useState(_initialPageCount);
  const [filterData, setFilterData] = useState(null);

  const fetchIdRef = useRef(0);

  const fetch = useRef(onFetch).current;

  const onFetchData = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      setPage(page);
      setPageSize(pageSize);

      const fetchId = ++fetchIdRef.current;

      if (fetchId === fetchIdRef.current) {
        const res = await fetch({ ...filterData, page, pageSize });

        if (res.data) {
          setError(null);
          setItems(res.data.items);
          setPageCount(Math.ceil(res.data.totalCount / pageSize));
        } else if (res.error) {
          setError(res.error);
          setPageCount(_initialPageCount);
        }

        setLoading(false);
      }
    },
    [fetch, filterData]
  );

  useEffect(() => {
    if (filterData) onFetchData(_initialPage, _initialPageSize);
  }, [onFetchData, filterData]);

  const handleDelete = async (data: any) => {
    setLoading(true);
    const res = await onDelete(data);
    if (res.data) {
      await onFetchData(page, pageSize);
    } else if (res.error) {
      toast.error(res.error.message);
      setLoading(false);
    }
  };

  const handleFilter = useCallback((filter: any) => {
    setFilterData(filter);
  }, []);

  return {
    items,
    filterData,
    error,
    loading,
    pageCount,
    onFetch: onFetchData,
    onDelete: handleDelete,
    onFilter: handleFilter
  };
};

export default useAdminRepository;
