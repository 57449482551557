import React from 'react';
import { Button, Dropdown } from '@campus/components';

import { ContainerFilter, DropdownStyled } from './styles';
import useAdminActions from 'containers/AdminActionsContainer/useAdminActions';

const AdminActions: React.FC = () => {
  const { onCreate, onEdit, selected, actions } = useAdminActions();

  return (
    <ContainerFilter>
      {selected.length === 0 ?
        (<Button onClick={onCreate}>Novo</Button>) :
        (
          <DropdownStyled title="Ações" id="bg-nested-dropdown">
            {actions.map(item => (
              <Dropdown.Item onClick={() => onEdit(item.path, selected)}>
                {item.label}
              </Dropdown.Item>
            ))}
          </DropdownStyled>
        )
      }
    </ContainerFilter>
  );
};

export default AdminActions;
