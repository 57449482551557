import { Response, PaginationModel } from '@campus/commons';
import { Period, PeriodFilter, PeriodInput } from 'models/Period';
import { AdminRepository } from './interfaces/AdminRepository';

class PeriodRepository extends AdminRepository<
  PeriodFilter,
  PeriodInput,
  Period
> {
  path: string = '/period';

  async getAll(
    filter: PeriodFilter
  ): Promise<Response<PaginationModel<Period>>> {
    try {
      const { name, page, pageSize } = filter;
      const skipCount = page * pageSize;
      const response = await super.api.get(this.path, {
        params: {
          Name: name,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(Period, response.data, skipCount);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<Period>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);

      const data = Period.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: PeriodInput): Promise<Response<boolean>> {
    try {
      const { name } = input;

      const response = await super.api.post(this.path, {
        Name: name
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(input: PeriodInput, id: string): Promise<Response<boolean>> {
    try {
      const { name } = input;

      const response = await super.api.put(`${this.path}/${id}`, {
        Name: name
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}

export default PeriodRepository;
