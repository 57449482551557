import { SelectOption } from '@campus/components';

import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

export interface DisciplineFilter extends PaginatedFilter {
  name?: string;
  teacher?: SelectOption;
  classroom?: SelectOption;
}

export type DisciplineInput = {
  name: string;
};

export class Discipline implements FormModel<DisciplineInput> {
  id?: string;
  name?: string;

  static fromJson(data: any): Discipline {
    const discipline = new Discipline();

    discipline.id = data?.Id;
    discipline.name = data?.Name;

    return discipline;
  }

  toInput = (): DisciplineInput => ({
    name: this.name
  });
}
