import React, { useState } from 'react';
import {
  getEvaluationMatrices,
  getDisciplines,
  getGrades,
  getPeriods,
  getDisciplineMatrixEdit,
  updateDisciplineMatrix
} from 'services/services';

import { FormCheckbox, FormSelectorAsync } from '@campus/components';
import AdminActionsForm from 'components/AdminActionsForm/AdminActionsForm';
import { SelectOption } from '@campus/components/src/types';
import { DisciplineMatrixEdit } from 'models/DisciplineMatrixEdit';

export const inputName = 'EditEvaluationMatrix';

const EvaluationPercentagesEdit: React.FC = () => {
  const [discipline, setDiscipline] = useState<SelectOption>();
  const [period, setPeriod] = useState<SelectOption>();
  const [grade, setGrade] = useState<SelectOption>();
  const [evaluationMatrix, setEvaluationMatrix] = useState<SelectOption>();

  const [showPerformance, setShowPerformance] = useState(false);

  const EditEvaluationTable: React.FC = () => (
    <>
      <FormSelectorAsync
        name={`${inputName}.discipline`}
        label="Componente Curricular"
        placeholder="Selecione um componente curricular"
        required
        getOptions={(v: string) => getDisciplines(v)}
        defaultValue={discipline}
      />
      <FormSelectorAsync
        name={`${inputName}.period`}
        label="Período Letivo"
        placeholder="Selecione um período letivo"
        required
        getOptions={(v: string) => getPeriods(v)}
        defaultValue={period}
      />
      <FormSelectorAsync
        name={`${inputName}.grade`}
        label="Série"
        placeholder="Selecione uma série"
        required
        getOptions={(v: string) => getGrades(v)}
        defaultValue={grade}
      />
      <FormSelectorAsync
        name={`${inputName}.evaluationMatrix`}
        label="Matriz de avaliação"
        placeholder="Selecione uma matriz"
        required
        getOptions={(v: string) => getEvaluationMatrices(v)}
        defaultValue={evaluationMatrix}
      />
      <FormCheckbox
        name={`${inputName}.showPerformance`}
        label="Exibir performance"
        defaultValue={showPerformance}
      />
    </>
  );

  const onLoadFormData = async (id) => {
    const getDataEdit = await getDisciplineMatrixEdit(id);

    setDiscipline({
      value: getDataEdit.data.discipline.id,
      label: getDataEdit.data.discipline.name
    });

    setPeriod({
      value: getDataEdit.data.period.id,
      label: getDataEdit.data.period.name
    });

    setGrade({
      value: getDataEdit.data.grade.id,
      label: getDataEdit.data.grade.name
    });

    setEvaluationMatrix({
      value: getDataEdit.data.evaluationMatrix.id,
      label: getDataEdit.data.evaluationMatrix.name
    });

    setShowPerformance(getDataEdit.data.showPerformance);
  };

  const onSubmit = async (id: any, data: any) => {
    const disciplineMatrixEditDTO = DisciplineMatrixEdit.fromInputToDTO(
      data.EditEvaluationMatrix
    );
    disciplineMatrixEditDTO.Id = id[0];
    const response = await updateDisciplineMatrix(disciplineMatrixEditDTO);
    return response;
  };

  return (
    <AdminActionsForm
      Form={EditEvaluationTable}
      onLoadFormData={onLoadFormData}
      onSubmit={onSubmit}
    />
  );
};

export default EvaluationPercentagesEdit;
