import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormInput, Select, DataGridProps } from '@campus/components';

import AdminContainer from 'containers/AdminContainer';
import EvaluationMatrixRepository from 'repositories/EvaluationMatrixRepository';
import EvaluationInput from './components/EvaluationInput';

const evaluationModelOptions = [
  { value: 'Percentage', label: 'Modelo do tipo percentual' },
  { value: 'Score', label: 'Modelo do tipo pontuação' },
  { value: 'Conceptual', label: 'Modelo do tipo conceitual' },
];

const EvaluationMatrixForm: React.FC = () => {
  const { setValue, control } = useFormContext();
  const evaluationMatrix = useWatch({ control });

  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome"
        required
        grid={{ xs: 6 }}
      />
      <FormInput
        name="description"
        label="Descrição"
        placeholder="Insira a descrição"
        required
        grid={{ xs: 6 }}
      />
      <Select
        name="evaluationModelType"
        label="Tipo de modelo de avaliação"
        placeholder="Insira o tipo de modelo de avaliação"
        required
        value={evaluationModelOptions.filter(item => item.value === evaluationMatrix?.evaluationModelType)}
        options={evaluationModelOptions}
        onChange={(data) => setValue('evaluationModelType', data.value)}
      />
      <EvaluationInput />
    </>
  );
};

const EvaluationMatrixFilter: React.FC = () => {
  return <FormInput name="name" label="Nome" placeholder="Insira o nome" />;
};

const EvaluationMatrixPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [{ Header: 'Nome', accessor: 'name' }],
    []
  );

  return (
    <AdminContainer
      title="Cadastro das matrizes de avaliação"
      columns={columns}
      repository={new EvaluationMatrixRepository()}
      components={{
        Form: EvaluationMatrixForm,
        Filter: EvaluationMatrixFilter,
      }}
    />
  );
};

export default EvaluationMatrixPage;
