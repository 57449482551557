import React from 'react';

import {
  FormInput,
  FormSelectorAsync,
  DataGridProps
} from '@campus/components';
import AdminContainer from 'containers/AdminContainer';

import ClassroomRepository from 'repositories/ClassroomRepository';
import {
  getGrades,
  getTeachers,
  getPeriods,
  getStudents,
  getDisciplines
} from 'services/services';
import ClassDisciplineInput from './components/ClassDisciplineInput';

export const classRepository = new ClassroomRepository();

const ClassroomForm: React.FC = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome"
        required
      />

      <FormSelectorAsync
        name="grade"
        label="Série"
        placeholder="Selecione uma série"
        getOptions={(inputValue: string) => getGrades(inputValue)}
      />

      <FormSelectorAsync
        name="period"
        label="Turno"
        placeholder="Selecione um turno"
        required
        getOptions={(inputValue: string) => getPeriods(inputValue)}
      />

      <ClassDisciplineInput />
    </>
  );
};

const ClassroomFilter: React.FC = () => {
  return (
    <>
      <FormInput name="name" label="Nome" placeholder="Insira o nome" />

      <FormSelectorAsync
        name="student"
        label="Estudante"
        placeholder="Selecione um estudante"
        getOptions={(inputValue: string) => getStudents(inputValue)}
      />

      <FormSelectorAsync
        name="teacher"
        label="Professor"
        placeholder="Selecione um professor"
        getOptions={(inputValue: string) => getTeachers(inputValue)}
      />

      <FormSelectorAsync
        name="grade"
        label="Série"
        placeholder="Selecione uma série"
        getOptions={(inputValue: string) => getGrades(inputValue)}
      />

      <FormSelectorAsync
        name="discipline"
        label="Buscar por componente curricular"
        placeholder="Selecione um componente curricular"
        getOptions={(inputValue: string) => getDisciplines(inputValue)}
      />
    </>
  );
};

const ClassroomPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [{ Header: 'Nome', accessor: 'name' }],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de turmas"
      repository={classRepository}
      columns={columns}
      components={{
        Filter: ClassroomFilter,
        Form: ClassroomForm
      }}
    />
  );
};

export default ClassroomPage;
