import React, { useMemo, useContext, useEffect } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';

import {
  Row,
  FormSelectorAsync,
  CollapserItem,
  CollapserContext,
  FormInput
} from '@campus/components';

import { getClassroom } from 'services/services';
import { CloseAccordion } from '../../../styles';
import DisciplineInput from 'components/DisciplineInput';

type DisciplineItemProps = {
  index: number;
  onRemove: (key) => void;
};

export const inputName = 'classDiscipline';

const DisciplineItem: React.FC<DisciplineItemProps> = ({ index, onRemove }) => {
  const fieldName = `${inputName}.${index}`;

  const { control } = useFormContext();
  const { isSubmitting, errors } = useFormState({ control });

  const classDiscipline = useWatch({ control, name: fieldName });
  const classroom = useWatch({ control, name: `${fieldName}.classroom` });

  const { forceOpened } = useContext(CollapserContext);

  useEffect(() => {
    if (isSubmitting) {
      const classDiscipline = errors?.classDiscipline;

      if (classDiscipline instanceof Array) {
        const i = classDiscipline.findIndex((e) => e);
        forceOpened(i);
      }
    }
  }, [isSubmitting, errors, forceOpened]);

  const title = useMemo(() => {
    const classroom = classDiscipline?.classroom;
    const discipline = classDiscipline?.disciplineMatrix;

    return `${classroom?.label ?? '-'} / ${discipline?.label ?? '-'}`;
  }, [classDiscipline]);

  const handleNumberFormat = (value: string) => {
    const number = parseFloat(value);
    return !isNaN(number) ? number.toFixed(2) : null;
  };

  return (
    <CollapserItem
      eventKey={index}
      header={
        <>
          <CloseAccordion onClick={onRemove} />
          {title}
        </>
      }
    >
      <Row className="gy-2 gx-2">
        <FormSelectorAsync
          name={`${fieldName}.classroom`}
          label="Classe"
          getOptions={(inputValue: string) => getClassroom(inputValue)}
          grid={{ lg: 6 }}
          required
          disabled
        />

        <DisciplineInput
          name={`${fieldName}.disciplineMatrix`}
          classroomId={classroom?.value}
        />

        <FormInput
          name={`${fieldName}.startDate`}
          type="date"
          label="Data de Inicio"
          grid={{ lg: 6 }}
        />

        <FormInput
          name={`${fieldName}.endDate`}
          type="date"
          label="Data de Termino"
          grid={{ lg: 6 }}
        />

        <FormInput
          name={`${fieldName}.score`}
          type="number"
          label="Nota"
          grid={{ lg: 6 }}
          format={handleNumberFormat}
        />

        <FormInput
          name={`${fieldName}.weight`}
          type="number"
          label="Peso"
          grid={{ lg: 6 }}
          format={handleNumberFormat}
        />
      </Row>
    </CollapserItem>
  );
};

export default DisciplineItem;
