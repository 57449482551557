import { SelectOption } from '@campus/components';
import { Discipline } from './Discipline';
import { Grade } from './Grade';

import FormModel from './interfaces/FormModel';

export type ClassificationInput = {
  id?: string;
  parentId?: string;
  code?: string;
  description?: string;
  discipline?: SelectOption;
  grade?: SelectOption;
};

export type ClassificationDTO = {
  Id?: string;
  ParentId?: string;
  Code?: string;
  Description?: string;
  DisciplineId?: string;
  GradeId?: string;
};

export class Classification implements FormModel<ClassificationInput> {
  id?: string;
  code?: string;
  description?: string;
  discipline?: Discipline;
  grade?: Grade;

  parentId?: string;

  static fromJson(data: any, parentId: string): Classification {
    const classification = new Classification();

    classification.id = data?.Id;
    classification.parentId = parentId;

    classification.description = data?.Description;
    classification.code = data?.Code;

    if (data?.Discipline) {
      classification.discipline = Discipline.fromJson(data?.Discipline);
    }

    if (data?.Grade) {
      classification.grade = Grade.fromJson(data?.Grade);
    }

    return classification;
  }

  static fromInputToDTO = (input: ClassificationInput): ClassificationDTO => ({
    ParentId: input?.parentId,
    Code: input?.code,
    Description: input?.description,
    GradeId: input?.grade?.value?.toString(),
    DisciplineId: input?.grade?.value?.toString()
  });

  toInput = (): ClassificationInput => ({
    id: this.id,
    parentId: this.parentId,
    code: this.code ?? '',
    description: this.description ?? '',
    grade: this.grade?.id
      ? { value: this.grade?.id, label: this.grade?.name }
      : null,
    discipline: this.discipline?.id
      ? { value: this.discipline?.id, label: this.discipline?.name }
      : null
  });
}
