import { useCallback, useMemo, useRef, useState } from 'react';

import {
  DisciplineMatrix,
  DisciplineMatrixInput
} from 'models/DisciplineMatrix';

import { getDisciplineMatrix } from 'services/DisciplineMatrixService';

const deepEqual = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b);

type DisciplineMatrixProps = {
  onSelect: (input: DisciplineMatrixInput) => void;
};

export const useDisciplineMatrix = ({ onSelect }: DisciplineMatrixProps) => {
  const [loading, setLoading] = useState(false);
  const [matrices, setMatrices] = useState<DisciplineMatrix[]>([]);

  const [selectedMatrix, setSelectedMatrix] = useState<string>();

  const matrixFilter = useRef<DisciplineMatrixInput>();

  const fetchMatrices = async (input: DisciplineMatrixInput) => {
    if (input.discipline) {
      setLoading(true);
      const res = await getDisciplineMatrix(input);
      setLoading(false);

      if (res.data) setMatrices(res.data);
      else setMatrices([]);
    }
  };

  const handleFilter = (input: DisciplineMatrixInput) => {
    if (!deepEqual(matrixFilter.current, input)) {
      matrixFilter.current = input;
      fetchMatrices(input);
    }
  };

  const matrix = useMemo(
    () => matrices.find((m) => m.id === selectedMatrix),
    [matrices, selectedMatrix]
  );

  const handleSubmit = useCallback(
    (d: DisciplineMatrix) => onSelect(d.toInput()),
    [onSelect]
  );

  return {
    matrices,
    loading,
    handleFilter,
    matrix,
    onSubmit: handleSubmit,
    onSelectMatrix: setSelectedMatrix
  };
};
