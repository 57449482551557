import { SelectOption } from '@campus/components';
import { EducationLevel } from './EducationLevel';
import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

export interface SegmentFilter extends PaginatedFilter {
  name?: string;
}

export type SegmentInput = {
  name: string;
  suffix?: string;
  educationLevel: SelectOption;
};

export type SegmentDTO = {
  Name: string;
  Suffix: string;
  EducationLevelId?: string;
};

export class Segment implements FormModel<SegmentInput> {
  id?: string;
  name?: string;
  suffix?: string;
  educationLevel?: EducationLevel;

  static fromJson(data: any): Segment {
    const segment = new Segment();

    segment.id = data?.Id;
    segment.name = data?.Name;
    segment.suffix = data?.Suffix;

    if (data?.EducationLevel) {
      segment.educationLevel = EducationLevel.fromJson(data?.EducationLevel);
    }

    return segment;
  }

  static fromInputToDTO = (input: SegmentInput): SegmentDTO => ({
    Name: input?.name,
    Suffix: input?.suffix,
    EducationLevelId: input?.educationLevel.value.toString()
  });

  toInput = (): SegmentInput => ({
    name: this.name,
    suffix: this.suffix,
    educationLevel: {
      value: this.educationLevel?.id,
      label: this.educationLevel?.name
    }
  });
}
