import React from 'react';
import { Col, Row, Collapser } from '@campus/components';
import { useFormContext, useFieldArray } from 'react-hook-form';

import DisciplineItem, { inputName } from './components/DisciplineItem';

import { ContainerButton } from '../../styles';
import DisciplineModal from './components/DisciplineModal';

const StudentDiscipline: React.FC = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: inputName
  });

  return (
    <Col xs={12}>
      <Collapser>
        {fields.map((f, i) => (
          <DisciplineItem key={f.id} index={i} onRemove={() => remove(i)} />
        ))}
      </Collapser>
      <Row className="gy-2 gx-2">
        <ContainerButton>
          <DisciplineModal onAdd={append} />
        </ContainerButton>
      </Row>
    </Col>
  );
};

export default StudentDiscipline;
