import React from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Delete } from '@styled-icons/material/Delete';

import { Col, Button, FormSelectorAsync } from '@campus/components';
import { getTeachers } from 'services/services';

const inputName = 'teachers';
const defaultValue = { teacher: null };

type TeacherInputProps = {
  name: string;
};

const StyledCol = styled(Col)`
  align-self: end;
`;

const TeacherInput: React.FC<TeacherInputProps> = ({ name }) => {
  const { control } = useFormContext();

  const inputFieldName = `${name}.${inputName}`;
  const { fields, append, remove } = useFieldArray({
    control,
    name: inputFieldName
  });

  return (
    <>
      {fields.map((f, i) => {
        const fieldName = `${inputFieldName}.${i}`;

        return (
          <React.Fragment key={f.id}>
            <FormSelectorAsync
              name={fieldName}
              label="Professor"
              placeholder="Selecione um professor"
              getOptions={(inputValue: string) => getTeachers(inputValue)}
              required
              grid={{ xs: 11 }}
            />

            <StyledCol xs={1}>
              <Button variant="light" onClick={() => remove(i)}>
                <Delete color="gray" size={24} />
              </Button>
            </StyledCol>
          </React.Fragment>
        );
      })}
      <Col xs={12}>
        <Button variant="link" onClick={() => append(defaultValue)}>
          Adicionar professor
        </Button>
      </Col>
    </>
  );
};

export default TeacherInput;
