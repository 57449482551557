import { Button, Modal } from '@campus/components';
import styled from 'styled-components';

export const IconButton = styled(Button)`
  padding: 0 0.5rem;
`;

export const CenterElement = styled.div`
  text-align: center;
`;

export const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px;
  margin: 18px 0px 12px 0px;
`;

export const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .btn-outline-secondary {
    color: ${(props) => props.theme.colors.gray4};
    border-color: ${(props) => props.theme.colors.gray4};
    font-weight: bold;
  }
`;

export const Separator = styled.hr`
  height: 1px;
  opacity: 1;
  background-color: ${(props) => props.theme.colors.gray5};
  margin-top: 23px;
  margin-bottom: 19px;
`;
