import styled from 'styled-components';
import {
  CloseButton,
  Col
} from '@campus/components';

export const CloseAccordion = styled(CloseButton)`
  margin-right: 16px;
`;

export const StyledCol = styled(Col)`
  align-self: end;
`;
