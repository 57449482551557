import React, { useState } from 'react';

import { Button, Modal } from '@campus/components';

import {
  DisciplineMatrix,
  DisciplineMatrixInput
} from 'models/DisciplineMatrix';

import MatrixForm from './components/MatrixForm';

import { useDisciplineMatrix } from './hooks/useDisciplineMatrix';
import {
  Box,
  Separator,
  Container,
  ItemContainer,
  BoxItem,
  ClassroomTitle,
  ClassroomItem,
  ButtonContainer
} from './styles';

export type DisciplineMatrixSelectProps = {
  renderButton: (onClick) => React.ReactChild;
  onSelect: (input: DisciplineMatrixInput) => void;
};

const DisciplineMatrixSelect: React.FC<DisciplineMatrixSelectProps> = ({
  onSelect,
  renderButton
}) => {
  const [show, setShow] = useState(false);

  const handleSelect = (input: DisciplineMatrixInput) => {
    setShow(false);
    onSelect(input);
  };

  return (
    <>
      {renderButton(() => setShow(true))}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar componente curricular</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DisciplineMatrixView onSelect={handleSelect} />
        </Modal.Body>
      </Modal>
    </>
  );
};

type DisciplineMatrixViewProps = {
  onSelect: (input: DisciplineMatrixInput) => void;
};

const DisciplineMatrixView: React.FC<DisciplineMatrixViewProps> = ({
  onSelect
}) => {
  const disciplineMatrix = useDisciplineMatrix({ onSelect });

  const { onSubmit, handleFilter, onSelectMatrix, loading, matrices, matrix } =
    disciplineMatrix;

  return (
    <>
      <MatrixForm onCreate={onSubmit} onChange={handleFilter} />
      <Separator />
      <Container>
        <ItemContainer>
          <h5>Componentes Curriculares</h5>
          <Box>
            {loading ? (
              <>Carregando...</>
            ) : matrices.length > 0 ? (
              matrices.map((m) => (
                <BoxItem
                  key={m.id}
                  variant="outline-secondary"
                  onClick={() => onSelectMatrix(m.id)}
                >
                  {m.label}
                </BoxItem>
              ))
            ) : (
              <>Nenhum componente curricular</>
            )}
          </Box>
        </ItemContainer>
        <ClassroomsView matrix={matrix} />
      </Container>
      <Separator />
      <ButtonContainer>
        <Button disabled={!matrix} onClick={() => onSubmit(matrix)}>
          Selecionar
        </Button>
      </ButtonContainer>
    </>
  );
};

type ClassroomsViewProps = {
  matrix: DisciplineMatrix;
};

const ClassroomsView: React.FC<ClassroomsViewProps> = ({ matrix }) => {
  if (!matrix) {
    return null;
  }

  const classrooms = matrix?.classDisciplines;

  return (
    <ItemContainer>
      <ClassroomTitle>
        <h5>Turmas: </h5>
        <p>{matrix.label}</p>
      </ClassroomTitle>
      <Box>
        {classrooms?.map((m) => (
          <ClassroomItem key={m.id}>{m.name}</ClassroomItem>
        ))}
      </Box>
    </ItemContainer>
  );
};

export default DisciplineMatrixSelect;
