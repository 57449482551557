import { Response, PaginationModel } from '@campus/commons';

import {
  EvaluationTypeDescription,
  EvaluationTypeDescriptionFilter,
  EvaluationTypeDescriptionInput
} from 'models/EvaluationTypeDescription';
import { AdminRepository } from './interfaces/AdminRepository';
import { hexToHsl } from '../utils/ColorConversion';

class EvaluationTypeDescriptionRepository extends AdminRepository<
  EvaluationTypeDescriptionFilter,
  EvaluationTypeDescription,
  EvaluationTypeDescriptionInput
> {
  path: string = '/evaluationType';

  async getAll(
    filter: EvaluationTypeDescriptionFilter
  ): Promise<Response<PaginationModel<EvaluationTypeDescription>>> {
    try {
      const { name, page, pageSize } = filter;
      const skipCount = page * pageSize;
      const response = await super.api.get(this.path, {
        params: {
          Name: name,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(
        EvaluationTypeDescription,
        response.data,
        skipCount
      );

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<EvaluationTypeDescription>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);
      const data = EvaluationTypeDescription.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: EvaluationTypeDescription): Promise<Response<boolean>> {
    try {
      input.color = hexToHsl(input.color);

      const response = await super.api.post(
        this.path,
        EvaluationTypeDescription.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(
    input: EvaluationTypeDescription,
    id: string
  ): Promise<Response<boolean>> {
    try {
      input.color = hexToHsl(input.color);

      const response = await super.api.put(
        `${this.path}/${id}`,
        EvaluationTypeDescription.fromInputToDTO(input)
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}

export default EvaluationTypeDescriptionRepository;
