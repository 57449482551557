import React from 'react';
import { Outlet } from 'react-router-dom';
import { DataGrid, DataGridProps } from '@campus/components';

import { AdminComponents } from 'containers/AdminContainer';

import { Card, Header, Title } from './styles';
import TableError from '../TableError';
import AdminTableFilter from './components/AdminTableFilter';
import DisciplineMatrixActions from './components/TableActions';
import TableAppBarActions from './components/TableAppBarActions';
import AdminProvider from 'providers/AdminProvider';
import { IUseAdminRepository } from 'hooks/useAdminRepository';

type AdminTableProps = IUseAdminRepository & {
  title: string;
  columns: DataGridProps['columns'];
  components: AdminComponents;
  disableNew?: boolean;
};

export type AdminContext = {
  onSubmit: () => void;
};

const AdminTable: React.FC<AdminTableProps> = ({
  title,
  columns,
  components,
  onDelete,
  onFetch,
  disableNew
}) => {
  const AppBarActions = components.AppBarActions ?? TableAppBarActions;

  const Filter = components.Filter;

  const ErrorPage = components.ErrorPage ?? TableError;

  const FormActions = components.FormActions ?? DisciplineMatrixActions;

  return (
    <AdminProvider onDelete={onDelete} onFetch={onFetch}>
      {({ loading, error, onFetch, items, pageCount }) => (
        <>
          <Header>
            <Title>{title}</Title>
            {!disableNew && <AppBarActions />}
          </Header>

          {!!Filter && (
            <AdminTableFilter>
              <Filter />
            </AdminTableFilter>
          )}

          <Card>
            {!!error ? (
              <ErrorPage error={error} />
            ) : (
              <DataGrid
                pageCount={pageCount}
                onFetchData={onFetch}
                data={items}
                loading={loading}
                columns={[
                  ...columns,
                  {
                    Header: 'Ações',
                    accessor: 'id',
                    width: 55,
                    maxWidth: 55,
                    Cell: ({ row }) => <FormActions data={row.original} />
                  }
                ]}
              />
            )}
          </Card>
          <Outlet
            context={{ onSubmit: () => onFetch(0, 10) } as AdminContext}
          />
        </>
      )}
    </AdminProvider>
  );
};

export default AdminTable;
