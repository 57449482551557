import { Response, PaginationModel } from '@campus/commons';
import {
  LibraryObjectStudentPermission,
  LibraryObjectStudentPermissionInput,
  LibraryObjectStudentPermissionUpdateInput,
  LibraryObjectStudentPermissionFilter,
  StudentsPermissions,
  StudentPermissionFilter
} from 'models/LibraryObjectStudentPermission';
import { AdminRepository } from './interfaces/AdminRepository';

class DigitalObjectPermissionsRepository extends AdminRepository<
  LibraryObjectStudentPermissionFilter,
  LibraryObjectStudentPermissionInput,
  LibraryObjectStudentPermission
> {
  path: string = '/libraryobjectstudentpermission';

  async getAll(
    filter: LibraryObjectStudentPermissionFilter
  ): Promise<Response<PaginationModel<LibraryObjectStudentPermission>>> {
    try {
      const { name, discipline, classroom, grade, page, pageSize } = filter;

      const skipCount = page * pageSize;
      const response = await super.api.get(this.path, {
        params: {
          Name: name,
          DisciplineId: discipline?.value,
          ClassId: classroom?.value,
          GradeId: grade?.value,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(
        LibraryObjectStudentPermission,
        response.data,
        skipCount
      );

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async getAllStudentsByLibraryObject(
    filter: StudentPermissionFilter
  ): Promise<Response<StudentsPermissions[]>> {
    try {
      const { libraryObjectId, disciplineId, gradeId } = filter;

      const response = await super.api.get(`${this.path}/students`, {
        params: {
          libraryobjectid: libraryObjectId,
          disciplineId: disciplineId,
          gradeId: gradeId
        }
      });

      const data: StudentsPermissions[] =
        response.data?.map((studentPermission) =>
          StudentsPermissions.fromJson(studentPermission)
        ) ?? [];

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<LibraryObjectStudentPermission>> {
    throw Error('Not implemented');
  }

  async create(
    input: LibraryObjectStudentPermissionInput
  ): Promise<Response<boolean>> {
    throw Error('Not implemented');
  }

  async updateMany(
    input: LibraryObjectStudentPermissionUpdateInput,
    libraryObjectId: string
  ): Promise<Response<boolean>> {
    const { studentsIds, permission } = input;

    const response = await super.api.put(
      `${this.path}/${libraryObjectId}/students/permission`,
      {
        studentsIds,
        permission
      }
    );

    return Response.fromData(true, response.status);
  }

  async update(
    input: LibraryObjectStudentPermissionInput,
    id: string
  ): Promise<Response<boolean>> {
    throw Error('Not implemented');
  }

  async delete(id: string): Promise<Response<boolean>> {
    throw Error('Not implemented');
  }
}

export default DigitalObjectPermissionsRepository;
