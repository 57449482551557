import { FormModel } from './interfaces';
import PaginatedFilter from './interfaces/PaginatedFilter';
import { SelectOption } from '@campus/components';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

export interface StudyPeriodFilter extends PaginatedFilter {
  name?: string;
}

export type StudyPeriodInput = {
  name: string;
  startDate: Date;
  endDate: Date;
  active?: boolean;
  hideScoreStartDate?: Date;
  hideScoreEndDate?: Date;
  id?: string;
};

export type StudyPeriodDTO = {
  Name: string;
  StartDate: Date;
  EndDate: Date;
  Active?: boolean;
  HideScoreStartDate?: Date;
  HideScoreEndDate?: Date;
};

export class StudyPeriod implements FormModel<StudyPeriodInput> {
  id: string;

  name: string;

  startDate: Date;

  endDate: Date;

  active?: boolean;

  hideScoreStartDate?: Date;

  hideScoreEndDate?: Date;

  startDateFormated?: string;

  endDateFormated?: string;

  activeFormated?: string;

  static fromJson(data: any): StudyPeriod {
    const period = new StudyPeriod();

    period.id = data?.Id;
    period.name = data?.Name;

    if (data?.StartDate) {
      const startDate = parseISO(data?.StartDate);
      period.startDate = data?.StartDate;
      period.startDateFormated = format(startDate, 'dd/MM/yyyy hh:mm');
    }

    if (data?.EndDate) {
      const endDate = parseISO(data?.EndDate);
      period.endDate = data?.EndDate;
      period.endDateFormated = format(endDate, 'dd/MM/yyyy hh:mm');
    }

    period.active = data?.Active;
    period.activeFormated = data?.Active ? 'Sim' : 'Não';

    period.hideScoreStartDate = data?.HideScoreStartDate;
    period.hideScoreEndDate = data?.HideScoreEndDate;

    return period;
  }

  static fromInputToDTO = (input: StudyPeriod): StudyPeriodDTO => ({
    Name: input?.name,
    StartDate: input?.startDate,
    EndDate: input?.endDate,
    HideScoreStartDate: input?.hideScoreStartDate,
    HideScoreEndDate: input?.hideScoreEndDate
  });

  toSelectOption = (): SelectOption => ({
    value: this.id,
    label: this.name
  });

  toInput = (): StudyPeriodInput => ({
    id: this.id,
    name: this.name,
    startDate: this.startDate,
    endDate: this.endDate,
    active: this.active,
    hideScoreStartDate: this.hideScoreStartDate,
    hideScoreEndDate: this.hideScoreEndDate
  });
}
