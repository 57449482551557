import React from 'react';

import { FormSelectorAsync, FormInput, HookForm } from '@campus/components';

import { ClassificationInput } from 'models/Classification';
import { getDisciplines, getGrades } from 'services/services';

type FormProps = {
  defaultValues: ClassificationInput;
  onSubmit: (data: ClassificationInput) => void;
};

const Form: React.FC<FormProps> = ({ defaultValues, onSubmit }) => {
  return (
    <HookForm
      asyncDefaultValues
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      submitLabel="Salvar"
    >
      <FormInput
        name="description"
        label="Descrição"
        placeholder="Insira a descrição"
        required
        grid={{ xs: 12 }}
      />

      <FormInput
        name="code"
        label="Código"
        placeholder="Insira o código"
        required
      />

      <FormSelectorAsync
        name="discipline"
        label="Buscar por componente curricular"
        placeholder="Selecione um componente curricular"
        getOptions={async (inputValue: string) => getDisciplines(inputValue)}
      />

      <FormSelectorAsync
        name="grade"
        label="Grau"
        placeholder="Selecione um grau"
        required
        getOptions={(inputValue: string) => getGrades(inputValue)}
      />
    </HookForm>
  );
};

export default Form;
