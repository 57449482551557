import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormInput, HookForm, toast } from '@campus/components';

import { FormSearchProps } from 'containers/AdminContainer';

import AdminForm, { FormProps } from 'components/AdminForm';
import Drawer from 'components/Drawer';

export interface SearchFormProps extends FormProps {
  searchData: any;
}

interface AdminSearchFormPageProps extends FormSearchProps {
  Form: React.ComponentType<SearchFormProps>;
}

const AdminSearchFormPage: React.FC<AdminSearchFormPageProps> = ({
  repository,
  Form
}) => {
  const params = useParams();
  const id = params.id;

  const [isSearch, setIsSearch] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    if (!id) setIsSearch(true);
  }, [id]);

  const handleSearch = async (data: any) => {
    setLoadingSubmit(true);

    const response = await repository.searchByKey(data);
    setLoadingSubmit(false);

    if (response.data) {
      setSearchData(response.data);
    } else if (response.error) {
      toast.error(response.error.message);
      return;
    }

    setIsSearch(false);
  };

  if (isSearch) {
    return (
      <Drawer title="Verificar usuário">
        <HookForm
          loading={loadingSubmit}
          submitLabel="Avançar"
          onSubmit={handleSearch}
        >
          <FormInput
            name="email"
            label="Email"
            type="email"
            grid={{ lg: 12 }}
            required
          />
        </HookForm>
      </Drawer>
    );
  }

  return (
    <Drawer title={!!id ? 'Editar' : 'Criar'}>
      <AdminForm repository={repository} Form={Form} searchData={searchData} />
    </Drawer>
  );
};

export default AdminSearchFormPage;
