import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

export interface ProfileFilter extends PaginatedFilter {
  name?: string;
}

export type ProfileInput = {
  id: string;
  name: string;
  roles: string[];
};

export class Profile implements FormModel<ProfileInput> {
  id?: string;
  name?: string;
  roles?: string[];

  static fromJson(data: any): Profile {
    const profile = new Profile();

    profile.id = data?.Id;
    profile.name = data?.Name;
    profile.roles = data?.Roles;

    return profile;
  }

  toInput = (): ProfileInput => ({
    id: this.id,
    name: this.name,
    roles: this.roles
  });
}
