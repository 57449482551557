import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HookForm, Spinner } from '@campus/components';

import { LoadingContainer } from './styled';
import { toast } from '@campus/components';

export type FormProps = {
  data?: any;
};

type AdminActionsFormProps = {
  Form: React.ComponentType<FormProps>;
  onLoadFormData: (id: string) => any;
  onSubmit: (id: Array<string>, data: any) => any;
  [x: string]: any;
};

const AdminActionsForm: React.FC<AdminActionsFormProps> = ({
  Form,
  onLoadFormData,
  onSubmit,
  ...props
}) => {
  const [loadingData, setLoadingData] = useState(true);
  const [defaultValues, setDefaultValues] = useState(null);
  const [searchParams] = useSearchParams();
  const ids = searchParams.get('ids')?.split(',');
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoadingData(true);
    const id = searchParams.get('ids')?.split(',')[0];
    const data = await onLoadFormData(id);
    if (data) {
      setDefaultValues(data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (data: any) => {
    setLoadingData(true);
    const response = await onSubmit(ids, data);
    setLoadingData(false);

    if (!response.error) {
      toast.success('👍 Dados salvos com sucesso');
      navigate(-1);
    } else {
      console.error(response.error);
      toast.error(
        response.error?.message ??
          'Erro ao salvar os dados. Entre em contato com o suporte.'
      );
    }
  };

  if (loadingData) {
    return (
      <LoadingContainer>
        <Spinner animation="border" variant="primary" />
        <span>Carregando...</span>
      </LoadingContainer>
    );
  }

  return (
    <HookForm
      loading={loadingData}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      submitLabel="Enviar"
    >
      <Form {...props} data={defaultValues} />
    </HookForm>
  );
};

export default AdminActionsForm;
