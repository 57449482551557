import React from 'react';

import { FormInput, DataGridProps } from '@campus/components';

import AdminContainer from 'containers/AdminContainer';
import PeriodRepository from 'repositories/PeriodRepository';

const PeriodForm: React.FC = () => {
  return (
    <FormInput
      name="name"
      label="Nome"
      placeholder="Insira o nome"
      required
      grid={{ xs: 12 }}
    />
  );
};

const PeriodFilter: React.FC = () => {
  return <FormInput name="name" label="Nome" placeholder="Insira o nome" />;
};

const PeriodPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [{ Header: 'Name', accessor: 'name' }],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de turnos"
      columns={columns}
      repository={new PeriodRepository()}
      components={{
        Form: PeriodForm,
        Filter: PeriodFilter
      }}
    />
  );
};

export default PeriodPage;
