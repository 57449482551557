import React from 'react';

import { FormInput, DataGridProps } from '@campus/components';

import { FormProps } from 'components/AdminForm';

import AdminContainer from 'containers/AdminContainer';
import TenantRepository from 'repositories/TenantRepository';

const TenantForm: React.FC<FormProps> = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome"
        required
        grid={{ xs: 12 }}
      />
    </>
  );
};

const TenantFilter: React.FC = () => {
  return <FormInput name="name" label="Nome" placeholder="Insira o nome" />;
};

const TenantPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [{ Header: 'Name', accessor: 'name' }],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de inquilinos"
      columns={columns}
      repository={new TenantRepository()}
      components={{
        Form: TenantForm,
        Filter: TenantFilter
      }}
    />
  );
};

export default TenantPage;
