import React from 'react';
import { FormInput } from '@campus/components';

import { FormProps } from 'components/AdminForm';
import FormInputImage from 'components/FormInputImage';

const UnitForm: React.FC<FormProps> = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome"
        required
        grid={{ xs: 12 }}
      />

      <FormInputImage
        placeholder="Logo da Unidade"
        name="imageUrl"
        variant="unit"
        required
      />

      <FormInputImage
        imageWidth={1032}
        imageHeight={182}
        placeholder="Banner da Dashboard"
        name="bannerImageUrl"
        variant="unit"
        required
      />

      <FormInputImage
        imageWidth={350}
        imageHeight={280}
        placeholder="Banner da Dashboard Mobile"
        name="bannerImageMobileUrl"
        variant="unit"
        required
      />

      <FormInput
        name="bannerRedirectUrl"
        label="Link de redirecionamento do Banner"
        placeholder="Insira o link"
        grid={{ xs: 12 }}
      />
    </>
  );
};

export default UnitForm;
