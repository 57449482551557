import React, { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { FormSelectorAsync, Row } from '@campus/components';

import { getDisciplines, getGrades, getPeriods } from 'services/services';

import {
  DisciplineMatrix,
  DisciplineMatrixInput
} from 'models/DisciplineMatrix';
import DisciplineCreation from './DisciplineCreation';

type MatrixFormProps = {
  onCreate: (input: DisciplineMatrix) => void;
  onChange: (input: DisciplineMatrixInput) => void;
};

const MatrixForm: React.FC<MatrixFormProps> = ({ onCreate, onChange }) => {
  const form = useForm<DisciplineMatrixInput>({ reValidateMode: 'onChange' });

  const { control } = form;

  const values = useWatch({ control });

  useEffect(() => {
    onChange(values as DisciplineMatrixInput);
  }, [values, onChange]);

  return (
    <FormProvider {...form}>
      <Row className="gy-2 gx-2 align-items-end">
        <FormSelectorAsync
          name="discipline"
          label="Componente Curricular"
          placeholder="Selecione um componente curricular"
          required
          grid={{ xs: 4 }}
          getOptions={(inputValue: string) => getDisciplines(inputValue)}
        />
        <FormSelectorAsync
          name="period"
          label="Período Letivo"
          placeholder="Selecione um período letivo"
          required
          grid={{ xs: 3 }}
          isClearable
          getOptions={(inputValue: string) => getPeriods(inputValue)}
        />
        <FormSelectorAsync
          name="grade"
          label="Série"
          placeholder="Selecione uma série"
          grid={{ xs: 3 }}
          isClearable
          getOptions={(inputValue: string) => getGrades(inputValue)}
        />
        <DisciplineCreation values={values} onCreate={onCreate} />
      </Row>
    </FormProvider>
  );
};

export default MatrixForm;
