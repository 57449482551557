import React, { useState, useEffect } from 'react';
import { Col, SelectOption } from '@campus/components';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { getClassroom } from 'services/services';
import ManagerClassRoomItem from './ManagerClassRoomItem';

const inputName = 'classRoom';

const ManagerClassRoom: React.FC = () => {
  const [classRoom, setClassRoom] = useState<Array<SelectOption>>([]);
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: inputName
  });

  useEffect(() => {
    const listClassRoom = async () => {
      const items = await getClassroom('', 1000);
      setClassRoom(items);
    };

    listClassRoom();
  }, []);

  return (
    <Col xs={12}>
      {classRoom?.map((room) => {
        const itemField = fields.findIndex((f: any) => f.value === room.value);

        return (
          <ManagerClassRoomItem
            index={itemField}
            item={room}
            checked={itemField >= 0}
            onAdd={append}
            onRemove={remove}
          />
        );
      })}
    </Col>
  );
};

export default ManagerClassRoom;
