import React from 'react';

import { ClassificationInput } from 'models/Classification';
import { useClassification } from '../../providers';
import Form from './components/Form';

const ClassificationForm: React.FC = () => {
  const { selected, onCreate } = useClassification();

  const handleSubmit = (data: ClassificationInput) => {
    onCreate({ ...selected, ...data });
  };

  if (!selected) {
    return <>Selecione ou adicione uma classificação</>;
  }

  return <Form onSubmit={handleSubmit} defaultValues={selected} />;
};

export default ClassificationForm;
