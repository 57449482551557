import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Table } from '@campus/components';

import { DisciplineMatrix } from 'models/DisciplineMatrix';
import AdminActionsForm from 'components/AdminActionsForm';
import DisciplineMatrixRepository from 'repositories/DisciplineMatrixRepository';

export const inputName = 'evaluations';

const EvaluationForm: React.FC = () => {
  const onLoadFormData = async (id) => {
    const repository = new DisciplineMatrixRepository();
    const response = await repository.get(id);

    if (response.data) {
      return response?.data;
    }
  };

  const onSubmit = async (id: Array<string>, data: any) => {
    const repository = new DisciplineMatrixRepository();
    const input = DisciplineMatrix.fromInputToDTO(data);
    input.Ids = id;
    const response = await repository.updateBatch(input);
    return response;
  };

  return (
    <AdminActionsForm
      Form={EvaluationTable}
      onLoadFormData={onLoadFormData}
      onSubmit={onSubmit}
    />
  );
};

const EvaluationTable: React.FC = () => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: inputName
  });

  return (
    <Table>
      <thead>
        <tr>
          <th>Etapa</th>
          <th>Tipo de avaliação</th>
          <th>Peso do desempenho</th>
          <th>Peso do engagamento</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((f, i) => {
          const fieldName = `${inputName}.${i}`;

          return <EvaluationInput key={f.id} fieldName={fieldName} />;
        })}
      </tbody>
    </Table>
  );
};

const EvaluationInput = ({ fieldName }) => {
  const { control, register } = useFormContext();

  const stage = useWatch({ name: `${fieldName}.stage`, control });

  const parseWeight = (value: string) => {
    const weight = parseInt(value);

    return weight && weight >= 0 ? weight : null;
  };

  const evaluationType = useWatch({
    name: `${fieldName}.evaluationType`,
    control
  });

  return (
    <tr>
      <td>{stage?.label ?? 'Todas'}</td>
      <td>{evaluationType?.name}</td>

      <td>
        <input
          type="number"
          {...register(`${fieldName}.performanceWeight`, {
            setValueAs: parseWeight
          })}
        />
      </td>
      <td>
        <input
          type="number"
          {...register(`${fieldName}.engagementWeight`, {
            setValueAs: parseWeight
          })}
        />
      </td>
    </tr>
  );
};

export default EvaluationForm;
