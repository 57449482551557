import { Api } from '@campus/auth';
import DisciplineRepository from 'repositories/DisciplineRepository';

export const getDisciplines = async (name: string) => {
  const disciplineRepository = new DisciplineRepository();

  const disciplines = await disciplineRepository.getAll({
    name: name,
    page: 0,
    pageSize: 100
  });

  return disciplines.data.items.map((i) => ({
    value: i.id,
    label: i.name
  }));
};

export const getClassDisciplineMatrix = async (classId: string) => {
  const api = Api.INSTANCE.getApi();

  const response = await api.get(`/class/${classId}/disciplinematrix`);

  if (response.data) {
    return response.data.map((d) => ({
      value: d.Id,
      label: d.DisciplineName
    }));
  }

  return [];
};
