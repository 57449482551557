import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  & div {
    width: 6rem;
    height: 6rem;
  }

  & span {
    width: 14rem;
    font-size: 18px;
    text-align: center;
    font-weight: ${(props) => props.theme.weight.semiBold};
    color: ${(props) => props.theme.colors.gray2};
  }
`;
