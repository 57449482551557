import React from 'react';

import {
  FormInput,
  FormSelectorAsync,
  DataGridProps
} from '@campus/components';
import AdminContainer from 'containers/AdminContainer';

import DisciplineRepository from 'repositories/DisciplineRepository';
import { getClassroom, getTeachers } from 'services/services';

const DisciplineForm: React.FC = () => {
  return (
    <FormInput
      name="name"
      label="Nome"
      placeholder="Insira o nome do Componente Curricular"
      required
      grid={{ xs: 12 }}
    />
  );
};

const DisciplineFilter: React.FC = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome do Componente Curricular"
      />

      <FormSelectorAsync
        name="teacher"
        label="Professor"
        placeholder="Selecione um professor"
        getOptions={(inputValue: string) => getTeachers(inputValue)}
      />

      <FormSelectorAsync
        name="classroom"
        label="Turma"
        placeholder="Selecione uma turma"
        getOptions={(inputValue: string) => getClassroom(inputValue)}
      />
    </>
  );
};

const DisciplinePage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [{ Header: 'Nome', accessor: 'name' }],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de componentes curriculares"
      repository={new DisciplineRepository()}
      columns={columns}
      components={{
        Form: DisciplineForm,
        Filter: DisciplineFilter
      }}
    />
  );
};

export default DisciplinePage;
