import UnitRepository from 'repositories/UnitRepository';

export const getUnities = async (name: string) => {
  const unitRepository = new UnitRepository();

  const unities = await unitRepository.getAll({
    name: name,
    page: 0,
    pageSize: 10
  });

  return unities.data.items.map((i) => ({
    value: i.id,
    label: i.name
  }));
};
