import React, { useEffect, useState } from 'react';
import { HookForm, Collapser, CollapserItem } from '@campus/components';
import { useSearchParams } from 'react-router-dom';

import AdminActions from '../AdminActions';

type AdminFilterProps = {
  loading: boolean;
  onFilter: (data: any) => void;
  onClear: () => void;
};

const AdminFilter: React.FC<AdminFilterProps> = ({
  children,
  loading,
  onFilter,
  onClear,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState();

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);

    setFilter(params.filter ? JSON.parse(params?.filter) : undefined);
  }, [searchParams]);

  const filterRef = React.useRef();

  useEffect(() => {
    if (filterRef.current !== filter) {
      onFilter(filter);
      filterRef.current = filter;
    }
  }, [filter, onFilter]);

  const handleSubmit = (data: any) => {
    setSearchParams({
      filter: JSON.stringify(data, (_, value) => (!!value ? value : undefined))
    });
  };

  const handleClear = () => {
    onClear();
    if (filter) setSearchParams({});
  };

  return (
    <Collapser>
      <CollapserItem open={true} header={<>Filtros</>} eventKey="filter">
        <HookForm
          loading={loading}
          asyncDefaultValues
          clearOnReset
          defaultValues={filter}
          onReset={handleClear}
          onSubmit={handleSubmit}
          submitLabel="Pesquisar"
          ActionsComponent={AdminActions}
        >
          {children}
        </HookForm>
      </CollapserItem>
    </Collapser>
  );
};

export default AdminFilter;
