import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';

import { Trash, PlusSquare } from '@campus/components/icons';
import {
  Row,
  Col,
  SelectAsync,
  Button,
  SelectOption
} from '@campus/components';

import { getProfileUsers } from 'services/ProfileService';
import { getUnities } from 'services/UnitService';

import { useProfileUser } from '../hooks/useProfileUser';

import { UserText } from '../styles';
import { Separator } from '../../../styles';

type UserListProps = {
  id: string;
};

const UserList: React.FC<UserListProps> = ({ id }) => {
  const { loading, removeUser, addUser, users } = useProfileUser(id);

  const [loadProfileUsers] = useDebounce(getProfileUsers, 300);

  const [user, setUser] = useState<SelectOption<string>>();
  const [unit, setUnit] = useState<SelectOption<string>>();

  const handleUserChange = (value: SelectOption<string>) => setUser(value);
  const handleUnitChange = (value: SelectOption<string>) => setUnit(value);

  const handleAdd = async () => {
    await addUser(unit, user);

    setUser(null);
    setUnit(null);
  };

  if (loading) {
    return <>Carregando...</>;
  }

  return (
    <Row className="gy-2 gx-2">
      <Row className="gy-2 gx-2 align-items-end">
        <Col>
          <SelectAsync
            name="unit"
            label="Unidade"
            defaultOptions
            cacheOptions
            value={unit}
            onChange={handleUnitChange}
            loadOptions={getUnities}
          />
        </Col>
        <Col>
          <SelectAsync
            name="user"
            label="Usuário"
            cacheOptions
            value={user}
            onChange={handleUserChange}
            isDisabled={!unit}
            loadOptions={loadProfileUsers}
          />
        </Col>
        <Col xs="auto">
          <Button disabled={!user && !unit} onClick={handleAdd}>
            <PlusSquare size={16} />
          </Button>
        </Col>
      </Row>
      <Separator />
      {users.map((u) => (
        <Row key={u?.user?.id} className="gy-2 gx-2 align-items-center">
          <Col>
            <UserText>{`${u?.unit?.name} - ${u?.user?.name}`}</UserText>
          </Col>
          <Col xs="auto">
            <Button variant="outline-secondary" onClick={() => removeUser(u)}>
              <Trash size={16} />
            </Button>
          </Col>
        </Row>
      ))}
    </Row>
  );
};

export default UserList;
