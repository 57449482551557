import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.gray2};
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 25px;
  margin: 0;
`;

export const Container = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const IconContainer = styled.div`
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid rgba(42, 210, 201, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;

  :after {
    content: '';
    width: 118px;
    height: 118px;
    display: block;
    position: absolute;
    background: rgba(42, 210, 201, 0.8);
    border-radius: 50%;
    transition: opacity 0.3s ease-out;
  }

  & svg {
    z-index: 8;
    transform: translateZ(0);
  }
`;

export const CardText = styled.h5`
  font-size: 17px;
  color: ${(props) => props.theme.colors.gray3};
  font-weight: ${(props) => props.theme.weight.semiBold};
  padding: 0px 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  z-index: 8;
  transition: color 0.3s ease-out;
`;

export const CardLink = styled(Link)`
  border-radius: 20px;
  width: 220px;
  height: 321px;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;

  &:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
      0 24px 46px rgba(42, 210, 201, 0.48);
  }

  &:hover ${IconContainer} {
    border-color: rgba(42, 210, 201, 0.8);
    background: rgba(42, 210, 201, 0.8);
  }

  &:hover ${IconContainer} {
    &:after {
      background: rgba(42, 210, 201, 0.8);
    }
  }

  &:hover ${CardText} {
    color: ${(props) => props.theme.colors.gray3};
  }

  &:active {
    transform: scale(1) translateZ(0);
    box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
      0 15px 24px rgba(42, 210, 201, 0.48);
  }
`;
