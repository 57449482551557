import React from 'react';
import styled from 'styled-components';

import { Pencil } from '@campus/components/icons';

import { ActionProps } from 'containers/AdminContainer';
import DeleteButton from './DeleteButton';
import { useAdminContext } from 'providers/AdminProvider';
import { useLocation, useNavigate } from 'react-router-dom';

export const ActionsContainer = styled.div`
  display: flex;

  & > * {
    margin-right: 1rem;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const DisciplineMatrixActions: React.FC<ActionProps<any>> = ({ data }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const onEdit = (id: string) => navigate({ pathname: id, search });

  const { onDelete } = useAdminContext();

  return (
    <ActionsContainer>
      <Pencil size={16} onClick={() => onEdit(data?.id)} />
      <DeleteButton onDelete={() => onDelete(data?.id)} />
    </ActionsContainer>
  );
};

export default DisciplineMatrixActions;
