import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

export interface TenantFilter extends PaginatedFilter {
  name?: string;
}

export type TenantInput = {
  id: string;
  name: string;
};

export class Tenant implements FormModel<TenantInput> {
  id?: string;
  name?: string;

  static fromJson(data: any): Tenant {
    const tenant = new Tenant();

    tenant.id = data?.Id;
    tenant.name = data?.Name;

    return tenant;
  }

  toInput = (): TenantInput => ({
    id: this.id,
    name: this.name
  });
}
