import React, { useEffect, useState } from 'react';
import { HookForm, Collapser, CollapserItem } from '@campus/components';
import { useSearchParams } from 'react-router-dom';
import { useAdminContext } from 'providers/AdminProvider';

const AdminTableFilter: React.FC = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState();

  const { loading, onFilter } = useAdminContext();

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);

    setFilter(params.filter ? JSON.parse(params?.filter) : undefined);
  }, [searchParams]);

  const filterRef = React.useRef();

  useEffect(() => {
    if (filterRef.current !== filter) {
      onFilter(filter);
      filterRef.current = filter;
    }
  }, [filter, onFilter]);

  const handleSubmit = (data: any) => {
    setSearchParams({
      filter: JSON.stringify(data, (_, value) => (!!value ? value : undefined))
    });
  };

  const handleClear = () => {
    if (filter) setSearchParams({});
  };

  return (
    <Collapser>
      <CollapserItem header={<>Filtros</>} eventKey="filter">
        <HookForm
          loading={loading}
          asyncDefaultValues
          clearOnReset
          defaultValues={filter}
          onReset={handleClear}
          onSubmit={handleSubmit}
          submitLabel="Pesquisar"
        >
          {children}
        </HookForm>
      </CollapserItem>
    </Collapser>
  );
};

export default AdminTableFilter;
