import React from 'react';
import { Route } from 'react-router-dom';

import { AppContainer } from '@campus/commons';
import { ContextType } from '@campus/auth';
import { adminTheme } from '@campus/components';

import Menu from 'containers/Menu';
import Content from 'containers/Content';

import Home from 'pages/Home';

import routes from './routes';

import AdminMainContextProvider from 'providers/AdminMainContextProvider';

const App: React.FC = () => {
  return (
    <AppContainer
      theme={adminTheme}
      contextType={ContextType.ADMIN}
      components={{
        Menu: Menu,
        Content: Content,
        MainProvider: AdminMainContextProvider
      }}
    >
      <Route path="" element={<Home />} />

      {routes.map((route) => (
        <Route
          key={route.path}
          path={`${route.path}/*`}
          element={route.component}
        />
      ))}
    </AppContainer>
  );
};

export default App;
