import styled from 'styled-components';

export const Separator = styled.hr`
  height: 1px;
  opacity: 1;
  background-color: ${(props) => props.theme.colors.gray5};
  margin: 1rem 0;
`;

export const RoleContainer = styled.div`
  h3 {
    color: ${({ theme }) => theme.colors.gray3};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
`;

export const RoleList = styled.div`
  height: 400px;
  overflow: auto;
`;
