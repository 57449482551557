import { SelectOption } from '@campus/components';

import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

import { Segment } from './Segment';

export interface GradeFilter extends PaginatedFilter {
  name?: string;
  segment?: SelectOption;
}

export type GradeInput = {
  name: string;
  segment?: SelectOption;
};

export class Grade implements FormModel<GradeInput> {
  id?: string;
  name?: string;
  segment?: Segment;

  static fromJson(data: any): Grade {
    const grade = new Grade();

    grade.id = data?.Id;
    grade.name = data?.Name;
    grade.segment = Segment.fromJson(data?.Segment);

    return grade;
  }

  toInput = (): GradeInput => ({
    name: this.name,
    segment: { label: this.segment.name, value: this.segment.id }
  });
}
