import React, { useEffect, useState } from 'react';

import { Col, Collapser, CollapserItem } from '@campus/components';

import { getRoles } from 'services/services';
import { ProfileRole } from 'models/ProfileRole';

import { RoleContainer, RoleList, Separator } from '../styles';
import PermissionsInput from './PermissionsInput';

const RolesInput: React.FC = () => {
  const [roles, setRoles] = useState<ProfileRole[]>([]);

  const loadRoles = async () => {
    const response = await getRoles();

    if (response.data) {
      setRoles(response.data);
    }
  };

  useEffect(() => {
    loadRoles();
  }, []);

  return (
    <Col xs={12}>
      <Separator />
      <RoleContainer>
        <h3>Permissões</h3>
        <RoleList>
          {roles.map((r, i) => (
            <Collapser key={i}>
              <CollapserItem eventKey={i} header={r.name}>
                <PermissionsInput roles={r.roles} />
              </CollapserItem>
            </Collapser>
          ))}
        </RoleList>
      </RoleContainer>
      <Separator />
    </Col>
  );
};

export default RolesInput;
