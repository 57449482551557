import React from 'react';
import { FormInput, Row } from '@campus/components';

import { Title } from './styles';

type ClassificationTypeFormProps = {
  loadingData: boolean;
};

const ClassificationTypeForm: React.FC<ClassificationTypeFormProps> = ({
  loadingData
}) => {
  if (loadingData) {
    return <>Carregando... </>;
  }

  return (
    <div>
      <Title>Informação do tipo</Title>
      <Row className="gy-2 gx-2">
        <FormInput
          name="name"
          label="Nome"
          placeholder="Insira o nome"
          required
        />
        <FormInput
          name="description"
          label="Descrição"
          placeholder="Insira a descrição"
          required
        />
      </Row>
    </div>
  );
};

export default ClassificationTypeForm;
