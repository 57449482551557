import React from 'react';

import { Container, ImagePlaceholder } from './styles';

type ImageViewProps = {
  image: string;
  imageWidth?: number;
  imageHeight?: number;
};

const ImageView: React.FC<ImageViewProps> = ({
  image,
  imageWidth,
  imageHeight
}) => {
  const containerHeight = '100px';
  const containerWidth =
    imageWidth && imageHeight
      ? `${(imageWidth * 100) / imageHeight}px`
      : '100px';

  return (
    <Container $width={containerWidth} $height={containerHeight}>
      {image ? (
        <img src={image} alt="Imagem do conteúdo" />
      ) : (
        <ImagePlaceholder />
      )}
    </Container>
  );
};

export default ImageView;
