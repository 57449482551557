import React from 'react';

import ClassificationTreeView from '../ClassificationTreeView';
import ClassificationForm from '../ClassificationForm';

import ClassificationProvider from '../../providers/ClassificationProvider';

import { Container, Box } from './styles';

type ClassificationBodyProps = {
  loadingData: boolean;
  typeId: string;
};

const ClassificationBody: React.FC<ClassificationBodyProps> = ({
  loadingData,
  typeId
}) => {
  if (loadingData) {
    return <>Carregando...</>;
  }

  return (
    <ClassificationProvider typeId={typeId}>
      <Container>
        <Box>
          <ClassificationTreeView typeId={typeId} />
        </Box>
        <Box>
          <ClassificationForm />
        </Box>
      </Container>
    </ClassificationProvider>
  );
};

export default ClassificationBody;
