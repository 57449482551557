import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

import { User } from './User';

export interface TeacherFilter extends PaginatedFilter {
  name?: string;
  classroom?: { value: string };
  discipline?: { value: string };
}

export type TeacherInput = {
  userId: string;
  name: string;
  birthday?: string;
  imageUrl?: string;
  email?: string;
};

export class Teacher implements FormModel<TeacherInput> {
  id?: string;
  user?: User;
  birthday?: string;
  birthdayFormated?: string;
  imageUrl?: string;
  tenantId?: string;

  static fromJson(data: any): Teacher {
    const teacher = new Teacher();

    if (data?.Birthday) {
      const birthdayDate = parseISO(data?.Birthday);
      teacher.birthday = format(birthdayDate, 'yyyy-MM-dd');
      teacher.birthdayFormated = format(birthdayDate, 'dd/MM/yyyy');
    }

    teacher.id = data?.Id;
    teacher.user = User.fromJson(data?.User);
    teacher.imageUrl = data?.ImageUrl;
    teacher.tenantId = data?.TenantId;

    return teacher;
  }

  toInput = (): TeacherInput => ({
    userId: this.user.id,
    name: this.user.name,
    email: this.user.email,
    birthday: this.birthday,
    imageUrl: this.imageUrl
  });
}
