import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

export interface StageFilter extends PaginatedFilter {
  name?: string;
}

export type StageInput = {
  name: string;
  startDate?: string;
  endDate?: string;
};

export class Stage implements FormModel<StageInput> {
  id?: string;
  name?: string;
  startDate?: string;
  startDateFormated?: string;
  endDate?: string;
  endDateFormated?: string;

  static fromJson(data: any): Stage {
    const stage = new Stage();

    if (data?.StartDate) {
      const startDate = parseISO(data?.StartDate);
      stage.startDate = format(startDate, 'yyyy-MM-dd');
      stage.startDateFormated = format(startDate, 'dd/MM/yyyy');
    }

    if (data?.EndDate) {
      const endDate = parseISO(data?.EndDate);
      stage.endDate = format(endDate, 'yyyy-MM-dd');
      stage.endDateFormated = format(endDate, 'dd/MM/yyyy');
    }

    stage.id = data?.Id;
    stage.name = data?.Name;

    return stage;
  }

  toInput = (): StageInput => ({
    name: this.name,
    startDate: this.startDate,
    endDate: this.endDate
  });
}
