import React, { useMemo, useContext, useEffect } from 'react';
import {
  Row,
  CollapserItem,
  FormInput,
  CollapserContext,
  Button
} from '@campus/components';
import {
  useFormContext,
  useFormState,
  useWatch,
  useFieldArray
} from 'react-hook-form';
import { Delete } from '@styled-icons/material/Delete';

import { CloseAccordion, StyledCol } from '../styles';

type StudentResponsiblesItemProps = {
  index: number;
  onRemove: (key) => void;
};

export const inputName = 'responsibles';

const StudentResponsiblesItem: React.FC<StudentResponsiblesItemProps> = ({
  index,
  onRemove
}) => {
  const fieldName = `${inputName}.${index}`;
  const fieldPhoneNumber = `${fieldName}.phoneNumbers`;

  const { control } = useFormContext();
  const responsible = useWatch({ control, name: fieldName });
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldPhoneNumber
  });
  const { isSubmitting, errors } = useFormState({ control });
  const { forceOpened } = useContext(CollapserContext);

  useEffect(() => {
    if (isSubmitting) {
      const responsible = errors?.responsibles;

      if (responsible instanceof Array) {
        const i = responsible.findIndex((e) => e);
        forceOpened(i);
      }
    }
  }, [isSubmitting, errors, forceOpened]);

  const title = useMemo(() => {
    if (responsible === null) {
      return `Responsável ${index + 1}`;
    }

    return responsible?.name;
  }, [responsible, index]);

  return (
    <CollapserItem
      eventKey={index}
      header={
        <>
          <CloseAccordion onClick={onRemove} />
          {title}
        </>
      }
    >
      <Row className="gy-2 gx-2">
        <FormInput
          name={`${fieldName}.name`}
          label="Nome"
          required
          placeholder="Insira o nome"
          grid={{ lg: 6 }}
        />

        <FormInput
          name={`${fieldName}.email`}
          label="Email"
          type="email"
          placeholder="Insira o e-mail"
          grid={{ lg: 6 }}
        />

        <FormInput
          name={`${fieldName}.birthDate`}
          type="date"
          label="Data de Nascimento"
          placeholder="Insira a data de nascimento"
          grid={{ lg: 6 }}
        />

        {fields.map((f, i) => (
          <React.Fragment key={f.id}>
            <FormInput
              name={`${fieldPhoneNumber}.${i}`}
              label="Telefone"
              placeholder="Telefone"
              required
              grid={{ xs: 3 }}
            />

            <StyledCol xs={1}>
              <Button variant="light" onClick={() => remove(i)}>
                <Delete color="gray" size={24} />
              </Button>
            </StyledCol>
          </React.Fragment>
        ))}

        <Row>
          <Button variant="link" onClick={() => append('')}>
            Adicionar Telefone
          </Button>
        </Row>
      </Row>
    </CollapserItem>
  );
};

export default StudentResponsiblesItem;
