import React, { useState } from 'react';
import { Button, Dropdown, FormInput, Modal, Row } from '@campus/components';
import { FormProvider, useForm } from 'react-hook-form';
import ClassroomDisciplineInput from './ClassroomDisciplineInput';
import ClassroomDisciplineRepository, {
  ClassDisciplineInput
} from 'repositories/ClassroomDisciplineRepository';
import { useAdminContext } from 'providers/AdminProvider';

const _repository = new ClassroomDisciplineRepository();

const AppBarActions: React.FC = () => {
  const [show, setShow] = useState(false);

  const { filterData, onFetch } = useAdminContext();

  const handleDelete = async () => {
    const classId = filterData?.classroom?.value;
    const disciplineId = filterData?.discipline?.value;

    await _repository.deleteBatch({ classId, disciplineId });
    await onFetch(0, 10);
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="primary">Ações</Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={handleDelete}>
            Remover todos os estudantes da turma/componente curricular
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShow(true)}>
            Adicionar todos os estudantes de outra turma/componente curricular
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {show ? (
        <ClassroomDisciplineModal show={show} onHide={() => setShow(false)} />
      ) : null}
    </>
  );
};

type ClassroomDisciplineModalProps = {
  show: boolean;
  onHide: () => void;
};

const ClassroomDisciplineModal: React.FC<ClassroomDisciplineModalProps> = ({
  show,
  onHide
}) => {
  const { filterData, onFetch } = useAdminContext();
  const form = useForm<ClassDisciplineInput>({
    reValidateMode: 'onChange'
  });

  const onSubmit = form.handleSubmit(async (data) => {
    const sourceClassroom = filterData?.classroom;
    const sourceDiscipline = filterData?.discipline;

    await _repository.create({
      sourceClassroom,
      sourceDiscipline,
      ...data
    });

    onHide();
    await onFetch(0, 10);
  });

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Deseja adicionar alunos de outro componente curricular?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...form}>
          <Row className="gy-2 gx-2 align-items-end">
            <ClassroomDisciplineInput />

            <FormInput
              name="startDate"
              type="date"
              label="Data de inicio"
              required
            />

            <FormInput
              name="endDate"
              type="date"
              label="Data de fim"
              required
            />
          </Row>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          Cancelar
        </Button>

        <Button onClick={onSubmit}>Confirmar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppBarActions;
