import { Api } from '@campus/auth';
import { PaginationModel, Response } from '@campus/commons';
import { ProfileUser } from 'models/ProfileUser';

import { User } from 'models/User';

type UserProfileInput = {
  id: string;
  unitId: string;
  userId: string;
};

class UserProfileRepository {
  path: string = '/profile';

  get api() {
    return Api.INSTANCE.getApi();
  }

  async getAll(text: string): Promise<Response<User[]>> {
    try {
      if (text.length < 3) {
        return Response.fromData([], 200);
      }

      const response = await this.api.get(`${this.path}/user`, {
        params: { text: text }
      });

      const data = response.data?.map(User.fromJson) ?? [];

      return Response.fromData(data, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }

  async get(
    profileId: string
  ): Promise<Response<PaginationModel<ProfileUser>>> {
    try {
      const response = await this.api.get(`${this.path}/${profileId}/users`);

      const data = PaginationModel.fromJson(ProfileUser, response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }

  async create(input: UserProfileInput): Promise<Response<ProfileUser>> {
    try {
      const { id, unitId, userId } = input;
      const response = await this.api.post(
        `${this.path}/${id}/users/${unitId}/${userId}`
      );

      return Response.fromData(
        ProfileUser.fromJson(response.data),
        response.status
      );
    } catch (e) {
      return Response.error(e);
    }
  }

  async delete(input: UserProfileInput): Promise<Response<boolean>> {
    try {
      const { id, unitId, userId } = input;
      const response = await this.api.delete(
        `${this.path}/${id}/users/${unitId}/${userId}`
      );

      return Response.fromData(true, response.status);
    } catch (e) {
      return Response.error(e);
    }
  }
}

export default UserProfileRepository;
