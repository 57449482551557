import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const Box = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
`;
