import React from 'react';

import {
  FormInput,
  FormSelectorAsync,
  DataGridProps
} from '@campus/components';

import TeacherRepository from 'repositories/TeacherRepository';
import { getClassroom, getDisciplines } from 'services/services';
import AdminContainer, { FormSearchProps } from 'containers/AdminContainer';
import AdminSearchFormPage from 'containers/AdminSearchFormPage';

import TeacherForm from './components/TeacherForm';

const TeacherFormPage: React.FC<FormSearchProps> = ({ repository }) => {
  return <AdminSearchFormPage repository={repository} Form={TeacherForm} />;
};

const TeacherFilter: React.FC = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Buscar pelo Nome"
        placeholder="Insira o nome"
      />

      <FormSelectorAsync
        name="classroom"
        label="Buscar por Turma"
        placeholder="Selecione uma Turma"
        getOptions={async (inputValue: string) => getClassroom(inputValue)}
      />

      <FormSelectorAsync
        name="discipline"
        label="Buscar por componente curricular"
        placeholder="Selecione um componente curricular"
        getOptions={async (inputValue: string) => getDisciplines(inputValue)}
      />
    </>
  );
};

const TeacherPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [
      { Header: 'Nome', accessor: 'user.name' },
      { Header: 'Email', accessor: 'user.email' },
      { Header: 'Data de Nascimento', accessor: 'birthdayFormated' }
    ],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de professores"
      columns={columns}
      repository={new TeacherRepository()}
      components={{
        Filter: TeacherFilter,
        FormPage: TeacherFormPage
      }}
    />
  );
};

export default TeacherPage;
