import { Discipline } from '@campus/commons';
import { SelectOption } from '@campus/components';

import { Grade } from './Grade';
import PaginatedFilter from './interfaces/PaginatedFilter';
import { LibraryObject } from './LibraryObject';

export type LibraryObjectStudentPermissionInput = {
  name: string;
};

export type LibraryObjectStudentPermissionUpdateInput= {
  studentsIds: string[];
  permission: boolean;
};

export interface LibraryObjectStudentPermissionFilter extends PaginatedFilter {
  name?: string;
  discipline?: SelectOption;
  classroom?: SelectOption;
  grade?: SelectOption;
}

export interface StudentPermissionFilter extends PaginatedFilter {
  libraryObjectId: string;
  disciplineId?: string;
  gradeId?: string;
}

export class StudentBasic {
  id: string;
  name: string;
  imageUrl?: string;

  static fromJson(data: any): StudentBasic {
    const student = new StudentBasic();

    student.id = data?.Id;
    student.name = data?.Name;
    student.imageUrl = data?.ImageUrl;

    return student;
  }
}

export class DisciplineBasic {
  id: string;
  name: string;

  static fromJson(data: any): DisciplineBasic {
    const discipline = new DisciplineBasic();

    discipline.id = data?.Id;
    discipline.name = data?.Name;

    return discipline;
  }
}

export class GradeBasic {
  id: string;
  name: string;

  static fromJson(data: any): GradeBasic {
    const grade = new GradeBasic();

    grade.id = data?.Id;
    grade.name = data?.Name;

    return grade;
  }
}

export class StudentsPermissions {
  student: StudentBasic;
  discipline: DisciplineBasic;
  grade: GradeBasic;
  hasPermission: boolean;

  static fromJson(data: any): StudentsPermissions {
    const studentsPermissions = new StudentsPermissions();

    studentsPermissions.student = data?.Student
      ? StudentBasic.fromJson(data?.Student)
      : undefined;

    studentsPermissions.discipline = data?.Discipline
      ? DisciplineBasic.fromJson(data?.Discipline)
      : undefined;

    studentsPermissions.grade = data?.Grade
      ? GradeBasic.fromJson(data?.Grade)
      : undefined;

    studentsPermissions.hasPermission = data?.HasPermission;

    return studentsPermissions;
  }
}

export class LibraryObjectStudentPermission {
  id?: string;

  libraryObject: LibraryObject;

  discipline?: Discipline;

  grade?: Grade;

  static fromJson(data: any): LibraryObjectStudentPermission {
    const libraryObject = new LibraryObjectStudentPermission();

    libraryObject.id = data?.Id;

    libraryObject.libraryObject = data?.LibraryObject
      ? LibraryObject.fromJson(data?.LibraryObject)
      : undefined;

    libraryObject.discipline = data?.Discipline
      ? Discipline.fromJson(data?.Discipline)
      : undefined;

    libraryObject.grade = data?.Grade ? Grade.fromJson(data?.Grade) : undefined;

    return libraryObject;
  }

  toInput = (): LibraryObjectStudentPermissionInput => ({
    name: this.libraryObject.name
  });
}
