import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { DataGridProps } from '@campus/components';

import { AdminRepository } from 'repositories/interfaces';

import AdminTable from './components/AdminTable';
import FormPage from './components/FormPage';

import { AdminActionsComponents } from './types';

export type AdminActionsContainerProps = {
  title: string;
  columns: DataGridProps['columns'];
  repository: AdminRepository;
  components: AdminActionsComponents;
};


const AdminActionsContainer: React.FC<AdminActionsContainerProps> = ({
  title,
  columns,
  repository,
  components,
}) => {
  const actions = components.actions;

  return (
    <Routes>
      <Route
        element={
          <AdminTable
            title={title}
            columns={columns}
            repository={repository}
            components={components}
          />
        }
      >
        <Route index element={<></>} />
        {actions.map(item => (
          <Route
            path={item.path}
            element={
              <FormPage
                isEdit
                repository={repository}
                Form={components.Form}
                EditForm={item.EditForm}
              />
            }
          />
        ))}

        <Route
          path="new"
          element={<FormPage repository={repository} Form={components.Form} EditForm={components.EditForm} />}
        />
      </Route>
    </Routes>
  )
};

export default AdminActionsContainer;