import { Manager } from './Manager';
import { Permission } from './Permission';

export class PermissionFeatureManager {
  manager?: Manager;
  permission?: Permission;

  static fromJson(data: any): PermissionFeatureManager {
    const permissionFeatureManager = new PermissionFeatureManager();

    permissionFeatureManager.manager = Manager.fromJson(data?.Manager);
    permissionFeatureManager.permission = Permission.fromJson(data?.Permission);

    return permissionFeatureManager;
  }
}
