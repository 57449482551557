import React from 'react';

import {
  FormSelectorAsync,
  DataGridProps,
  FormCheckbox,
} from '@campus/components';
import AdminActionsContainer from 'containers/AdminActionsContainer';
import Drawer from 'components/Drawer';
import { FormSearchProps } from 'containers/AdminActionsContainer/types';

import {
  getClassroom,
  getDisciplines,
  getGrades,
  getPeriods,
  getEvaluationMatrices
} from 'services/services';
import DisciplineMatrixRepository from 'repositories/DisciplineMatrixRepository';

import EvaluationForm from './components/EvaluationForm';
import EvaluationStageForm from './components/EvaluationStageForm';
import EvaluationPercentagesEdit from './components/EvaluationPercentagesEdit';

const DisciplineMatrixForm: React.FC = () => {
  return (
    <>
      <FormSelectorAsync
        name="discipline"
        label="Componente Curricular"
        placeholder="Selecione um componente curricular"
        getOptions={(v: string) => getDisciplines(v)}
        required
      />
      <FormSelectorAsync
        name="period"
        label="Período Letivo"
        placeholder="Selecione um período letivo"
        getOptions={(v: string) => getPeriods(v)}
        required
      />
      <FormSelectorAsync
        name="grade"
        label="Série"
        placeholder="Selecione uma série"
        getOptions={(v: string) => getGrades(v)}
        required
      />
      <FormSelectorAsync
        name="evaluationMatrix"
        label="Matriz de avaliação"
        placeholder="Selecione uma matriz"
        getOptions={(v: string) => getEvaluationMatrices(v)}
        required
      />
      <FormCheckbox name="showPerformance" label="Exibir performance" />
    </>
  );
};

const DisciplineMatrixEditForm: React.FC<FormSearchProps> = () => {
  return (
    <Drawer title="Avaliações">
      <EvaluationForm />
    </Drawer>
  );
};

const DisciplineMatrixStage: React.FC<FormSearchProps> = () => {
  return (
    <Drawer title="Etapas">
      <EvaluationStageForm />
    </Drawer>
  );
};

const DisciplineMatrixEdit: React.FC<FormSearchProps> = () => {
  return (
    <Drawer title="Editar Componente Curricular">
      <EvaluationPercentagesEdit />
    </Drawer>
  );
};

const DisciplineMatrixFilter: React.FC = () => {
  return (
    <>
      <FormSelectorAsync
        name="discipline"
        label="Componente Curricular"
        placeholder="Selecione um componente curricular"
        grid={{ xs: 4 }}
        getOptions={(i: string) => getDisciplines(i)}
      />

      <FormSelectorAsync
        name="classroom"
        label="Turma"
        placeholder="Selecione uma turma"
        grid={{ xs: 4 }}
        getOptions={(i: string) => getClassroom(i)}
      />

      <FormSelectorAsync
        name="period"
        label="Período"
        placeholder="Selecione um período"
        grid={{ xs: 4 }}
        getOptions={(i: string) => getPeriods(i)}
      />
    </>
  );
};

const DisciplineMatrixPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [
      { Header: 'Componente Curricular', accessor: 'discipline.name' },
      { Header: 'Série', accessor: 'grade.name' },
      {
        Header: 'Turmas',
        accessor: 'classDisciplines',
        Cell: ({ value }) => <>{value?.map((c) => c.name).join(', ')}</>
      },

      { Header: 'Período', accessor: 'period.name' }
    ],
    []
  );

  return (
    <AdminActionsContainer
      title="Cadastro de percentuais de avalição"
      repository={new DisciplineMatrixRepository()}
      columns={columns}
      components={{
        Filter: DisciplineMatrixFilter,
        Form: DisciplineMatrixForm,
        EditForm: DisciplineMatrixEditForm,
        actions: [
          {
            EditForm: DisciplineMatrixEditForm,
            label: 'Atualizar as avaliações das matrizes do componente curricular',
            path: 'evaluation'
          },
          {
            EditForm: DisciplineMatrixStage,
            label: 'Atualizar as configurações de etapa das matrizes de componente curricular',
            path: 'stage'
          },
          {
            EditForm: DisciplineMatrixEdit,
            label: 'Editar as configurações dos percentuais de avaliação',
            path: 'editDisciplineMatrix'
          }
        ]
      }}
    />
  );
};

export default DisciplineMatrixPage;
