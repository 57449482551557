import { SelectOption } from '@campus/components';
import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';
import { hslStringToHex } from '../utils/ColorConversion';

export interface EvaluationTypeDescriptionFilter extends PaginatedFilter {
  name?: string;
}

export type EvaluationTypeDescriptionInput = {
  name?: string;
  pluralName?: string;
  color?: string;
  id?: string;
};

export type EvaluationTypeDescriptionDTO = {
  Name?: string;
  PluralName?: string;
  Color?: string;
};
export class EvaluationTypeDescription
  implements FormModel<EvaluationTypeDescriptionInput>
{
  id?: string;

  name?: string;

  pluralName?: string;

  color?: string;

  static fromJson(data: any): EvaluationTypeDescription {
    const evaluationTypeDescription = new EvaluationTypeDescription();

    evaluationTypeDescription.id = data?.Id;
    evaluationTypeDescription.name = data?.Name;
    evaluationTypeDescription.pluralName = data?.PluralName;
    if (data?.Color) {
      evaluationTypeDescription.color = hslStringToHex(data?.Color);
    }

    return evaluationTypeDescription;
  }

  static fromInputToDTO = (
    input: EvaluationTypeDescription
  ): EvaluationTypeDescriptionDTO => ({
    Name: input.name,
    PluralName: input.pluralName,
    Color: input.color
  });

  toSelectOption = (): SelectOption => ({
    value: this.id,
    label: this.name
  });

  toInput = (): EvaluationTypeDescriptionInput => ({
    id: this.id,
    name: this.name,
    pluralName: this.pluralName,
    color: this.color
  });
}
