import React from 'react';

import { FormInput, Tabs, Tab, Row } from '@campus/components';

import { SearchFormProps } from 'containers/AdminSearchFormPage';
import FormInputImage from 'components/FormInputImage';

import ManagerClassRoom from './ManagerClassRoom';
import useSearchForm from 'hooks/useSearchForm';

const requiredFields = ['name', 'email'];
const defaultKey = 'manager';

const ManagerForm: React.FC<SearchFormProps> = ({ searchData }) => {
  const { key, setKey } = useSearchForm({
    searchData,
    requiredFields,
    defaultKey
  });

  return (
    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
      <Tab eventKey={defaultKey} title="Gestor">
        <Row className="gy-2 gx-2">
          <FormInput
            name="name"
            label="Nome"
            placeholder="Insira o nome"
            required
          />

          <FormInput
            name="email"
            label="Email"
            placeholder="Insira o email"
            required
          />

          <FormInput
            name="birthday"
            type="date"
            label="Data de Nascimento"
            placeholder="Insira a data de nascimento"
          />

          <FormInputImage name="imageUrl" variant="manager" />
        </Row>
      </Tab>
      <Tab eventKey="classroom" title="Turmas">
        <ManagerClassRoom />
      </Tab>
    </Tabs>
  );
};

export default ManagerForm;
