export class ProfileRole {
  name?: string;
  roles?: Role[];

  static fromJson(data: any): ProfileRole {
    const profileRole = new ProfileRole();

    profileRole.name = data?.Name;
    profileRole.roles = data?.Roles?.map(Role.fromJson) ?? [];

    return profileRole;
  }
}

export class Role {
  name?: string;
  description?: string;

  static fromJson(data: any): Role {
    const role = new Role();

    role.name = data?.Name;
    role.description = data?.Description;

    return role;
  }
}
