import { Button } from '@campus/components';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TableAppBarActions: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const onCreate = () => navigate({ pathname: 'new', search });

  return <Button onClick={onCreate}>Novo</Button>;
};

export default TableAppBarActions;
