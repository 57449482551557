import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormSelector } from '@campus/components';
import { getClassDisciplineMatrix } from 'services/services';

type DisciplineInputProps = {
  name: string;
  classroomId: string;
};

const DisciplineInput: React.FC<DisciplineInputProps> = ({
  name,
  classroomId
}) => {
  const { setValue } = useFormContext();

  const [disciplines, setDisciplines] = useState([]);

  const prevClass = useRef(classroomId);

  const fetchDisciplines = useCallback(async () => {
    const data = classroomId ? await getClassDisciplineMatrix(classroomId) : [];

    setDisciplines(data);
  }, [classroomId]);

  useEffect(() => {
    fetchDisciplines();
  }, [fetchDisciplines]);

  useEffect(() => {
    if (prevClass.current !== classroomId) {
      prevClass.current = classroomId;
      setValue(name, null);
    }
  }, [classroomId, name, setValue]);

  return (
    <FormSelector
      name={name}
      label="Componente Curricular"
      placeholder="Selecione um componente curricular"
      options={disciplines}
      disabled={!classroomId || disciplines.length === 0}
      isClearable={false}
      grid={{ lg: 6 }}
      required
    />
  );
};

export default DisciplineInput;
