import { CloseButton, Col } from '@campus/components';
import styled from 'styled-components';

export const ContainerButton = styled.div`
padding-top: 20px;
`;

export const CloseAccordion = styled(CloseButton)`
margin-right: 16px;
`;

export const StyledCol = styled(Col)`
align-self: end;
padding: 0px;
margin: 0px;
`;