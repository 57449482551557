import React, { useContext } from 'react';

import useAdminRepository, {
  IUseAdminRepository
} from 'hooks/useAdminRepository';

type AdminContextType = ReturnType<typeof useAdminRepository>;

const AdminContext = React.createContext({} as AdminContextType);

export type AdminProviderProps = IUseAdminRepository & {
  children: (props: AdminContextType) => React.ReactNode;
};

export const useAdminContext = () => useContext(AdminContext);

const AdminProvider: React.FC<AdminProviderProps> = ({
  children,
  onDelete,
  onFetch
}) => {
  const useRepository = useAdminRepository({ onDelete, onFetch });

  return (
    <AdminContext.Provider value={useRepository}>
      {children(useRepository)}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
