import { Grade } from './Grade';
import { Permission } from './Permission';

export class PermissionFeatureGrade {
  grade?: Grade;
  permission?: Permission;

  static fromJson(data: any): PermissionFeatureGrade {
    const permissionFeatureGrade = new PermissionFeatureGrade();

    permissionFeatureGrade.grade = Grade.fromJson(data?.Grade);
    permissionFeatureGrade.permission = Permission.fromJson(data?.Permission);

    return permissionFeatureGrade;
  }
}
