import React from 'react';
import styled from 'styled-components';
import { FileFindIcon } from 'icons/icons';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  max-width: 1000px;
`;

export const ErrorText = styled.div`
  padding-left: 16px;
  color: ${(props) => props.theme.colors.gray1};

  h1 {
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: 20px;
  }

  h2 {
    font-weight: ${(props) => props.theme.weight.semiBold};
    font-size: 16px;
  }
`;

const TableError: React.FC = () => {
  return (
    <Container>
      <FileFindIcon size={50} />
      <ErrorText>
        <h1>Uh-oh! Não encontramos nenhum resultado online</h1>
        <h2>
          Verifique os dados da sua busca, faça uma busca com novas informações!
        </h2>
      </ErrorText>
    </Container>
  );
};

export default TableError;
