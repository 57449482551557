import styled from 'styled-components';
import { DropdownButton } from '@campus/components';

export const ContainerFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

export const DropdownStyled = styled(DropdownButton)`
  button {
    position: relative;
    pointer-events: ${(props) => props.$loading && 'none'};
    border-radius: 6px;
  
    &.btn-primary,
    &.btn-primary:disabled,
    &.btn-primary:focus,
    &.btn-primary:hover,
    &.btn-primary:active {
      color: #fff;
    }
  
    &.btn:active,
    &.btn:focus,
    &.btn:active:focus {
      box-shadow: none;
    }
  
    &.btn-outline-primary:hover,
    &.btn-outline-primary:active {
      color: #fff;
    }
  
    & span {
      color: ${(props) => props.$loading && 'transparent'};
    }
  }
`;
