import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  CampusIcon,
  Checkbox,
  Input,
  Pagination,
  toast
} from '@campus/components';

import {
  getPermissionFeatureGrade,
  setPermissionFeature,
  updatePermissionFeature
} from 'repositories/LiteraryWorkPermissionRepository';
import { PermissionFeatureGrade } from 'models/PermissionFeatureGrade';
import PermissionType from 'utils/PermissionType';

import { usePagination } from '../hooks';

import {
  AvatarAndName,
  DivPage,
  TableStyled,
  PaginationContainer,
  ModalStyled,
  DivActions,
  DivSearch
} from './styles';

type Props = {
  show: boolean;
  setShowModal: (value: boolean) => void;
};

const ModalPermissionGrade: React.FC<Props> = ({ show, setShowModal }) => {
  const [search, setSearch] = useState('');
  const [gradeData, setGradeData] = useState<PermissionFeatureGrade[]>([]);

  const {
    page,
    totalPages,
    loading,
    pageSize,
    goToFirstPage,
    goToNextPage,
    goToLastPage,
    goToPrevPage,
    setPage,
    setLoading,
    setTotalPages
  } = usePagination();

  const loadDataGrade = useCallback(
    async (name?: string) => {
      setLoading(true);
      const response = await getPermissionFeatureGrade(page, pageSize, name);
      setGradeData(response?.data?.items ?? []);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
      setLoading(false);
    },
    [page, pageSize, setLoading, setTotalPages]
  );

  useEffect(() => {
    if (show) {
      loadDataGrade();
    }
  }, [loadDataGrade, show]);

  const handleChangeGradePermission = useCallback(
    async (grade: PermissionFeatureGrade) => {
      if (grade.permission?.id) {
        const resultUpdate = await updatePermissionFeature(grade.permission);

        const updatedItem = resultUpdate.data;

        setGradeData((currentValue) => {
          return currentValue.map((item) => {
            return item.grade.id === updatedItem.gradeId
              ? { ...item, permission: updatedItem }
              : item;
          });
        });
      } else {
        const result = await setPermissionFeature(
          PermissionType.VESTIBULAR_ACCESS,
          !grade.permission.enabled,
          null,
          grade.grade.id
        );

        const createdItem = result.data;

        setGradeData((currentValue) => {
          return currentValue.map((item) => {
            return item.grade.id === createdItem.gradeId
              ? { ...item, permission: createdItem }
              : item;
          });
        });
      }

      toast.success('Permissão alterada com sucesso');
    },
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      loadDataGrade();
    } else {
      loadDataGrade(search);
    }
    setPage(1);
  };

  return (
    <>
      <ModalStyled show={show} onHide={() => setShowModal(false)} size="lg">
        <ModalStyled.Header closeButton>
          <ModalStyled.Title>Gerenciar permissoes de Série</ModalStyled.Title>
        </ModalStyled.Header>
        <ModalStyled.Body>
          {!loading && (
            <>
              <DivSearch>
                <Input
                  placeholder="Pesquisar série"
                  onBlur={handleSearch}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  name="search"
                  StartIcon={<CampusIcon name="search" />}
                />
              </DivSearch>
              <TableStyled>
                <thead>
                  <tr>
                    <th>Gestor</th>
                    <th>Acesso</th>
                  </tr>
                </thead>
                <tbody>
                  {gradeData.map((value) => (
                    <tr key={value.grade.id}>
                      <td>
                        <div>
                          <AvatarAndName>
                            <span>{value.grade.name}</span>
                          </AvatarAndName>
                        </div>
                      </td>
                      <td>
                        <Checkbox
                          checked={value.permission.enabled}
                          name={`permission-${value.grade.id}`}
                          onChange={() => {
                            handleChangeGradePermission(value);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableStyled>
              <PaginationContainer>
                <DivPage>
                  <span>
                    Página{' '}
                    <strong>
                      {page} de {totalPages}
                    </strong>
                  </span>
                </DivPage>
                <Pagination>
                  <Pagination.First
                    onClick={goToFirstPage}
                    disabled={page === 1}
                  />
                  <Pagination.Prev
                    onClick={goToPrevPage}
                    disabled={page === 1}
                  />
                  <Pagination.Next
                    onClick={goToNextPage}
                    disabled={page === totalPages}
                  />
                  <Pagination.Last
                    onClick={goToLastPage}
                    disabled={page === totalPages}
                  />
                </Pagination>
              </PaginationContainer>
            </>
          )}
        </ModalStyled.Body>
        <ModalStyled.Footer>
          <DivActions>
            <Button onClick={() => setShowModal(false)} variant="secondary">
              Fechar
            </Button>
          </DivActions>
        </ModalStyled.Footer>
      </ModalStyled>
    </>
  );
};

export default ModalPermissionGrade;
