import React from 'react';
import { FormInput, Tabs, Tab, Row } from '@campus/components';

import { SearchFormProps } from 'containers/AdminSearchFormPage';
import FormInputImage from 'components/FormInputImage';

import StudentDiscipline from './components/StudentDiscipline/StudentDiscipline';
import StudentResponsibles from './components/StudentResponsibles';

import useSearchForm from 'hooks/useSearchForm';

const requiredFields = ['name', 'email', 'birthday'];
const defaultKey = 'student';

const StudentForm: React.FC<SearchFormProps> = ({ searchData }) => {
  const { key, setKey } = useSearchForm({
    searchData,
    requiredFields,
    defaultKey
  });

  return (
    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
      <Tab eventKey="student" title="Estudante">
        <Row className="gy-2 gx-2">
          <FormInput
            name="name"
            label="Nome"
            required
            placeholder="Insira o nome"
            grid={{ lg: 6 }}
          />

          <FormInput
            name="email"
            label="Email"
            type="email"
            placeholder="Insira o nome"
            required
            grid={{ lg: 6 }}
          />

          <FormInput
            name="birthday"
            type="date"
            required
            label="Data de Nascimento"
            placeholder="Insira a data de nascimento"
            grid={{ lg: 6 }}
          />

          <FormInputImage name="imageUrl" variant="student" />
        </Row>
      </Tab>
      <Tab eventKey="class" title="Turmas">
        <StudentDiscipline />
      </Tab>
      <Tab eventKey="Responsibles" title="Responsáveis">
        <StudentResponsibles />
      </Tab>
    </Tabs>
  );
};

export default StudentForm;
