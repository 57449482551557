import { Response, PaginationModel } from '@campus/commons';
import { Stage, StageFilter, StageInput } from 'models/Stage';
import { AdminRepository } from './interfaces/AdminRepository';

class StageRepository extends AdminRepository<StageFilter, StageInput, Stage> {
  path: string = '/stage';

  async getAll(filter: StageFilter): Promise<Response<PaginationModel<Stage>>> {
    try {
      const { name, page, pageSize } = filter;
      const skipCount = page * pageSize;
      const response = await super.api.get('/stage', {
        params: {
          Name: name,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(Stage, response.data, skipCount);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<Stage>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);

      const data = Stage.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: StageInput): Promise<Response<boolean>> {
    try {
      const { name, endDate, startDate } = input;

      const response = await super.api.post(this.path, {
        Name: name,
        EndDate: endDate,
        StartDate: startDate
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(input: StageInput, id: string): Promise<Response<boolean>> {
    try {
      const { name, endDate, startDate } = input;

      const response = await super.api.put(`${this.path}/${id}`, {
        Name: name,
        EndDate: endDate,
        StartDate: startDate
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}

export default StageRepository;
