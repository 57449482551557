import FormModel from './interfaces/FormModel';
import { Discipline } from './Discipline';
import { EvaluationMatrix } from './EvaluationMatrix';
import { Grade } from './Grade';
import { Period } from './Period';

export type DisciplineMatrixEditInput = {
  id?: string;
  discipline?: Discipline;
  evaluationMatrix?: EvaluationMatrix;
  grade?: Grade;
  period?: Period;
  showPerformance?: boolean;
};

export type DisciplineMatrixEditDTO = {
  Id?: string;
  Discipline?: Discipline;
  EvaluationMatrix?: EvaluationMatrix;
  Grade?: Grade;
  Period?: Period;
  ShowPerformance?: boolean;
};

export class DisciplineMatrixEdit
  implements FormModel<DisciplineMatrixEditInput>
{
  id?: string;
  discipline?: Discipline;
  evaluationMatrix?: EvaluationMatrix;
  grade?: Grade;
  period?: Period;
  showPerformance?: boolean;

  static fromJson(data: any): DisciplineMatrixEdit {
    const disciplineMatrixEdit = new DisciplineMatrixEdit();

    disciplineMatrixEdit.id = data.Id;
    disciplineMatrixEdit.discipline = Discipline.fromJson(data.Discipline);
    disciplineMatrixEdit.evaluationMatrix = EvaluationMatrix.fromJson(
      data.EvaluationMatrix
    );
    disciplineMatrixEdit.grade = Grade.fromJson(data.Grade);
    disciplineMatrixEdit.period = Period.fromJson(data.Period);
    disciplineMatrixEdit.showPerformance = data.ShowPerformance;

    return disciplineMatrixEdit;
  }

    static fromInputToDTO = (input: DisciplineMatrixEdit): DisciplineMatrixEditDTO => {
    return {
      Id: input.id,
      Discipline: input.discipline,
      EvaluationMatrix: input.evaluationMatrix,
      Grade: input.grade,
      Period: input.period,
      ShowPerformance: input.showPerformance
    };
  };

  toInput = (): DisciplineMatrixEditInput => ({
    discipline: this.discipline,
    evaluationMatrix: this.evaluationMatrix,
    grade: this.grade,
    period: this.period,
    showPerformance: this.showPerformance,
    id: this.id
  });
}
