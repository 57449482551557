import React from 'react';
import { FormInput, DataGridProps } from '@campus/components';

import AdminContainer from 'containers/AdminContainer';
import StageRepository from 'repositories/StageRepository';

const StageForm: React.FC = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome"
        required
      />

      <FormInput
        name="startDate"
        type="date"
        label="Data de início"
        placeholder="Insira a data de início"
        required
      />

      <FormInput
        name="endDate"
        type="date"
        label="Data de término"
        placeholder="Insira a data de término"
        required
      />
    </>
  );
};

const StageFilter: React.FC = () => {
  return <FormInput name="name" label="Nome" placeholder="Insira o nome" />;
};

const StagePage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [
      { Header: 'Nome', accessor: 'name' },
      { Header: 'Data de início', accessor: 'startDateFormated' },
      { Header: 'Data de fim', accessor: 'endDateFormated' }
    ],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de etapas"
      columns={columns}
      repository={new StageRepository()}
      components={{
        Form: StageForm,
        Filter: StageFilter
      }}
    />
  );
};

export default StagePage;
