import React from 'react';

import {
  FormInput,
  DataGridProps,
  FormSelectorAsync
} from '@campus/components';

import AdminContainer from 'containers/AdminContainer';
import SegmentRepository from 'repositories/SegmentRepository';
import { getEducationLevels } from 'services/services';

const SegmentForm: React.FC = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome"
        required
      />

      <FormInput
        name="suffix"
        label="Sufixo"
        placeholder="Insira o sufixo"
        required
      />

      <FormSelectorAsync
        name="educationLevel"
        label="Nível de ensino"
        required
        getOptions={(inputValue: string) => getEducationLevels(inputValue)}
      />
    </>
  );
};

const SegmentFilter: React.FC = () => {
  return <FormInput name="name" label="Nome" placeholder="Insira o nome" />;
};

const SegmentPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [
      { Header: 'Nome', accessor: 'name' },
      { Header: 'Sufixo', accessor: 'suffix' }
    ],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de segmentos"
      repository={new SegmentRepository()}
      columns={columns}
      components={{
        Form: SegmentForm,
        Filter: SegmentFilter
      }}
    />
  );
};

export default SegmentPage;
