import styled from 'styled-components';

export const Container = styled.div<{ $width: string; $height: string }>`
  padding: 0.5rem;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border: 1px dashed ${({ theme }) => theme.colors.gray100};

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ImagePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray100};
`;
