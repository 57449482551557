import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

import { User } from './User';
import {
  ClassDisciplinesStudent,
  ClassDisciplinesStudentInput
} from './ClassDisciplinesStudent';
import {
  StudentResponsibles,
  StudentResponsiblesInput
} from './StudentResponsibles';

export interface StudentFilter extends PaginatedFilter {
  name?: string;
  classroom?: { value: string };
  teacher?: { value: string };
  isBirthdayMonth?: boolean;
}

export type StudentInput = {
  userId: string;
  name: string;
  birthday?: string;
  imageUrl?: string;
  email?: string;
  classDiscipline: Array<ClassDisciplinesStudentInput>;
  responsibles: Array<StudentResponsiblesInput>;
};

export class Student implements FormModel<StudentInput> {
  id?: string;
  user?: User;
  birthday?: string;
  birthdayFormated?: string;
  imageUrl?: string;
  tenantId?: string;
  classDiscipline: Array<ClassDisciplinesStudent>;
  responsibles: Array<StudentResponsibles>;

  static fromJson(data: any): Student {
    const student = new Student();

    if (data?.Birthday) {
      const birthdayDate = parseISO(data?.Birthday);
      student.birthday = format(birthdayDate, 'yyyy-MM-dd');
      student.birthdayFormated = format(birthdayDate, 'dd/MM/yyyy');
    }

    student.id = data?.Id;
    student.imageUrl = data?.ImageUrl;
    student.tenantId = data?.TenantId;
    student.user = User.fromJson(data?.User);
    student.classDiscipline = data?.ClassDisciplines?.map((item) =>
      ClassDisciplinesStudent.fromJson(item)
    );
    student.responsibles = data?.Responsibles?.map((item) =>
      StudentResponsibles.fromJson(item)
    );

    return student;
  }

  static fromInputToDTO(input: StudentInput) {
    const { name, birthday, email, imageUrl, classDiscipline, responsibles } =
      input;

    return {
      Birthday: birthday,
      ImageUrl: imageUrl,
      User: {
        Name: name,
        Email: email,
        ImageUrl: imageUrl
      },
      ClassDisciplines: classDiscipline?.map(
        ClassDisciplinesStudent.fromInputToDTO
      ),
      Responsibles: responsibles?.map(StudentResponsibles.fromInputToDTO)
    };
  }

  toInput = (): StudentInput => ({
    userId: this.user.id,
    name: this.user.name,
    email: this.user.email,
    birthday: this.birthday,
    imageUrl: this.imageUrl,
    classDiscipline: this.classDiscipline?.map((item) => item.toInput()),
    responsibles: this.responsibles?.map((item) => item.toInput())
  });
}
