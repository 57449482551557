import React from 'react';
import {
  FormInput,
  FormCheckbox,
  FormSelectorAsync,
} from '@campus/components';

import { getClassroom } from 'services/ClassroomService';
import { getTeachers } from 'services/TeacherService';

const StudentFormFilter: React.FC = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Buscar pelo Nome"
        placeholder="Insira o nome"
        grid={{ lg: 4 }}
      />

      <FormSelectorAsync
        name="classroom"
        label="Buscar por Turma"
        placeholder="Selecione uma Turma"
        grid={{ lg: 4 }}
        getOptions={async (inputValue: string) => getClassroom(inputValue)}
      />

      <FormSelectorAsync
        name="teacher"
        label="Buscar por Professor"
        placeholder="Selecione um Professor"
        grid={{ lg: 4 }}
        getOptions={async (inputValue: string) => getTeachers(inputValue)}
      />

      <FormCheckbox name="isBirthdayMonth" label="Buscar por Aniversariante" />
    </>
  );
};

export default StudentFormFilter;
