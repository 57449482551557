import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import {
  DisciplineMatrixEdit,
  DisciplineMatrixEditInput,
} from 'models/DisciplineMatrixEdit';

export const getDisciplineMatrixEdit = async (
  id: string
): Promise<Response<DisciplineMatrixEdit>> => {
  try {
    const api = Api.INSTANCE.getApi();

    const response = await api.get(`/discipline/disciplinematrix/${id}`, {
      params: { id: id }
    });
    const data = DisciplineMatrixEdit.fromJson(response.data);

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
};

export const updateDisciplineMatrix = async (
  disciplineMatrixEdit: any
): Promise<Response<DisciplineMatrixEditInput>> => {
  try {
    const api = Api.INSTANCE.getApi();

    const response = await api.put(
      `discipline/disciplinematrix/${disciplineMatrixEdit.Id}`,
      {
        Id: disciplineMatrixEdit.Id,
        DisciplineId: disciplineMatrixEdit.Discipline.value,
        EvaluationMatrixId: disciplineMatrixEdit.EvaluationMatrix.value,
        GradeId: disciplineMatrixEdit.Grade.value,
        PeriodId: disciplineMatrixEdit.Period.value,
        ShowPerformance: disciplineMatrixEdit.ShowPerformance
      }
    );

    return Response.fromData(disciplineMatrixEdit, response.status);
  } catch (e) {
    return Response.error(e);
  }
};
