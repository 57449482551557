import styled from 'styled-components';

export const ImageContainer = styled.div<{ $isLargeImage: boolean }>`
  display: flex;
  gap: ${(props) => (props.$isLargeImage ? '5px' : '1rem')};
  flex-direction: ${(props) => (props.$isLargeImage ? 'column' : 'row')};
`;

export const UploadContainer = styled.div<{ $isLargeImage: boolean }>`
  flex: 1;
  align-self: center;
  padding: ${(props) => (props.$isLargeImage ? '0' : '1rem 0')};
  ${(props) => (props.$isLargeImage ? 'margin-bottom: 15px;' : '')}

  P {
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.gray400};
    ${(props) => (props.$isLargeImage ? 'margin-bottom: 5px;' : '')}
  }
`;

export const Placeholder = styled.div`
  color: #828282;
  font-size: 14px;
  line-height: 120%;
  font-weight: 600;
`;
