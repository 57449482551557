import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Table } from '@campus/components';

import { DisciplineMatrixStage } from 'models/DisciplineStage'
import AdminActionsForm from 'components/AdminActionsForm';
import DisciplineMatrixStageRepository from 'repositories/DisciplineMatrixStageRepository';

export const inputName = 'stages';

const EvaluationStageForm: React.FC = () => {
  const onLoadFormData = async (id) => {
    const repository = new DisciplineMatrixStageRepository();
    const response = await repository.getByDisciplineMatrixID(id);

    if (response.data) {
      return response?.data;
    }
  };

  const onSubmit = async (id: Array<string>, data: any) => {
    const repository = new DisciplineMatrixStageRepository();
    const input = DisciplineMatrixStage.fromInputToDTO(data);
    input.Ids = id;
    const response = await repository.updateBatch(input);
    return response;
  };

  return (
    <AdminActionsForm
      Form={EvaluationStageTable}
      onLoadFormData={onLoadFormData}
      onSubmit={onSubmit}
    />
  );
};

const EvaluationStageTable: React.FC = () => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: inputName
  });

  return (
    <Table>
      <thead>
        <tr>
          <th>Etapa</th>
          <th>Máx. Pontos</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((f, i) => {
          const fieldName = `${inputName}.${i}`;

          return <EvaluationInput key={f.id} fieldName={fieldName} />;
        })}
      </tbody>
    </Table>
  );
};

const EvaluationInput = ({ fieldName }) => {
  const { control, register } = useFormContext();

  const stage = useWatch({ name: fieldName, control });

  const parseWeight = (value: string) => {
    const weight = parseInt(value);

    return weight && weight >= 0 ? weight : null;
  };

  return (
    <tr>
      <td>{stage?.name ?? 'Todas'}</td>

      <td>
        <input
          type="number"
          {...register(`${fieldName}.maxWeight`, {
            setValueAs: parseWeight
          })}
        />
      </td>
    </tr>
  );
};

export default EvaluationStageForm;
