import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

export interface EducationLevelFilter extends PaginatedFilter {
  name?: string;
}

export type EducationLevelInput = {
  name: string;
};

export class EducationLevel implements FormModel<EducationLevelInput> {
  id?: string;
  name?: string;

  static fromJson(data: any): EducationLevel {
    const educationLevel = new EducationLevel();

    educationLevel.id = data?.Id;
    educationLevel.name = data?.Name;

    return educationLevel;
  }

  toInput = (): EducationLevelInput => ({
    name: this.name
  });
}
