import FormModel from './interfaces/FormModel';
import { SelectOption } from '@campus/components';
import { parseAffectIndex } from 'utils/AffectIndexOptions';
import {
  EvaluationTypeDescription,
} from './EvaluationTypeDescription';

export enum AffectIndex {
  OPTIONAL = 'Optional',
  NO = 'No',
  YES = 'Yes'
}

export type EvaluationTypeInput = {
  id: string;
  type: SelectOption;
  affectIndex: SelectOption;
  performanceDescription: string;
  performanceEvaluable: boolean;
  engagementDescription: string;
  engagementEvaluable: boolean;
};

export type EvaluationTypeDTO = {
  Id: string;
  EvaluationTypeId: string;
  AffectIndex: string;
  PerformanceDescription: string;
  PerformanceEvaluable: boolean;
  EngagementDescription: string;
  EngagementEvaluable: boolean;
};

export class EvaluationType implements FormModel<EvaluationTypeInput> {
  id?: string;

  type?: EvaluationTypeDescription;

  affectIndex?: AffectIndex;

  performanceDescription?: string;

  performanceEvaluable?: boolean;

  engagementDescription?: string;

  engagementEvaluable?: boolean;

  static fromJson(data: any): EvaluationType {
    const evaluationType = new EvaluationType();

    evaluationType.id = data?.Id;
    evaluationType.affectIndex = data?.AffectIndex;
    evaluationType.performanceDescription = data?.PerformanceDescription;
    evaluationType.performanceEvaluable = data?.PerformanceEvaluable;
    evaluationType.engagementDescription = data?.EngagementDescription;
    evaluationType.engagementEvaluable = data?.EngagementEvaluable;

    evaluationType.type = EvaluationTypeDescription.fromJson(
      data?.EvaluationType
    );



    return evaluationType;
  }

  static fromInputToDTO = (input: EvaluationTypeInput): EvaluationTypeDTO => ({
    Id: input.id,
    AffectIndex: input.affectIndex?.value as string,
    PerformanceDescription: input.performanceDescription,
    PerformanceEvaluable: input.performanceEvaluable,
    EngagementDescription: input.engagementDescription,
    EngagementEvaluable: input.engagementEvaluable,
    EvaluationTypeId: input.type?.value as string,
  });

  toInput = (): EvaluationTypeInput => ({
    id: this.id,
    type: this.type.toSelectOption(),
    affectIndex: parseAffectIndex(this.affectIndex),
    engagementDescription: this.engagementDescription,
    engagementEvaluable: this.engagementEvaluable,
    performanceDescription: this.performanceDescription,
    performanceEvaluable: this.performanceEvaluable,
  });
}
