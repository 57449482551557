import React from 'react';
import { FormProps } from 'components/AdminForm';
import { AdminRepository } from 'repositories/interfaces';

import AdminForm from 'components/AdminForm';
import Drawer from 'components/Drawer';

import { FormSearchProps } from '../../types';

export type FormPageProps = {
  isEdit?: boolean;
  repository: AdminRepository;
  Form: React.ComponentType<FormProps>;
  EditForm: React.ComponentType<FormSearchProps>;
};


const FormPage: React.FC<FormPageProps> = ({
  isEdit,
  repository,
  Form,
  EditForm
}) => {
  if (isEdit && !!EditForm) {
    return <EditForm repository={repository} />;
  }

  return (
    <Drawer title={isEdit ? 'Editar' : 'Criar'}>
      <AdminForm repository={repository} Form={Form} />
    </Drawer>
  );
};

export default FormPage;
