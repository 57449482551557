import React from 'react';
import { Placeholder } from '@campus/components';
import { ListItem, TreeList } from '../styles';

const ListPlaceholder: React.FC = () => {
  return (
    <Placeholder animation="glow">
      <TreeList>
        <ListItem>
          <Placeholder xs={12} />
        </ListItem>
        <ListItem>
          <Placeholder xs={12} />
        </ListItem>
        <ListItem>
          <Placeholder xs={9} />
        </ListItem>
        <ListItem>
          <Placeholder xs={9} />
        </ListItem>
        <ListItem>
          <Placeholder xs={6} />
        </ListItem>
      </TreeList>
    </Placeholder>
  );
};

export default ListPlaceholder;
