import { Button } from '@campus/components';
import styled from 'styled-components';

export const Separator = styled.hr`
  height: 1px;
  background-color: ${(props) => props.theme.colors.gray5};
  margin: 1rem 0;
`;

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  gap: 1rem;
  width: 100%;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ItemContainer = styled.div`
  flex: 1;
`;

export const Box = styled.ul`
  flex: 1;
  list-style-type: none;
  padding: 0.5rem;
  overflow-y: auto;
  max-height: 210px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  box-shadow: 0px 2px 5px -2px #0000001a;
  border-radius: 4px;
`;

export const BoxItem = styled(Button)`
  width: 100%;
  padding: 0.5rem;
  margin: 0.1rem 0px;
  border: none;
  text-align: start;
`;

export const ClassroomTitle = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 0.5rem;

  p {
    color: ${(props) => props.theme.colors.blue1};
  }

  * {
    margin: 0;
  }
`;

export const ClassroomItem = styled.li`
  padding: 0.5rem;
  margin: 0.1rem 0px;
  color: ${(props) => props.theme.colors.gray1};
  list-style: inside;
`;
