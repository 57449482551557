import React from 'react';

import { FormInput, DataGridProps } from '@campus/components';

import AdminContainer from 'containers/AdminContainer';
import ClassificationModal from 'containers/ClassificationModal';

import ClassificationTypeRepository from 'repositories/ClassificationTypeRepository';

const ClassificationTypeForm: React.FC = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome"
        required
      />
      <FormInput
        name="description"
        label="Descrição"
        placeholder="Insira a descrição"
        required
      />
    </>
  );
};

const ClassificationTypeFilter: React.FC = () => {
  return (
    <FormInput
      name="name"
      label="Buscar pelo Nome"
      placeholder="Insira o nome"
    />
  );
};

const ClassificationTypePage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [
      { Header: 'Nome', accessor: 'name' },
      { Header: 'Descrição', accessor: 'description' }
    ],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de classificação dos objetivos de aprendizagem"
      columns={columns}
      repository={new ClassificationTypeRepository()}
      components={{
        Form: ClassificationTypeForm,
        EditForm: ClassificationModal,
        Filter: ClassificationTypeFilter
      }}
    />
  );
};

export default ClassificationTypePage;
