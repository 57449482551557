import {
  Button,
  FormInput,
  FormSelectorAsync,
  Modal,
  Row
} from '@campus/components';
import { ClassDisciplinesStudentInput } from 'models/ClassDisciplinesStudent';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getClassDisciplineMatrix, getClassroom } from 'services/services';

type DisciplineModalProps = {
  onAdd: (disciplines: ClassDisciplinesStudentInput[]) => void;
};

const DisciplineModal: React.FC<DisciplineModalProps> = ({ onAdd }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button variant="link" onClick={() => setShow(true)}>
        Adicionar Componentes Curriculares
      </Button>
      {show && (
        <DisciplineModalForm onAdd={onAdd} onHide={() => setShow(false)} />
      )}
    </>
  );
};

const DisciplineModalForm = ({ onAdd, onHide }) => {
  const form = useForm<ClassDisciplinesStudentInput>({
    reValidateMode: 'onChange'
  });
  const { handleSubmit } = form;

  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    const classId = data.classroom.value as string;
    const disciplinesMatrix = await getClassDisciplineMatrix(classId);

    const disciplines = disciplinesMatrix.map((d) => ({
      ...data,
      disciplineMatrix: d
    }));

    onAdd(disciplines);

    onHide();
    setLoading(false);
  });

  return (
    <FormProvider {...form}>
      <Modal show onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Componentes Curriculares</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="gy-2 gx-2 align-items-end">
            <FormSelectorAsync
              name="classroom"
              label="Classe"
              getOptions={(inputValue: string) => getClassroom(inputValue)}
              required
              grid={{ xs: 12 }}
            />

            <FormInput
              name="startDate"
              type="date"
              label="Data de Inicio"
              grid={{ xs: 6 }}
            />

            <FormInput
              name="endDate"
              type="date"
              label="Data de Termino"
              grid={{ xs: 6 }}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button loading={loading} onClick={onSubmit}>
            Adicionar
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
};

export default DisciplineModal;
