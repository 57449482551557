import React from 'react';

import { FormInput, DataGridProps } from '@campus/components';

import { FormProps } from 'components/AdminForm';
import AdminContainer from 'containers/AdminContainer';
import ProfileRepository from 'repositories/ProfileRepository';
import UserSelect from './components/UserSelect';
import RolesInput from './components/RolesInput';

const ProfileForm: React.FC<FormProps> = ({ data }) => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome"
        required
        grid={{ xs: 12 }}
      />

      <RolesInput />

      {data?.id && <UserSelect id={data?.id} />}
    </>
  );
};

const ProfileFilter: React.FC = () => {
  return <FormInput name="name" label="Nome" placeholder="Insira o nome" />;
};

const ProfilePage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [{ Header: 'Name', accessor: 'name' }],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de perfis"
      columns={columns}
      repository={new ProfileRepository()}
      components={{
        Form: ProfileForm,
        Filter: ProfileFilter
      }}
    />
  );
};

export default ProfilePage;
