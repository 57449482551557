import React from 'react';
import { FormInput, DataGridProps } from '@campus/components';

import AdminContainer, { FormSearchProps } from 'containers/AdminContainer';
import ManagerRepository from 'repositories/ManagerRepository';

import ManagerForm from './components/ManagerForm';
import AdminSearchFormPage from 'containers/AdminSearchFormPage';

const ManagerFilter: React.FC = () => {
  return <FormInput name="name" label="Nome" placeholder="Insira o nome" />;
};

const ManagerFormPage: React.FC<FormSearchProps> = ({ repository }) => {
  return <AdminSearchFormPage repository={repository} Form={ManagerForm} />;
};

const ManagerPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [
      { Header: 'Nome', accessor: 'user.name' },
      { Header: 'Email', accessor: 'user.email' },
      { Header: 'Data de Nascimento', accessor: 'birthdayFormated' }
    ],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de gestores"
      repository={new ManagerRepository()}
      columns={columns}
      components={{
        Filter: ManagerFilter,
        FormPage: ManagerFormPage
      }}
    />
  );
};

export default ManagerPage;
