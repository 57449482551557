import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CloseButton } from '@campus/components';
import { Header, StyledDrawer, TitleContainer } from './styled';

type DrawerProps = {
  title: string;
  onClose?: () => void;
  subTitle?: string;
};

const Drawer: React.FC<DrawerProps> = ({
  title,
  children,
  onClose,
  subTitle
}) => {
  const navigate = useNavigate();

  if (!onClose) {
    onClose = () => navigate(-1);
  }

  return (
    <StyledDrawer>
      <Header>
        <TitleContainer>
          <h1>{title}</h1>
          {!!subTitle && <h6>{subTitle}</h6>}
        </TitleContainer>
        <CloseButton onClick={onClose} />
      </Header>
      {children}
    </StyledDrawer>
  );
};

export default Drawer;
