import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { SelectOption } from '@campus/components';

import FormModel from './interfaces/FormModel';
import PaginatedFilter from './interfaces/PaginatedFilter';

import { User } from './User';
import { Classes } from './Classes';

export interface ManagerFilter extends PaginatedFilter {
  name?: string;
}

export type ManagerInput = {
  userId: string;
  name: string;
  birthday?: string;
  imageUrl?: string;
  email?: string;
  classRoom: Array<SelectOption>;
};

export class Manager implements FormModel<ManagerInput> {
  id?: string;

  user?: User;

  birthday?: string;

  birthdayFormated?: string;

  imageUrl?: string;

  tenantId?: string;

  classRoom: Array<SelectOption>;

  classes: Array<Classes>;

  static fromJson(data: any): Manager {
    const manager = new Manager();

    if (data?.Birthday) {
      const birthdayDate = parseISO(data?.Birthday);
      manager.birthday = format(birthdayDate, 'yyyy-MM-dd');
      manager.birthdayFormated = format(birthdayDate, 'dd/MM/yyyy');
    }

    manager.id = data?.Id;
    manager.user = User.fromJson(data?.User);
    manager.imageUrl = data?.ImageUrl;
    manager.tenantId = data?.TenantId;
    manager.classRoom =
      data?.Classes?.map((item) => ({
        value: item.Id,
        label: item.Name
      })) || [];

    manager.classes = data?.Classes;

    return manager;
  }

  toInput = (): ManagerInput => ({
    userId: this.user.id,
    name: this.user.name,
    email: this.user.email,
    birthday: this.birthday,
    imageUrl: this.imageUrl,
    classRoom: this.classRoom.map((item) => ({
      label: item.label,
      value: item.value
    }))
  });
}
