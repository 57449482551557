import React, { useState } from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useFormState
} from 'react-hook-form';

import {
  Button,
  Col,
  FormCheckbox,
  FormSelectorAsync,
  Modal,
  Row,
  toast
} from '@campus/components';

import {
  createDisciplineMatrix,
  getEvaluationMatrices,
  getDisciplines,
  getGrades,
  getPeriods
} from 'services/services';
import {
  DisciplineMatrix,
  DisciplineMatrixInput
} from 'models/DisciplineMatrix';

type DisciplineCreationProps = {
  values: any;
  onCreate: (input: DisciplineMatrix) => void;
};

const DisciplineCreation: React.FC<DisciplineCreationProps> = ({
  onCreate,
  values
}) => {
  const { control } = useFormContext();
  const state = useFormState({ control });

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await createDisciplineMatrix(data);
    setLoading(false);

    if (res.data) {
      onCreate(res.data);
    } else if (res.error) {
      toast.error(res.error.message);
    }
  };

  return (
    <>
      <Col>
        <Button
          disabled={!state.isDirty}
          loading={loading}
          onClick={() => setShow(true)}
        >
          Criar Novo
        </Button>
      </Col>
      {show && (
        <DisciplineCreationForm
          values={values}
          onSubmit={onSubmit}
          loading={loading}
          onCancel={() => setShow(false)}
        />
      )}
    </>
  );
};

const DisciplineCreationForm = ({ values, loading, onSubmit, onCancel }) => {
  const form = useForm<DisciplineMatrixInput>({
    defaultValues: values,
    reValidateMode: 'onChange'
  });
  const { handleSubmit, control } = form;
  const state = useFormState({ control });

  const submit = handleSubmit(onSubmit);

  return (
    <FormProvider {...form}>
      <Modal size="lg" show onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Criar componente curricular</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row
            style={{ marginBottom: 85 }}
            className="gy-2 gx-2 align-items-end"
          >
            <FormSelectorAsync
              name="discipline"
              label="Componente Curricular"
              placeholder="Selecione um componente curricular"
              required
              grid={{ xs: 4 }}
              getOptions={(v: string) => getDisciplines(v)}
            />
            <FormSelectorAsync
              name="period"
              label="Período Letivo"
              placeholder="Selecione um período letivo"
              required
              grid={{ xs: 4 }}
              isClearable
              getOptions={(v: string) => getPeriods(v)}
            />
            <FormSelectorAsync
              name="grade"
              label="Série"
              placeholder="Selecione uma série"
              grid={{ xs: 4 }}
              isClearable
              getOptions={(v: string) => getGrades(v)}
            />
            <FormSelectorAsync
              name="evaluationMatrix"
              label="Matriz de avaliação"
              placeholder="Selecione uma matriz"
              grid={{ xs: 4 }}
              isClearable
              required
              getOptions={(v: string) => getEvaluationMatrices(v)}
            />
            <FormCheckbox
              name="showPerformance"
              label="Exibir performance"
              grid={{ xs: 4 }}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!state.isDirty} loading={loading} onClick={submit}>
            Criar Novo
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
};

export default DisciplineCreation;
