import React from 'react';

import { AdminRepository } from 'repositories/interfaces';

import AdminForm from 'components/AdminForm';
import Drawer from 'components/Drawer';

import { AdminComponents } from '../types';

export type FormPageProps = {
  isEdit?: boolean;
  repository: AdminRepository;
  components: AdminComponents;
};

const FormPage: React.FC<FormPageProps> = ({
  isEdit,
  repository,
  components
}) => {
  const FormPage = components.FormPage;

  if (!!FormPage) {
    return <FormPage repository={repository} />;
  }

  const Form = components.Form;
  const EditForm = components.EditForm;

  if (isEdit && !!EditForm) {
    return <EditForm repository={repository} />;
  }

  return (
    <Drawer title={isEdit ? 'Editar' : 'Criar'}>
      <AdminForm repository={repository} Form={Form} />
    </Drawer>
  );
};

export default FormPage;
