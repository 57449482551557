import React, { useContext, useEffect, useMemo } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { Pencil } from '@campus/components/icons';
import styled from 'styled-components';
import {
  Row,
  CollapserItem,
  CollapserContext,
  CloseButton,
  Button
} from '@campus/components';

import TeacherInput from './TeacherInput';
import DisciplineMatrixSelect from './DisciplineMatrixSelect';
import { DisciplineMatrixSelectProps } from './DisciplineMatrixSelect/DisciplineMatrixSelect';

const CloseAccordion = styled(CloseButton)`
  margin-right: 16px;
`;

type ClassDisciplineItemProps = {
  index: number;
  remove: () => void;
  onSelect: DisciplineMatrixSelectProps['onSelect'];
};

export const inputName = 'classDisciplines';

const ClassDisciplineItem: React.FC<ClassDisciplineItemProps> = ({
  index,
  remove,
  onSelect
}) => {
  const fieldName = `${inputName}.${index}`;

  const { control } = useFormContext();
  const disciplineMatrix = useWatch({
    control,
    name: `${fieldName}.disciplineMatrix`
  });
  const { isSubmitting, errors } = useFormState({ control });

  const { forceOpened } = useContext(CollapserContext);

  useEffect(() => {
    if (isSubmitting) {
      const classDisciplines = errors?.classDisciplines;

      if (classDisciplines instanceof Array) {
        const i = classDisciplines.findIndex((e) => e);
        forceOpened(i);
      }
    }
  }, [isSubmitting, errors, forceOpened]);

  const title = useMemo(() => {
    if (!disciplineMatrix) {
      return `Componente Curricular ${index + 1}`;
    }

    return disciplineMatrix?.discipline?.label;
  }, [disciplineMatrix, index]);

  return (
    <CollapserItem
      eventKey={index}
      header={
        <>
          <CloseAccordion onClick={remove} />
          {title}
          <DisciplineMatrixSelect
            onSelect={onSelect}
            renderButton={(onClick) => (
              <Button variant="link" onClick={onClick}>
                <Pencil size={16} color="black" />
              </Button>
            )}
          />
        </>
      }
    >
      <Row className="gy-2 gx-2">
        <TeacherInput name={fieldName} />
      </Row>
    </CollapserItem>
  );
};

export default ClassDisciplineItem;
