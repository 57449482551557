import React from 'react';

import routes from 'core/routes';

import {
  Header,
  Title,
  Container,
  CardText,
  CardLink,
  IconContainer
} from './styles';

const Home: React.FC = () => {
  return (
    <>
      <Header>
        <Title>Home</Title>
      </Header>
      <Container>
        {routes.map((route) => {
          if (route.path === '') {
            return null;
          }
          return (
            <CardLink key={route.path} to={route.path}>
              <CardText>{route.name}</CardText>
              <IconContainer>
                <route.Icon color="#fff" size={50} />
              </IconContainer>
              <CardText>{route.description}</CardText>
            </CardLink>
          );
        })}
      </Container>
    </>
  );
};

export default Home;
