import React from 'react';

import { DataGridProps } from '@campus/components';

import AdminContainer from 'containers/AdminContainer';
import UnitRepository from 'repositories/UnitRepository';

import UnitForm from './components/UnitForm';
import UnitFilter from './components/UnitFilter';

const UnitPage: React.FC = () => {
  const columns = React.useMemo<DataGridProps['columns']>(
    () => [{ Header: 'Name', accessor: 'name' }],
    []
  );

  return (
    <AdminContainer
      title="Cadastro de unidades"
      columns={columns}
      repository={new UnitRepository()}
      components={{
        Form: UnitForm,
        Filter: UnitFilter
      }}
    />
  );
};

export default UnitPage;
