import { SelectOption } from '@campus/components';

import FormModel from './interfaces/FormModel';

import { DisciplineMatrix, DisciplineMatrixInput } from './DisciplineMatrix';
import { User } from './User';

export type ClassDisciplinesInput = {
  disciplineMatrix: DisciplineMatrixInput;
  teachers: Array<SelectOption>;
};

export type ClassDisciplinesDTO = {
  DisciplineMatrixId: string;
  Teachers: Array<{ TeacherId: string }>;
};

export class ClassDisciplines implements FormModel<ClassDisciplinesInput> {
  disciplineMatrix?: DisciplineMatrix;

  teachers?: Array<User>;

  static fromJson(data: any): ClassDisciplines {
    const classDisciplines = new ClassDisciplines();

    classDisciplines.disciplineMatrix = DisciplineMatrix.fromJson(
      data?.DisciplineMatrix
    );

    classDisciplines.teachers = data?.Teachers?.map(User.fromJson) ?? [];

    return classDisciplines;
  }

  static fromInputToDTO(input: ClassDisciplinesInput): ClassDisciplinesDTO {
    return {
      DisciplineMatrixId: input.disciplineMatrix?.id,
      Teachers: input.teachers?.map((t) => ({
        TeacherId: t?.value?.toString()
      }))
    };
  }

  toInput = (): ClassDisciplinesInput => ({
    disciplineMatrix: this.disciplineMatrix.toInput(),
    teachers: this.teachers.map((t) => ({
      value: t.id,
      label: t.name
    }))
  });
}
