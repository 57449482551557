import { Response, PaginationModel, User } from '@campus/commons';
import { Teacher, TeacherFilter, TeacherInput } from 'models/Teacher';
import { AdminRepository } from './interfaces/AdminRepository';

class TeacherRepository extends AdminRepository<
  TeacherFilter,
  TeacherInput,
  Teacher
> {
  path: string = '/teacher';
  isSearch: boolean = true;

  async getAll(
    filter: TeacherFilter
  ): Promise<Response<PaginationModel<Teacher>>> {
    try {
      const { classroom, discipline, name, page, pageSize } = filter;
      const skipCount = page * pageSize;
      const response = await super.api.get('/teacher', {
        params: {
          Name: name,
          ClassId: classroom?.value,
          DisciplineId: discipline?.value,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(Teacher, response.data, skipCount);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<Teacher>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);

      const data = Teacher.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: TeacherInput): Promise<Response<boolean>> {
    try {
      const { name, birthday, email, imageUrl } = input;

      const response = await super.api.post(this.path, {
        Birthday: birthday,
        ImageUrl: imageUrl,
        User: {
          Name: name,
          Email: email
        }
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(input: TeacherInput, id: string): Promise<Response<boolean>> {
    try {
      const { userId, name, birthday, email, imageUrl } = input;

      const response = await super.api.put(`${this.path}/${id}`, {
        Birthday: birthday,
        ImageUrl: imageUrl,
        User: {
          Id: userId,
          Name: name,
          Email: email
        }
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async searchByKey(user: User): Promise<Response<User>> {
    try {
      const response = await super.api.get(`${this.path}/user`, {
        params: { email: user.email }
      });

      const data = User.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}

export default TeacherRepository;
