import React from 'react';

import { Link } from 'react-router-dom';
import { SideMenu } from '@campus/components';

import MenuHeader from './components/MenuHeader';

import routes from 'core/routes';

const Menu: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => {
  return (
    <SideMenu {...props}>
      <MenuHeader />

      <Link to="">
        <SideMenu.Option>Página inicial</SideMenu.Option>
      </Link>

      {routes.map((route) => (
        <Link key={route.path} to={route.path}>
          <SideMenu.Option>{route.name}</SideMenu.Option>
        </Link>
      ))}
    </SideMenu>
  );
};

export default Menu;
