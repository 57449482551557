import { Response, PaginationModel } from '@campus/commons';
import { Unit, UnitFilter, UnitInput } from 'models/Unit';
import { AdminRepository } from './interfaces/AdminRepository';

class UnitRepository extends AdminRepository<UnitFilter, UnitInput, Unit> {
  path: string = '/unit';

  async getAll(filter: UnitFilter): Promise<Response<PaginationModel<Unit>>> {
    try {
      const { name, page, pageSize } = filter;
      const skipCount = page * pageSize;
      const response = await super.api.get('/unit', {
        params: {
          Name: name,
          SkipCount: skipCount,
          MaxResultCount: pageSize
        }
      });

      const data = PaginationModel.fromJson(Unit, response.data, skipCount);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async get(id: string): Promise<Response<Unit>> {
    try {
      const response = await super.api.get(`${this.path}/${id}`);

      const data = Unit.fromJson(response.data);

      return Response.fromData(data, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async create(input: UnitInput): Promise<Response<boolean>> {
    try {
      const {
        name,
        imageUrl,
        bannerImageUrl,
        bannerImageMobileUrl,
        bannerRedirectUrl
      } = input;

      const response = await super.api.post(this.path, {
        Name: name,
        ImageUrl: imageUrl,
        BannerImageUrl: bannerImageUrl,
        BannerImageMobileUrl: bannerImageMobileUrl,
        BannerRedirectUrl: bannerRedirectUrl
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async update(input: UnitInput, id: string): Promise<Response<boolean>> {
    try {
      const {
        name,
        imageUrl,
        bannerImageUrl,
        bannerImageMobileUrl,
        bannerRedirectUrl
      } = input;

      const response = await super.api.put(`${this.path}/${id}`, {
        Name: name,
        ImageUrl: imageUrl,
        BannerImageUrl: bannerImageUrl,
        BannerImageMobileUrl: bannerImageMobileUrl,
        BannerRedirectUrl:
          bannerRedirectUrl?.length > 0 ? bannerRedirectUrl : null
      });

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }

  async delete(id: string): Promise<Response<boolean>> {
    try {
      const response = await super.api.delete(`${this.path}/${id}`);

      return Response.fromData(true, response.status);
    } catch (e) {
      return super.handleError(e);
    }
  }
}

export default UnitRepository;
