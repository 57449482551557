import React, { useState } from 'react';

import { Button, Col, Modal } from '@campus/components';
import UserList from './components/UserList';

type UserSelectProps = {
  id: string;
};

const UserSelect: React.FC<UserSelectProps> = ({ id }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Col xs={12}>
        <Button onClick={() => setShow(true)}>Configurar usuários</Button>
      </Col>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Configurar usuários</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserList id={id} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserSelect;
