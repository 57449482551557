import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Col, Row, Button, Collapser } from '@campus/components';

import StudentResponsiblesItem, { inputName } from './StudentResponsiblesItem';
import { ContainerButton } from '../styles';

const defaultValue = {
  imageUrl: null,
  name: null,
  phoneNumbers: null
};

const StudentResponsibles: React.FC = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: inputName
  });

  return (
    <Col xs={12}>
      <Collapser>
        {fields.map((f, i) => (
          <StudentResponsiblesItem
            key={f.id}
            index={i}
            onRemove={() => remove(i)}
          />
        ))}
      </Collapser>
      <Row className="gy-2 gx-2">
        <ContainerButton>
          <Button variant="link" onClick={() => append(defaultValue)}>
            Adicionar Responsável
          </Button>
        </ContainerButton>
      </Row>
    </Col>
  );
};

export default StudentResponsibles;
