export class Permission {
  id?: string;

  featureId?: string;

  classId?: string;

  gradeId?: string;

  userId?: string;

  unitId?: string;

  userAccessType?: string;

  enabled?: boolean;

  static fromJson(data: any): Permission {
    const permission = new Permission();

    permission.id = data?.Id;

    permission.featureId = data?.FeatureId;

    permission.classId = data?.ClassId;

    permission.gradeId = data?.GradeId;

    permission.userId = data?.UserId;

    permission.unitId = data?.UnitId;

    permission.userAccessType = data?.UserAccessType;

    permission.enabled = data?.Enabled;

    return permission;
  }
}
