import React, { useState } from 'react';

import { CampusIcon } from '@campus/components';
import { Pencil, Trash } from '@campus/components/icons';

import { Classification } from 'models/Classification';

import ListPlaceholder from './components/ListPlaceholder';

import {
  ActionContainer,
  ListItem,
  ListElement,
  TreeList,
  ListButton,
  AddButton,
  ArrowIcon
} from './styles';
import { useTreeView } from './hooks/useTreeView';

export const inputName = 'classifications';

type ClassificationTreeViewProps = {
  typeId: string;
  parent?: string;
};

const ClassificationTreeView: React.FC<ClassificationTreeViewProps> = ({
  typeId,
  parent
}) => {
  const { classifications, loading, onDelete, onEdit, onAdd } = useTreeView(
    typeId,
    parent
  );

  if (loading) {
    return <ListPlaceholder />;
  }

  return (
    <TreeList>
      {classifications.map((c) => (
        <ItemView
          key={c.id}
          item={c}
          typeId={typeId}
          onDelete={() => onDelete(c.id)}
          onEdit={() => onEdit(c.toInput())}
        />
      ))}
      <AddButton variant="link" onClick={onAdd}>
        <CampusIcon name="squarePlus" size={16} />
        Adicionar
      </AddButton>
    </TreeList>
  );
};

type ItemViewProps = {
  typeId: string;
  item: Classification;
  onEdit: () => void;
  onDelete: () => void;
};

const ItemView: React.FC<ItemViewProps> = ({
  item,
  typeId,
  onDelete,
  onEdit
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <ListItem>
      <ListElement>
        <ListButton role="button" onClick={() => setExpanded((e) => !e)}>
          <ArrowIcon $expanded={expanded} name="miniArrowRight" size={8} />
          <p>{item.description}</p>
        </ListButton>
        <ActionContainer>
          <Pencil size={16} onClick={onEdit} />
          <Trash size={16} onClick={onDelete} />
        </ActionContainer>
      </ListElement>
      {expanded && <ClassificationTreeView typeId={typeId} parent={item.id} />}
    </ListItem>
  );
};

export default ClassificationTreeView;
