import styled from 'styled-components';

export const Title = styled.h2`
  color: ${(props) => props.theme.colors.gray2};
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 22px;
  line-height: 120%;
  display: inline;
  margin-right: 6px;
`;
