import { Button, CampusIcon } from '@campus/components';
import styled from 'styled-components';

export const TreeList = styled.ul`
  list-style: none;
  padding: 0px;

  ul {
    padding-left: 1.5rem;
  }
`;

export const ListItem = styled.li`
  margin-bottom: 4px;
`;

export const ListElement = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;

  p {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }
`;

export const ListButton = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: center;

  p {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }
`;

export const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;

  svg {
    cursor: pointer;

    :hover {
      color: ${({ theme }) => theme.colors.blue1};
    }
  }
`;

export const AddButton = styled(Button)`
  span {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
    align-items: center;
  }
`;

export const ArrowIcon = styled(CampusIcon)<{ $expanded: boolean }>`
  transition: all 0.3s ease-out;
  transform: ${({ $expanded }) => ($expanded ? `rotate(90deg)` : '')};
`;
