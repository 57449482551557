import { useCallback, useEffect, useState } from 'react';

import { Classification, ClassificationInput } from 'models/Classification';
import { useClassification } from '../../../providers';

import ClassificationRepository from 'repositories/ClassificationRepository';

const classificationRepository = new ClassificationRepository();

export const useTreeView = (typeId: string, parent?: string) => {
  const { onSelect, onDelete } = useClassification();
  const [loading, setLoading] = useState(true);
  const [classifications, setClassifications] = useState<Classification[]>([]);

  const loadData = useCallback(async () => {
    setLoading(true);
    const response = await classificationRepository.getAll(typeId, parent);

    if (!!response.data) {
      setClassifications(response.data);
    }
    setLoading(false);
  }, [typeId, parent]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleDelete = async (id: string) => {
    setLoading(true);
    await onDelete(id, loadData);
    setLoading(false);
  };

  const handleEdit = (input: ClassificationInput) => {
    onSelect(input, loadData);
  };

  const handleAdd = () => {
    const newClassification = new Classification();
    onSelect(newClassification.toInput(), loadData);
  };

  return {
    loading,
    classifications,
    onDelete: handleDelete,
    onEdit: handleEdit,
    onAdd: handleAdd
  };
};
