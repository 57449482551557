import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import FormModel from './interfaces/FormModel';

export type StudentResponsiblesInput = {
  id: string;
  imageUrl: string;
  name: string;
  email: string;
  phoneNumbers: Array<string>;
  birthDate?: string;
};

export class StudentResponsibles
  implements FormModel<StudentResponsiblesInput>
{
  id: string;
  imageUrl: string;
  name: string;
  email: string;
  birthDate?: string;
  birthDateFormated?: string;
  phoneNumbers: Array<string>;

  static fromJson(data: any): StudentResponsibles {
    const responsibles = new StudentResponsibles();

    if (data?.Birthdate) {
      const birthdayDate = parseISO(data?.Birthdate);
      responsibles.birthDate = format(birthdayDate, 'yyyy-MM-dd');
      responsibles.birthDateFormated = format(birthdayDate, 'dd/MM/yyyy');
    }

    responsibles.id = data?.Id;
    responsibles.imageUrl = data?.ImageUrl;
    responsibles.name = data?.Name;
    responsibles.phoneNumbers = data?.PhoneNumbers;
    responsibles.email = data?.Email;

    return responsibles;
  }

  static fromInputToDTO(input: StudentResponsiblesInput) {
    return {
      User: {
        Email: input?.email,
        Name: input?.name
      },
      Id: input.id,
      Birthday: input?.birthDate,
      PhoneNumbers: input?.phoneNumbers
    };
  }

  toInput = (): StudentResponsiblesInput => ({
    id: this.id,
    imageUrl: this.imageUrl,
    name: this.name,
    email: this.email,
    phoneNumbers: this.phoneNumbers,
    birthDate: this.birthDate
  });
}
